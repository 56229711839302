<template>
  <div class="tw-absolute tw-top-0 tw-justify-center tw-flex tw-w-full">
    <DsTag color="#F04438" action="close" size="lg" @close="exitPlanningMode()">
      {{ $t('objects.projectAlternatives.title') }}
    </DsTag>
  </div>
</template>
<script setup lang="ts">
import { useProjectAlternativeStore } from '../store/projectAlternativeStore';

const alternativeStore = useProjectAlternativeStore();

const exitPlanningMode = () => {
  alternativeStore.exitPlanningMode();
};
</script>
