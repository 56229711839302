// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { h } from '../jsxFactory';

type ButtonProps = {
  $children?: any; // workaround so ts does not complain
  className?: string;
  hierarchy?:
    | 'primary'
    | 'primary-light'
    | 'secondary'
    | 'secondary-grey'
    | 'tertiary'
    | 'tertiary-grey'
    | 'link'
    | 'link-grey';
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  type?: 'button' | 'submit';
  destructive?: boolean;
  clickAction?: (event: MouseEvent) => void;
  dataTestid?: string;
};

export default function Button(
  {
    className = '',
    hierarchy = 'primary',
    size = 'md',
    type = 'button',
    destructive = false,
    clickAction = () => {},
    dataTestid = '',
  }: ButtonProps,
  children: (HTMLElement | string)[],
) {
  const onKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }
  };

  return (
    <button
      class={`ds-button --md --icon-default --${hierarchy} --${size} ${destructive ? '--destructive' : ''} ${className}`}
      type={type}
      onKeydown={onKeydown}
      onClick={clickAction}
      data-testid={dataTestid}
    >
      {...children}
    </button>
  );
}
