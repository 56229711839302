import { useI18n } from 'vue-i18n';

import { useBasePlanStore } from '@/features/basePlan';
import { ProjectPermissionGroup } from '@/features/permissions';
import { useProjectAlternativeStore } from '@/features/projectAlternatives';
import { useProjectStore } from '@/features/projects/projectStore';
import { useProjectStructureTemplatesStore } from '@/features/projectStructureTemplates';
import { useTradeSequenceTemplatesStore } from '@/features/tenantTemplates/tradeSequenceTemplatesStore';
import { useTicketStore } from '@/features/tickets';
import { LoggingService } from '@/interfaces/services';
import { useJimo } from '@/plugins/jimo/jimo';
import { useUserback } from '@/plugins/userback';
import { useLoggingService } from '@/services/logging/composable';
import { getScheduler } from '@/services/store/integrations/scheduler';
import { useMembershipStore } from '@/services/store/memberships/store';
import { useTenantStore } from '@/services/store/tenant';
import { useUserStore } from '@/services/store/user';
import { identifyUser, setUserLanguage } from '@/services/store/user/utils';

import { useGlobalStore } from './globalStore';

export const useAppLevelStores = () => {
  const jimo = useJimo();
  const userback = useUserback();
  const i18n = useI18n();
  const loggingService = useLoggingService();
  const userStore = useUserStore();
  const tenantStore = useTenantStore();
  const membershipStore = useMembershipStore();

  const initialize = async (tenantId: string): Promise<void> => {
    const [tenantMemberships, tenant, , user] = await Promise.all([
      membershipStore.fetchTenantMemberships(),
      tenantStore.fetchOwn({ id: tenantId }),
      membershipStore.fetchProjectSubcontractorWorkers(),
      userStore.fetchOwn(),
    ]);

    const userIsTenantMember = tenantMemberships.some(
      (membership) => membership.tenant.id === tenant?.id,
    );

    identifyUser(user, userIsTenantMember, loggingService, userback, jimo);
    setUserLanguage(user, userback, i18n);

    if (tenant) {
      const results: Promise<unknown>[] = [];

      loggingService.setTenantGroup({
        identification: tenantId,
        properties: {
          name: tenant.name,
          type: tenant.type,
          companyId: tenant.id,
          created: tenant.createdAt,
          hasMembership: userIsTenantMember,
        },
      });

      if (!userIsTenantMember) return;

      if (tenant.features?.enableTradeSequenceTemplates) {
        results.push(useTradeSequenceTemplatesStore().initialize(tenant.id));
      }

      results.push(useProjectStructureTemplatesStore().initialize(tenant.id));

      await Promise.all(results);
    }
  };

  return { initialize };
};

export const useProjectLevelStores = () => {
  const userStore = useUserStore();
  const projectStore = useProjectStore();
  const ticketStore = useTicketStore();
  const basePlanStore = useBasePlanStore();
  const projectAlternativeStore = useProjectAlternativeStore();
  const globalStore = useGlobalStore();

  const initialize = async (
    projectId: string,
    partial = false,
    loggingService: LoggingService,
  ): Promise<void> => {
    performance.mark('projectLevelStores initialize start');
    if (!partial) {
      const project = await projectStore.fetchProject({ id: projectId }).catch(() => null);
      if (!project) return;

      loggingService.setProjectGroup({
        identification: projectId,
        properties: {
          id: project.id,
          name: project.name,
          address: {
            city: project.address.city,
            country: project.address.country,
            postalCode: project.address.postalCode,
            street: project.address.street,
          },
          createdAt: project.createdAt,
          projectId: project.id,
          tenantId: project.tenant.id,
          // legacy
          created: project.createdAt,
          tenantName: project.tenant.name,
          $city: project.address.city,
          $country: project.address.country,
        },
      });
    }

    const isGeneralContractorPromise = userStore.checkIfUserIsGeneralContractor(
      globalStore.currentTenantId!,
      projectId,
    );

    const projectMembershipPromise = useMembershipStore().fetchProjectMembership(projectId);

    isGeneralContractorPromise.then(async (isGeneralContractor) => {
      const projectMembership = await projectMembershipPromise;
      const isTenantAdmin = useMembershipStore().tenantMemberships.find(
        (membership) => membership.tenant.id === globalStore.currentTenantId,
      )?.isAdmin;
      const isProjectEditor = Boolean(
        projectMembership && projectMembership.permission === ProjectPermissionGroup.editor,
      );
      const canLoadAlternatives = isProjectEditor || isTenantAdmin;
      if (!partial && isGeneralContractor && canLoadAlternatives) {
        projectAlternativeStore.initialize(projectId).catch(() => {});
      }
    });

    const { scheduleProjectId } = globalStore;

    if (!scheduleProjectId) return;

    const fetchPromises: Promise<unknown>[] = [
      ticketStore.fetchAll({ project: scheduleProjectId }),
    ];

    if (!partial) {
      basePlanStore.initialize(projectId).then(() => {
        getScheduler()?.refreshRows();
      });
    }

    await Promise.all(fetchPromises);

    performance.mark('projectLevelStores initialize end');
    performance.measure(
      'projectLevelStores initialize',
      'projectLevelStores initialize start',
      'projectLevelStores initialize end',
    );
  };

  return { initialize };
};
