import { computed, Ref, ref } from 'vue';

import { MembershipRepository } from '@/interfaces/repositories';
import { MembershipWithUser } from '@/interfaces/repositories/memberships';
import { MembershipService, OwnMembership } from '@/interfaces/services/membership';
import { useUserStore } from '@/services/store/user';

export class CustomMembershipService implements MembershipService {
  public constructor(private membershipRepository: MembershipRepository) {}

  public fetchOwn(): OwnMembership {
    const userStore = useUserStore();
    const { membershipId } = userStore;

    if (!membershipId) {
      return {
        membership: ref(null),
        isUnrelated: ref(true),
        isAdmin: ref(false),
        loading: ref(false),
        error: ref<Error>({} as unknown as Error),
      };
    }
    const { result, loading, error } = this.membershipRepository.fetchOne(membershipId);

    const isUnrelated = computed(() => result.value === null);
    const isAdmin = computed(() => Boolean(result.value?.isAdmin));

    return {
      membership: result,
      isUnrelated,
      isAdmin,
      loading,
      error,
    };
  }

  public getRelatedMemberships(
    tenantId: string,
    userIds: Ref<string[]>,
  ): Ref<MembershipWithUser[]> {
    const userStore = useUserStore();
    if (!userStore?.membershipId) return ref([]);

    const { result: memberships } = this.membershipRepository.fetchAll(ref({ tenantId }));

    return computed(() =>
      memberships.value.filter(
        (membership) => membership.user && userIds.value.includes(membership.user?.id),
      ),
    );
  }
}
