import { MilestoneEntity, PartialEntity } from '@/common/types';
import { OperationInputType } from '@/features/realTimeCollaboration/types';

import { MilestoneType } from './types';

export const DEFAULT_MILESTONE_TYPE = MilestoneType.FIXED;

export function convertMilestoneUpdateOperationInputToPartialMilestoneEntity(
  input: (OperationInputType<'UpdateMilestones'>[0] &
    Partial<OperationInputType<'UpdateMilestoneStatus'>>)[],
): PartialEntity<MilestoneEntity>[] {
  return input.map((m) => ({
    id: m.id,
    color: m.color ?? undefined,
    date: m.date ? new SchedulingDate(m.date) : undefined,
    name: m.name ?? undefined,
    wbsSection: typeof m.wbsSectionId === 'string' ? { id: m.wbsSectionId } : m.wbsSectionId,
    isFixed: m.type ? m.type === 'FIXED' : undefined,
    type: m.type === 'FIXED' ? MilestoneType.FIXED : MilestoneType.FLEXIBLE,
    acceptanceCriteria: m.criteria ?? undefined,
    completedAt: typeof m.completedAt === 'string' ? new Date(m.completedAt) : m.completedAt,
  }));
}
