import { Router } from 'vue-router';

import { AppApolloClient } from '@/interfaces/graphql';
import { UserRepository } from '@/interfaces/repositories';
import {
  AuthenticationService,
  CompressionService,
  FileDownloadService,
  FileUploadService,
  LocationService,
  LoggingService,
  MembershipService,
} from '@/interfaces/services';
import { GoogleMaps } from '@/plugins/googleMaps';
import { Segment } from '@/plugins/segment';
import {
  ConsoleLoggingService,
  CustomMembershipService,
  FirebaseAuthenticationService,
  GoogleMapsLocationService,
  HttpFileDownloadService,
  HttpFileUploadService,
  ImageCompressionService,
  ProductionLoggingService,
} from '@/services';
import { StagingLoggingService } from '@/services/logging';
import { IS_PROD_ENV, IS_STAGING_ENV } from '@/utils/config';
import { useFeatureToggles } from '@/utils/featureToggles';

import { getLoggingService, getMembershipRepository } from '..';
import { ContainerModule, IoCContainer } from '../framework/container';

export function registerLoggingService(container: IoCContainer): {
  loggingService: LoggingService;
  logger: Segment | null;
  loggingRequiresSentry: boolean;
} {
  const { ENABLE_PRODUCTION_LOGGING_ON_DEV, ENABLE_STAGING_LOGGING_ON_DEV } = useFeatureToggles();

  const useProductionTracking = IS_PROD_ENV || ENABLE_PRODUCTION_LOGGING_ON_DEV;
  const useStagingTracking = IS_STAGING_ENV || ENABLE_STAGING_LOGGING_ON_DEV;
  let logger: Segment | null = null;
  let loggingService: LoggingService;

  if (useProductionTracking) {
    logger = new Segment();
    loggingService = new ProductionLoggingService(logger);
  } else if (useStagingTracking) {
    loggingService = new StagingLoggingService();
  } else {
    loggingService = new ConsoleLoggingService();
  }
  container.register<LoggingService>('LoggingService', () => loggingService);
  return {
    loggingService,
    logger,
    loggingRequiresSentry: useProductionTracking || useStagingTracking,
  };
}

export function registerAuthenticationService(
  container: IoCContainer,
  loggingService: LoggingService,
): AuthenticationService {
  const authenticationService = new FirebaseAuthenticationService(
    loggingService,
    () => container.resolve<UserRepository>('UserRepository')!,
  );
  container.register<AuthenticationService>('AuthenticationService', () => authenticationService);
  return authenticationService;
}

class ServiceModule implements ContainerModule {
  public onCreate(
    container: IoCContainer,
    client: AppApolloClient,
    router: Router,
    googleMaps: GoogleMaps,
  ): void {
    container.register<CompressionService>('CompressionService', () => {
      return new ImageCompressionService();
    });

    container.register<FileDownloadService>(
      'FileDownloadService',
      () => new HttpFileDownloadService(getLoggingService(container)),
    );

    container.register<FileUploadService>('FileUploadService', () => {
      return new HttpFileUploadService();
    });

    container.register<MembershipService>('MembershipService', () => {
      const membershipRepository = getMembershipRepository(container);
      return new CustomMembershipService(membershipRepository);
    });

    container.register<LocationService>('LocationService', () => {
      return new GoogleMapsLocationService(googleMaps);
    });
  }
}

export default new ServiceModule();
