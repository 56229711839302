const folder_sync =
  'M20,6h-8l-1.4-1.4C10.2,4.2,9.7,4,9.2,4H4C2.9,4,2,4.9,2,6l0,12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8C22,6.9,21.1,6,20,6zM13.6,17.6c-0.5,0.2-1.1,0.3-1.6,0.3c-2.6,0-4.7-2.1-4.9-4.6H4.9l2.9-2.9l2.9,2.9H8.6c0.1,1.8,1.6,3.2,3.4,3.2c0.4,0,0.8-0.1,1.1-0.2c0.3-0.1,0.6,0,0.8,0.2c0.2,0.2,0.2,0.4,0.2,0.7C14,17.3,13.9,17.5,13.6,17.6z M16.2,15.7l-2.9-2.9h2.2C15.3,11,13.8,9.6,12,9.6c-0.4,0-0.8,0.1-1.1,0.2c-0.3,0.1-0.6,0-0.8-0.2C9.9,9.4,9.9,9.2,9.9,8.9c0.1-0.2,0.2-0.4,0.4-0.5c0.5-0.2,1.1-0.3,1.6-0.3c2.6,0,4.7,2.1,4.9,4.6h2.2L16.2,15.7z';
const handshake =
  'M11 6H14L17.29 2.7A1 1 0 0 1 18.71 2.7L21.29 5.29A1 1 0 0 1 21.29 6.7L19 9H11V11A1 1 0 0 1 10 12A1 1 0 0 1 9 11V8A2 2 0 0 1 11 6M5 11V15L2.71 17.29A1 1 0 0 0 2.71 18.7L5.29 21.29A1 1 0 0 0 6.71 21.29L11 17H15A1 1 0 0 0 16 16V15H17A1 1 0 0 0 18 14V13H19A1 1 0 0 0 20 12V11H13V12A2 2 0 0 1 11 14H9A2 2 0 0 1 7 12V9Z';
const email_outline =
  'M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z';
const assignment_outline =
  'M7 15h7v2H7zm0-4h10v2H7zm0-4h10v2H7zm12-4h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04-.39.08-.74.28-1.01.55-.18.18-.33.4-.43.64-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z';
const assignment_turned_in_outline =
  'M18 9l-1.41-1.42L10 14.17l-2.59-2.58L6 13l4 4zm1-6h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04-.39.08-.74.28-1.01.55-.18.18-.33.4-.43.64-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z';
const extension_outline =
  'M10.5 4.5c.28 0 .5.22.5.5v2h6v6h2c.28 0 .5.22.5.5s-.22.5-.5.5h-2v6h-2.12c-.68-1.75-2.39-3-4.38-3s-3.7 1.25-4.38 3H4v-2.12c1.75-.68 3-2.39 3-4.38 0-1.99-1.24-3.7-2.99-4.38L4 7h6V5c0-.28.22-.5.5-.5m0-2C9.12 2.5 8 3.62 8 5H4c-1.1 0-1.99.9-1.99 2v3.8h.29c1.49 0 2.7 1.21 2.7 2.7s-1.21 2.7-2.7 2.7H2V20c0 1.1.9 2 2 2h3.8v-.3c0-1.49 1.21-2.7 2.7-2.7s2.7 1.21 2.7 2.7v.3H17c1.1 0 2-.9 2-2v-4c1.38 0 2.5-1.12 2.5-2.5S20.38 11 19 11V7c0-1.1-.9-2-2-2h-4c0-1.38-1.12-2.5-2.5-2.5z';
const train_outline =
  'M12 2c-4 0-8 .5-8 4v9.5C4 17.43 5.57 19 7.5 19L6 20.5v.5h2l2-2h4l2 2h2v-.5L16.5 19c1.93 0 3.5-1.57 3.5-3.5V6c0-3.5-4-4-8-4zm0 2c3.51 0 4.96.48 5.57 1H6.43c.61-.52 2.06-1 5.57-1zM6 7h5v3H6V7zm12 8.5c0 .83-.67 1.5-1.5 1.5h-9c-.83 0-1.5-.67-1.5-1.5V12h12v3.5zm0-5.5h-5V7h5v3z';
const info_outline =
  'M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z';
const my_broken_link =
  'M15.184 20.239c-.61 0-1.104.494-1.104 1.104v1.586a1.104 1.104 0 1 0 2.208 0v-.033v.002v-1.555c0-.609-.494-1.104-1.103-1.104zm7.714-6.159h-1.555a1.105 1.105 0 0 0-.001 2.208h1.557a1.105 1.105 0 0 0 .001-2.208zm-2.579 4.678a1.104 1.104 0 0 0-1.559 1.561l-.001-.001l1.099 1.099a1.104 1.104 0 1 0 1.561-1.559l-.001-.001zM8.817 3.762c.61 0 1.104-.494 1.104-1.104V1.104a1.105 1.105 0 0 0-2.208-.001v1.556c0 .609.494 1.104 1.103 1.104zM2.658 9.92h.031a1.104 1.104 0 1 0 0-2.208h-.033h.002h-1.554a1.105 1.105 0 0 0-.001 2.208h.002zm1.024-4.677a1.104 1.104 0 1 0 1.561-1.559l-.001-.001l-1.099-1.099a1.104 1.104 0 1 0-1.561 1.559l.001.001zm10.66 12.221a1.104 1.104 0 0 0-1.561-1.559l.001-.001l-3.12 3.12a3.312 3.312 0 0 1-4.678-4.682l-.002.002l3.12-3.12a1.104 1.104 0 1 0-1.559-1.561l-.001.001l-3.12 3.12a5.52 5.52 0 0 0 3.9 9.422h.013a5.467 5.467 0 0 0 3.887-1.616zm6.243-14.052a5.467 5.467 0 0 0-3.887-1.616h-.013h.001h-.013a5.467 5.467 0 0 0-3.887 1.616l-3.12 3.12a1.104 1.104 0 1 0 1.559 1.561l.001-.001l3.12-3.12a3.312 3.312 0 1 1 4.683 4.679l-.002.002l-3.12 3.12a1.104 1.104 0 1 0 1.559 1.561l.001-.001l3.12-3.12c.996-.999 1.611-2.378 1.611-3.9s-.616-2.901-1.612-3.9z';
const folder_minus =
  'M13 7L11.8845 4.76892C11.5634 4.1268 11.4029 3.80573 11.1634 3.57116C10.9516 3.36373 10.6963 3.20597 10.4161 3.10931C10.0992 3 9.74021 3 9.02229 3H5.2C4.0799 3 3.51984 3 3.09202 3.21799C2.71569 3.40973 2.40973 3.71569 2.21799 4.09202C2 4.51984 2 5.0799 2 6.2V7M2 7H17.2C18.8802 7 19.7202 7 20.362 7.32698C20.9265 7.6146 21.3854 8.07354 21.673 8.63803C22 9.27976 22 10.1198 22 11.8V16.2C22 17.8802 22 18.7202 21.673 19.362C21.3854 19.9265 20.9265 20.3854 20.362 20.673C19.7202 21 18.8802 21 17.2 21H6.8C5.11984 21 4.27976 21 3.63803 20.673C3.07354 20.3854 2.6146 19.9265 2.32698 19.362C2 18.7202 2 17.8802 2 16.2V7ZM9 14H15';
const folder_plus =
  'M13 7L11.8845 4.76892C11.5634 4.1268 11.4029 3.80573 11.1634 3.57116C10.9516 3.36373 10.6963 3.20597 10.4161 3.10931C10.0992 3 9.74021 3 9.02229 3H5.2C4.0799 3 3.51984 3 3.09202 3.21799C2.71569 3.40973 2.40973 3.71569 2.21799 4.09202C2 4.51984 2 5.0799 2 6.2V7M2 7H17.2C18.8802 7 19.7202 7 20.362 7.32698C20.9265 7.6146 21.3854 8.07354 21.673 8.63803C22 9.27976 22 10.1198 22 11.8V16.2C22 17.8802 22 18.7202 21.673 19.362C21.3854 19.9265 20.9265 20.3854 20.362 20.673C19.7202 21 18.8802 21 17.2 21H6.8C5.11984 21 4.27976 21 3.63803 20.673C3.07354 20.3854 2.6146 19.9265 2.32698 19.362C2 18.7202 2 17.8802 2 16.2V7ZM12 17V11M9 14H15';

/** To reuse those custom icons, they need to be prefixed with `$` */
export const customIcons = {
  assignment_outline,
  assignment_turned_in_outline,
  email_outline,
  extension_outline,
  folder_sync,
  handshake,
  info_outline,
  train_outline,
  my_broken_link,
  folder_minus,
  folder_plus,
};
