import { AnalyticsEvent, AnalyticsEventCategories } from '@/utils/analyticsEvents/categories';

export class ProjectContractorAddedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.PROJECT_CONTRACTOR_ADDED;

  public constructor(public properties: { doesTenantExist: boolean }) {
    super();
  }
}

export class ProjectContractorDeletedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.PROJECT_CONTRACTOR_DELETED;
}

export class ProjectContractorWorkerAddedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.PROJECT_CONTRACTOR_WORKER_ADDED;
}

export class ProjectContractorWorkerDeletedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.PROJECT_CONTRACTOR_WORKER_DELETED;
}

export class TenantMemberAddedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.TENANT_MEMBER_ADDED;
}

export class TenantMemberDeletedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.TENANT_MEMBER_DELETED;
}
