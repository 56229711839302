import { App, inject } from 'vue';

import { LoggingService } from '@/interfaces/services';
import { JIMO_PROJECT_ID } from '@/utils/config';
import { EventBus } from '@/utils/eventBus';

export const jimoKey = Symbol('jimo');

export const enum JIMO_EVOLUTION_IDS {
  NEW_FTS_POPUP = 'aafede5e-0756-426d-83ab-6fc33c79cb2d', // https://i.usejimo.com/pokes/aafede5e-0756-426d-83ab-6fc33c79cb2d
  BASE_ACTUAL_POPUP = 'e412b502-0531-4ce4-89ce-477802b32e05',
  NEW_PROJECT_ONBOARDING = 'e96e942d-9011-4463-935e-c2b064b81da9',
  PROJECT_ONBOARDING = '9149769b-912f-4410-b029-97dc53f19d38',
  MISSING_AVATAR_POPUP = '968a6ba9-d069-4987-bd2d-9fe2c320f219',
}

export const JIMO_POKE_IDS = {
  VDE2_HIGHLIGHT_TOGGLE: 'jimo-poke-vde2-highlight-toggle',
  RTC_AVATAR_UPLOAD: 'jimo-poke-rtc-avatar-upload',
};

type User = {
  id: string;
  email: string;
  name: string;
};

export interface Jimo {
  init: (user: User) => void;
  activateBadge: () => void;
  onOpened: (callback: () => void) => void;
  triggerPokeManually: (evolutionId: JIMO_EVOLUTION_IDS) => void;
}
let registeredOnOpened = false;
export const createJimoClient = (eventBus: EventBus, loggingService?: LoggingService): Jimo => {
  const runSafeJimo = (callback: () => void) => {
    try {
      callback();
    } catch (e) {
      loggingService?.error(e as Error, { code: 'JIMO' });
    }
  };

  eventBus.on('logout', () => {
    runSafeJimo(() => window.jimoKill());
  });

  return {
    init: (user: User) => {
      window.JIMO_SESSION_TOKEN = user.id;
      runSafeJimo(() => {
        window.jimoInit();

        window.jimo?.push(['set', 'user:id', [user.id]]);
        window.jimo?.push(['set', 'user:email', [user.email]]);
        window.jimo?.push(['set', 'user:name', [user.name]]);
      });
    },
    onOpened: (callback: () => void) =>
      runSafeJimo(() => {
        if (registeredOnOpened) return;
        registeredOnOpened = true;
        window.jimo?.push(['on', 'widget:opened', [callback]]);
      }),
    activateBadge: () => runSafeJimo(() => window.jimo?.push(['do', 'trigger-badge:find'])),
    triggerPokeManually: (evolutionId: JIMO_EVOLUTION_IDS) => {
      runSafeJimo(() =>
        window.jimo?.do?.('boosted:trigger', {
          evolutionId,
        }),
      );
    },
  };
};

/** https://docs.usejimo.com/guides/install-jimo/install-snippet/ */
export function installJimo(app: App, eventBus: EventBus, loggingService?: LoggingService): void {
  // @ts-expect-error-next-line
  window.jimo = [];
  const script = document.createElement('script');
  script.async = true;
  script.id = 'jimo';
  script.type = 'text/javascript';
  script.src = 'https://undercity.usejimo.com/jimo-invader.js';
  window.JIMO_PROJECT_ID = JIMO_PROJECT_ID;
  window.JIMO_MANUAL_INIT = true; // https://docs.usejimo.com/guides/snippet/how-to/manual-initialization/

  /** Append script to DOM */
  document.head.appendChild(script);

  const jimo = createJimoClient(eventBus, loggingService);

  app.provide(jimoKey, jimo);
}

export function useJimo(): Jimo {
  return inject(jimoKey) as Jimo;
}
