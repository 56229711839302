import { useMutation, useQuery } from '@vue/apollo-composable';
import { computed, Ref, unref } from 'vue';

import {
  CreateProjectSubcontractorWorkerMutation,
  CreateProjectSubcontractorWorkerMutationVariables,
  DeleteProjectSubcontractorWorkerMutationVariables,
  ProjectSubcontractorWorkerFragment,
  ProjectSubcontractorWorkersQuery,
  ProjectSubcontractorWorkersQueryVariables,
  UpdateProjectSubcontractorWorkerMutation,
  UpdateProjectSubcontractorWorkerMutationVariables,
} from '@/graphql/__generated__/graphql';
import ProjectSubcontractorWorkersForUser from '@/graphql/projectSubcontractorWorkers/AllForUser.gql';
import ProjectSubcontractorWorkerCreate from '@/graphql/projectSubcontractorWorkers/Create.gql';
import ProjectSubcontractorWorkerDelete from '@/graphql/projectSubcontractorWorkers/Delete.gql';
import ProjectSubcontractorWorkerFragmentDocument from '@/graphql/projectSubcontractorWorkers/Fragment.gql';
import ProjectSubcontractorWorkerUpdate from '@/graphql/projectSubcontractorWorkers/Update.gql';
import { AppApolloClient } from '@/interfaces/graphql';
import { ProjectSubcontractorWorkerRepository } from '@/interfaces/repositories';
import {
  DeleteMutationResult,
  Entity,
  MutationResult,
  QueryAllResult,
} from '@/interfaces/repositories/base';
import {
  CreateProjectSubcontractorWorkerVariables,
  ProjectSubcontractorWorker,
  UpdateProjectSubcontractorWorkerVariables,
} from '@/interfaces/repositories/projectSubcontractorWorker';
import {
  FragmentName,
  getDataId,
  getOptimisticResponse,
  NodeName,
} from '@/repositories/utils/cache';
import { useDefaultMutationOnEntity } from '@/repositories/utils/defaults';
import { useCalledIndicator } from '@/repositories/utils/helper';

import { flattenNodeConnection, useFetchAllResult } from './utils/fetchAll';

export class GraphQLProjectSubcontractorWorkerRepository
  implements ProjectSubcontractorWorkerRepository
{
  public constructor(private client: AppApolloClient) {}

  public useCreate(
    vars?: Ref<CreateProjectSubcontractorWorkerVariables>,
  ): MutationResult<
    CreateProjectSubcontractorWorkerVariables,
    ProjectSubcontractorWorkerFragment | null
  > {
    const variables: Ref<CreateProjectSubcontractorWorkerMutationVariables> = computed(() => ({
      ...(vars?.value ?? ({} as CreateProjectSubcontractorWorkerMutationVariables)),
    }));

    const { mutate, error, loading } = useMutation<
      CreateProjectSubcontractorWorkerMutation,
      CreateProjectSubcontractorWorkerMutationVariables
    >(ProjectSubcontractorWorkerCreate, () => ({
      variables: variables.value,
    }));

    return {
      mutate: (
        data?: CreateProjectSubcontractorWorkerVariables,
      ): Promise<ProjectSubcontractorWorkerFragment | null> => {
        return mutate(data).then(
          (result) =>
            result?.data?.createProjectSubcontractorWorker?.projectSubcontractorWorker ?? null,
        );
      },
      loading,
      error,
    };
  }

  public useUpdate(
    id?: string | Ref<string>,
    vars?: Ref<UpdateProjectSubcontractorWorkerVariables>,
  ): MutationResult<
    UpdateProjectSubcontractorWorkerVariables & Entity,
    ProjectSubcontractorWorkerFragment | null
  > {
    const variables: Ref<UpdateProjectSubcontractorWorkerMutationVariables> = computed(() => ({
      id: unref(id ?? ''),
      ...(vars?.value ?? ({} as UpdateProjectSubcontractorWorkerMutationVariables)),
    }));

    const { mutate, error, loading } = useDefaultMutationOnEntity<
      UpdateProjectSubcontractorWorkerMutation,
      UpdateProjectSubcontractorWorkerMutationVariables
    >(ProjectSubcontractorWorkerUpdate, variables);

    return {
      mutate: (
        data?: UpdateProjectSubcontractorWorkerVariables & Entity,
      ): Promise<ProjectSubcontractorWorkerFragment | null> => {
        const ownId = data?.id ?? '';

        const role = data?.role;

        // read fragment of own object from cache to get additional data, that never gets updated (e.g. tenant in this case)
        const subcontractorWorker = this.client.readFragment({
          id: getDataId(NodeName.PROJECT_SUBCONTRACTOR_WORKER, ownId),
          fragment: ProjectSubcontractorWorkerFragmentDocument,
          fragmentName: FragmentName.PROJECT_SUBCONTRACTOR_WORKER,
        });

        const optimisticResponse = getOptimisticResponse<UpdateProjectSubcontractorWorkerMutation>(
          () => {
            return {
              updateProjectSubcontractorWorker: {
                __typename: 'UpdateProjectSubcontractorWorkerPayload',
                projectSubcontractorWorker: {
                  ...subcontractorWorker,
                  role,
                },
              },
            };
          },
        );

        return mutate(data, { optimisticResponse }).then(
          (result) =>
            result?.data?.updateProjectSubcontractorWorker?.projectSubcontractorWorker ?? null,
        );
      },
      loading,
      error,
    };
  }

  public useDelete(id: string) {
    const variables: Ref<DeleteProjectSubcontractorWorkerMutationVariables> = computed(() => ({
      id: unref(id),
    }));

    const { mutate, error, loading } = useDefaultMutationOnEntity<
      DeleteMutationResult,
      DeleteProjectSubcontractorWorkerMutationVariables
    >(ProjectSubcontractorWorkerDelete, variables);

    return {
      mutate,
      loading,
      error,
    };
  }

  public fetchAllForOwnUser(): QueryAllResult<ProjectSubcontractorWorker> {
    const {
      result: rawResult,
      loading,
      error,
      refetch,
    } = useQuery<ProjectSubcontractorWorkersQuery, ProjectSubcontractorWorkersQueryVariables>(
      ProjectSubcontractorWorkersForUser,
      {},
    );

    const result = useFetchAllResult<
      ProjectSubcontractorWorkersQuery,
      NonNullable<ProjectSubcontractorWorkersQuery['projectSubcontractorWorkers']>,
      ProjectSubcontractorWorker
    >(rawResult, 'projectSubcontractorWorkers', (data) => flattenNodeConnection(data));

    const { called } = useCalledIndicator(rawResult);

    return { ...result, loading, error, refetch, called };
  }

  /** Hacked query call for the router (when refs can not be used)
   * Returns all projectSubcontractorWorkers for the current user
   */
  public async fetchAllForOwnUserHack(): Promise<ProjectSubcontractorWorker[]> {
    return this.client
      .query<ProjectSubcontractorWorkersQuery, ProjectSubcontractorWorkersQueryVariables>({
        query: ProjectSubcontractorWorkersForUser,
      })
      .then((result) => flattenNodeConnection(result.data.projectSubcontractorWorkers));
  }
}
