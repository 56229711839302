import { DependencyType, OrderTaskTemplate } from '@koppla-tech/scheduling-engine';

import { Weekday } from '@/features/calendars/calendarTypes';
import { MilestoneType } from '@/features/milestones/types';
import { MultiSelectActionsStore } from '@/features/multiSelectActions/types';
import { ProjectStatus } from '@/features/projects/projectTypes';
import { ProjectSubcontractorStore } from '@/features/projectSubcontractors';
import { ProjectTradeStore } from '@/features/projectTrades/types';
import {
  CreateTradeSequenceVariables,
  DeleteTradeSequenceVariables,
} from '@/features/projectTradeSequences/types';
import {
  OperationInputType,
  ProjectChangeEventContext,
} from '@/features/realTimeCollaboration/types';
import { TenantTradeSequenceStore, TenantTradeStore } from '@/features/tenantTemplates';
import { UndoRedoCommit } from '@/features/undoRedo';
import {
  CalendarFragment,
  OrderDependencyFragment,
  OrderFragment,
  OrderStatusReportFragment,
  OrderTaskFragment,
  PauseFragment,
  ProjectAlternativeFragment,
  ProjectFragment,
  ProjectMilestoneFragment,
  ProjectSubcontractorFragment,
  TenantTradeFragment,
  TradeFragment,
  TradeSequenceActivityDependencyFragment,
  TradeSequenceActivityFragment,
  TradeSequenceV2Fragment,
  WbsSectionNode,
} from '@/graphql/__generated__/graphql';
import { StatusReport } from '@/helpers/orders/status';
import { OmitAddress } from '@/interfaces/repositories/utility';

import { UnwrapEdges } from './graphql';

export interface Entity {
  id: string;
}

export type PartialEntity<T extends Entity> = Partial<Omit<T, 'id'>> & Pick<T, 'id'>;

export interface OrderEntity
  extends Omit<UnwrapEdges<OrderFragment>, 'status' | 'startAt' | 'finishAt' | 'finishedAt'> {
  status: StatusReport;
  startAt: SchedulingDate;
  finishAt: SchedulingDate;
  finishedAt: SchedulingDate | null;
  duration?: number;
}

export interface OrderStatusEntity
  extends Omit<UnwrapEdges<OrderStatusReportFragment>, 'status' | 'progress'> {
  orderId: string;
  status: StatusReport;
  progress: number;
}

export interface OrderTaskEntity extends Omit<UnwrapEdges<OrderTaskFragment>, 'completedAt'> {
  completedAt?: Date | null;
}

export interface MilestoneEntity
  extends Omit<UnwrapEdges<ProjectMilestoneFragment>, 'date' | 'completedAt' | 'isFixed'> {
  date: SchedulingDate;
  type: MilestoneType;
  completedAt: Date | null | undefined;
  isFixed?: boolean;
}

export interface OrderDependencyEntity extends UnwrapEdges<OrderDependencyFragment> {
  bufferInMinutes: number;
  type: DependencyType;
}

export interface CalendarEntity
  extends Omit<UnwrapEdges<CalendarFragment>, 'workingDays' | 'exceptions'> {
  workingDays: {
    weekday: Weekday;
    workingTimes: { from: string; to: string }[];
  }[];
  exceptions: {
    startAt: Date;
    finishAt: Date;
    isWorkingDay: boolean;
    workingTimes: { from: string; to: string }[];
  }[];
}

export interface PauseEntity extends Omit<UnwrapEdges<PauseFragment>, 'start' | 'end' | 'name'> {
  name: string;
  start: SchedulingDate;
  end: SchedulingDate;
}

export interface TradeSequenceActivityEntity
  extends Omit<
    UnwrapEdges<TradeSequenceActivityFragment>,
    'tenantTradeVariation' | 'taskTemplates'
  > {
  tenantTradeVariation: NonNullable<TradeSequenceActivityFragment['tenantTradeVariation']>;
  taskTemplates: (Omit<OrderTaskTemplate, 'id'> & { id?: string })[];
}

export interface TradeSequenceActivityDependencyEntity
  extends UnwrapEdges<Omit<TradeSequenceActivityDependencyFragment, 'type'>> {
  type: DependencyType;
}

export interface TradeSequenceEntity
  extends Omit<UnwrapEdges<TradeSequenceV2Fragment>, 'activities' | 'dependencies'> {
  activities: TradeSequenceActivityEntity[];
  dependencies: TradeSequenceActivityDependencyEntity[];
}

export interface InsertTradeSequenceVariables {
  tradeSequenceId: string;
  date: Date;
  sectionId: string;
  instanceId: string;
}

export interface TradeEntity extends UnwrapEdges<TradeFragment> {}

export interface TenantTradeEntity extends UnwrapEdges<TenantTradeFragment> {}

export interface ProjectSubcontractorEntity extends UnwrapEdges<ProjectSubcontractorFragment> {}

export interface WbsSectionEntity
  extends Pick<UnwrapEdges<WbsSectionNode>, 'id' | 'name' | 'position'> {
  parentId: string | null;
}

export interface ProjectEntity extends OmitAddress<UnwrapEdges<ProjectFragment>> {
  status: ProjectStatus;
}

export interface ProjectAlternativeEntity extends ProjectAlternativeFragment {}

export interface InteractiveEntities {
  orders?: OrderEntity[];
  orderStatus?: OrderStatusEntity[];
  milestones?: MilestoneEntity[];
  dependencies?: OrderDependencyEntity[];
  pauses?: PauseEntity[];
  calendars?: CalendarEntity[];
  tradeSequences?: TradeSequenceEntity[];
  wbsSections?: WbsSectionEntity[];
  trades?: (TradeEntity | TenantTradeEntity)[];
  projectSubcontractors?: ProjectSubcontractorEntity[];
  projects?: ProjectEntity[];
  projectAlternatives?: ProjectAlternativeEntity[];
}

type InteractiveEntityKey = keyof InteractiveEntities;

export const INTERACTIVE_ENTITY_KEYS: InteractiveEntityKey[] = [
  'orders',
  'orderStatus',
  'milestones',
  'dependencies',
  'pauses',
  'calendars',
  'tradeSequences',
  'wbsSections',
  'trades',
  'projectSubcontractors',
  'projects',
  'projectAlternatives',
];

export interface PartialInteractiveEntities
  extends Partial<{
    [K in keyof Required<InteractiveEntities>]: Required<InteractiveEntities>[K] extends Array<
      infer U extends Entity
    >
      ? PartialEntity<U>[]
      : Required<InteractiveEntities>[K];
  }> {}

export interface InteractiveEntityMaps
  extends Partial<{
    [K in keyof Required<InteractiveEntities>]: Map<
      string,
      Required<InteractiveEntities>[K] extends Array<infer U extends { id: string }> ? U : never
    >;
  }> {}

export interface PartialInteractiveEntityMaps
  extends Partial<{
    [K in keyof Required<PartialInteractiveEntities>]: Map<
      string,
      Required<PartialInteractiveEntities>[K] extends Array<infer U extends { id: string }>
        ? U
        : never
    >;
  }> {}

export interface InteractiveEntityChanges {
  add?: InteractiveEntities;
  update?: PartialInteractiveEntities;
  delete?: {
    [K in InteractiveEntityKey]?: Entity[];
  };
}

export interface InteractiveEntityChangeMaps {
  add?: InteractiveEntityMaps;
  update?: PartialInteractiveEntityMaps;
  delete?: {
    [K in InteractiveEntityKey]?: Map<string, Entity>;
  };
}

export interface InteractiveOperationReturnType {
  changes: InteractiveEntityChanges;
  commit: UndoRedoCommit;
  context?: ProjectChangeEventContext;
}

type StandardInteractiveEntityStoreProperties<T extends Entity> = {
  create(
    vars: T[],
    createCommit?: boolean,
    context?: ProjectChangeEventContext,
  ): Promise<InteractiveOperationReturnType>;
  update(
    vars: PartialEntity<T>[],
    createCommit?: boolean,
    context?: ProjectChangeEventContext,
  ): Promise<InteractiveOperationReturnType>;
  restore(
    vars: T[],
    createCommit?: boolean,
    context?: ProjectChangeEventContext,
  ): Promise<InteractiveOperationReturnType>;
  delete(
    vars: T[],
    createCommit?: boolean,
    context?: ProjectChangeEventContext,
  ): Promise<InteractiveOperationReturnType>;
  copyState: () => Map<string, T>;
  setState: (state?: Map<string, T>) => void;
  applyChanges: (
    changes: { add?: T[]; update?: PartialEntity<T>[]; delete?: Entity[] },
    state?: Map<string, T>,
  ) => void;
  fetchAll: (projectId: string) => Promise<T[]>;
  fetchAllPromise: Promise<T[]> | null;
  reset: () => void;
  getSoftDeletedEntity: (id: string) => T | undefined;
};

type DynamicInteractiveEntityStoreProperties<T extends Entity, DataKeyName extends keyof any> = {
  [P in DataKeyName]: Map<string, T>;
};

export type InteractiveEntityStore<
  T extends Entity,
  DataKeyName extends InteractiveEntityKey,
> = StandardInteractiveEntityStoreProperties<T> &
  DynamicInteractiveEntityStoreProperties<T, DataKeyName>;

export interface InteractiveProjectStore
  extends Omit<InteractiveEntityStore<ProjectEntity, 'projects'>, 'fetchAll' | 'delete'> {
  currentProject: ProjectEntity | null;
  fetchAll: (vars?: {
    hasPermissionToSeeAllProjects?: boolean;
    fetchOnlyForUser?: boolean;
  }) => Promise<ProjectEntity[]>;
  delete: (id: string) => Promise<InteractiveEntityChanges>;
}

export interface InteractiveProjectAlternativeStore
  extends Omit<
    InteractiveEntityStore<ProjectAlternativeEntity, 'projectAlternatives'>,
    'fetchAll' | 'fetchAllPromise' | 'create' | 'update' | 'restore' | 'delete'
  > {
  selectedAlternative: ProjectAlternativeEntity | null;
}

export interface InteractiveOrderStore
  extends Omit<
    InteractiveEntityStore<OrderEntity, 'orders'>,
    'create' | 'update' | 'applyChanges' | 'getSoftDeletedEntity'
  > {
  create(
    vars: CreateOrdersInput,
    createCommit?: boolean,
    context?: ProjectChangeEventContext,
  ): Promise<InteractiveOperationReturnType>;
  update(
    vars: UpdateOrdersInput,
    createCommit?: boolean,
    context?: ProjectChangeEventContext,
  ): Promise<InteractiveOperationReturnType>;
  applyChanges: (
    changes: {
      add?: OrderEntity[];
      update?: PartialEntity<OrderEntity>[];
      delete?: Entity[];
      addStatus?: OrderStatusEntity[];
    },
    state?: Map<string, OrderEntity>,
  ) => void;
  setOrderStatusState: (state: Map<string, OrderStatusEntity> | undefined) => void;
  replaceTrade: (previousTradeId: string, newTradeId: string) => void;
  unassignSubcontractorIfNecessary: (subcontractors: Set<string> | string[]) => Set<string>;
  getSoftDeletedEntity: (id: string) => { order: OrderEntity; dependencies: string[] } | undefined;
  setSoftDeletedEntity: (id: string, dependency?: string) => void;
  injectWorkingTimeDuration: <T extends PartialEntity<OrderEntity>>(
    order: T,
    updatedCalendars?: PartialEntity<CalendarEntity>[],
    updatedPauses?: PartialEntity<PauseEntity>[],
  ) => T;
}

export interface InteractiveOrderDependencyStore
  extends Omit<
    InteractiveEntityStore<OrderDependencyEntity, 'dependencies'>,
    'create' | 'delete' | 'restore'
  > {
  create(
    vars: OrderDependencyEntity[],
    createCommit: boolean,
    entityStores: Pick<InteractiveEntityStores, 'orderStore' | 'milestoneStore'>,
    context?: ProjectChangeEventContext,
  ): Promise<InteractiveOperationReturnType>;
  restore(
    vars: OrderDependencyEntity[],
    createCommit: boolean,
    entityStores: Pick<InteractiveEntityStores, 'orderStore' | 'milestoneStore'>,
    context?: ProjectChangeEventContext,
  ): Promise<InteractiveOperationReturnType>;
  delete(
    vars: OrderDependencyEntity[],
    createCommit: boolean,
    entityStores: Pick<InteractiveEntityStores, 'orderStore' | 'milestoneStore'>,
    context?: ProjectChangeEventContext,
  ): Promise<InteractiveOperationReturnType>;
  setSoftDeletedEntity: (dependency: OrderDependencyEntity) => void;
}

export type CreateOrdersInput = (Omit<OrderEntity, 'duration'> & {
  tasks?: OperationInputType<'UpdateOrders'>[0]['tasks'];
} & { duration: number })[];

export type UpdateOrdersInput = (PartialEntity<OrderEntity> & {
  tasks?: OperationInputType<'UpdateOrders'>[0]['tasks'];
})[];

export interface InteractiveWbsSectionStore
  extends Omit<
    InteractiveEntityStore<WbsSectionEntity, 'wbsSections'>,
    'getSoftDeletedEntity' | 'create' | 'setState' | 'applyChanges'
  > {
  create(
    vars: WbsSectionEntity[],
    root: WbsSectionEntity | null,
    createCommit?: boolean,
    context?: ProjectChangeEventContext,
  ): Promise<InteractiveOperationReturnType>;
  setState: (
    state?: Map<string, WbsSectionEntity>,
    oldOrderState?: Map<string, OrderEntity>,
    oldMilestoneState?: Map<string, MilestoneEntity>,
  ) => void;
  applyChanges: (
    changes: {
      add?: WbsSectionEntity[];
      update?: PartialEntity<WbsSectionEntity>[];
      delete?: Entity[];
    },
    oldOrderState?: Map<string, OrderEntity>,
    oldMilestoneState?: Map<string, MilestoneEntity>,
  ) => void;
  getSoftDeletedEntities: (id: string) => WbsSectionEntity[] | undefined;
}

export interface InteractiveProjectTradeSequenceStore
  extends Omit<InteractiveEntityStore<TradeSequenceEntity, 'tradeSequences'>, 'create' | 'delete'> {
  create(
    vars: CreateTradeSequenceVariables[],
    createCommit?: boolean,
    context?: ProjectChangeEventContext,
  ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }>;
  delete(
    vars: DeleteTradeSequenceVariables[],
    createCommit?: boolean,
    context?: ProjectChangeEventContext,
  ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }>;
  replaceTrade: (previousTradeId: string, newTradeId: string) => void;
}

export interface InteractiveMilestoneStore
  extends Omit<InteractiveEntityStore<MilestoneEntity, 'milestones'>, 'getSoftDeletedEntity'> {
  getSoftDeletedEntity: (
    id: string,
  ) => { milestone: MilestoneEntity; dependencies: string[] } | undefined;
  setSoftDeletedEntity: (id: string, dependency?: string) => void;
}

export interface InteractiveEntityStores {
  calendarStore: () => InteractiveEntityStore<CalendarEntity, 'calendars'>;
  orderStore: () => InteractiveOrderStore;
  pauseStore: () => InteractiveEntityStore<PauseEntity, 'pauses'>;
  wbsSectionStore: () => InteractiveWbsSectionStore;
  milestoneStore: () => InteractiveMilestoneStore;
  projectTradeSequenceStore: () => InteractiveProjectTradeSequenceStore;
  tenantTradeSequenceStore: () => TenantTradeSequenceStore;
  orderDependencyStore: () => InteractiveOrderDependencyStore;
  projectSubcontractorStore: () => ProjectSubcontractorStore;
  projectStore: () => InteractiveProjectStore;
  projectAlternativeStore: () => InteractiveProjectAlternativeStore;
  multiSelectActionsStore: () => MultiSelectActionsStore;
  projectTradeStore: () => ProjectTradeStore;
  tenantTradeStore: () => TenantTradeStore;
}

export const INTERACTIVE_ENTITY_STORES_BY_ENTITY: Record<
  InteractiveEntityKey,
  keyof InteractiveEntityStores | null
> = {
  calendars: 'calendarStore',
  orders: 'orderStore',
  orderStatus: null,
  pauses: 'pauseStore',
  milestones: 'milestoneStore',
  wbsSections: 'wbsSectionStore',
  dependencies: 'orderDependencyStore',
  tradeSequences: 'projectTradeSequenceStore',
  trades: null,
  projectSubcontractors: null,
  projects: null,
  projectAlternatives: null,
};

export type WrapRef<T, K extends keyof T> = {
  [P in keyof T]: P extends K ? Ref<T[P]> : T[P];
};
