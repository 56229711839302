import { OperationNames } from 'events.schema';

import { sanitizeListWithNamedItems } from '@/common/sanitization/lists';
import {
  CreateOrdersInput,
  Entity,
  OrderDependencyEntity,
  UpdateOrdersInput,
} from '@/common/types';
import {
  OperationInputType,
  ProjectChangeEventContext,
} from '@/features/realTimeCollaboration/types';

import { generateRTCMessageId, LocalProjectChangeEventTemplate } from '../realTimeCollaboration';

export const createProjectChangeCreateEvent = (
  vars: CreateOrdersInput,
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.CreateOrders,
      input: vars.map((order) => ({
        ...order,
        startAt: order.startAt.toISOString(),
        finishAt: order.finishAt.toISOString(),
        wbsSectionId: order.wbsSection.id,
        calendarId: order.calendar.id,
        tradeId: order.tenantTradeVariation.id,
        subcontractorId: order.subcontractor?.id,
        tradeSequenceActivityId: order.tradeSequenceActivity?.id,
        workingTimeDuration: order.duration,
        tasks: order.tasks && sanitizeListWithNamedItems(order.tasks),
      })),
      context,
    },
  };
};

export const createProjectChangeUpdateEvent = (
  vars: UpdateOrdersInput,
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.UpdateOrders,
      input: vars.map((partialOrder) => ({
        id: partialOrder.id,
        name: partialOrder.name,
        subcontractorId: partialOrder.subcontractor?.id,
        tradeId: partialOrder.tenantTradeVariation?.id,
        calendarId: partialOrder.calendar?.id,
        startAt: partialOrder.startAt?.toISOString(),
        finishAt: partialOrder.finishAt?.toISOString(),
        wbsSectionId:
          partialOrder.wbsSection === undefined ? undefined : partialOrder.wbsSection?.id,
        workingTimeDuration: partialOrder.duration,
        dryingBreak: partialOrder.dryingBreak,
        isFixed: partialOrder.isFixed,
        ignoreFixed: true,
        tasks: partialOrder.tasks && sanitizeListWithNamedItems(partialOrder.tasks),
        tradeSequenceActivityId: partialOrder.tradeSequenceActivity?.id,
      })),
      context,
    },
  };
};

export const createProjectChangeRestoreEvent = (
  orders: Entity[],
  dependencies?: Entity[],
  context?: ProjectChangeEventContext,
  adjacentDependenciesToRestore?: OrderDependencyEntity[],
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.RestoreScheduleNodes,
      input: {
        orders: orders.map((order) => order.id),
        dependencies: dependencies?.map((dependency) => dependency.id),
      },
      context,
    },
    restoredEntities: {
      orders: orders.map((order) => order.id),
      dependencies: dependencies?.map((dependency) => dependency.id),
      dependencyDetails: adjacentDependenciesToRestore?.map((dependency) => ({
        ...dependency,
        from: { orderId: dependency.from.id, milestoneId: dependency.from.id },
        to: { orderId: dependency.to.id, milestoneId: dependency.to.id },
      })),
    },
  };
};

export const createProjectChangeDeleteEvent = (
  orders: Entity[],
  dependencies?: Entity[],
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.RemoveScheduleNodes,
      input: {
        orders: orders.map((order) => order.id),
        dependencies: dependencies?.map((dependency) => dependency.id),
      },
      context,
    },
  };
};

export const createProjectChangeCopyEvent = (
  input: OperationInputType<'CopyOrders'>,
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.CopyOrders,
      input,
      context,
    },
  };
};

export const createProjectChangeCreateOrderStatusReportsEvent = (
  vars: OperationInputType<'CreateOrderStatusReports'>,
  context?: ProjectChangeEventContext,
): LocalProjectChangeEventTemplate => {
  return {
    messageId: generateRTCMessageId(),
    operation: {
      name: OperationNames.CreateOrderStatusReports,
      input: vars,
      context,
    },
  };
};
