import { useNotificationStore } from '@/features/notifications/notificationStore';
import { getDaysFromDuration } from '@/helpers/utils/dates';
import { escapeCharacters } from '@/helpers/utils/escapeCharacters';
import { SEVEN_SECONDS_IN_MS } from '@/helpers/utils/timeConstants';
import { NodeName } from '@/repositories/utils/cache';
import { AnalyticsEvent } from '@/utils/analyticsEvents/categories';
import { support } from '@/utils/pages';

import { ConflictNotificationShownEvent } from './analytics';
import { RescheduledDependency } from './types';

export const showUndoRedoFailedNotification = () => {
  showActionFailedNotification(new ConflictNotificationShownEvent({ isUndoRedoConflict: true }));
};

export const showActionFailedNotification = (
  analyticsEvent: AnalyticsEvent = new ConflictNotificationShownEvent({
    isUndoRedoConflict: false,
  }),
) => {
  const notificationStore = useNotificationStore();

  notificationStore.push({
    titleI18nKey: 'objects.realTimeCollaboration.actionFailedNotificationTitle',
    bodyI18nKey: 'objects.realTimeCollaboration.actionFailedNotificationDescription',
    primaryAction: {
      callback: () => {
        notificationStore.closeCurrentNotification();
        window?.open(support.WORKING_TOGETHER, '_blank');
      },
      i18nKey: 'objects.realTimeCollaboration.actionFailedNotificationAction',
    },
    icon: 'info',
    type: 'attention',
    timeout: SEVEN_SECONDS_IN_MS,
    analyticsEvent,
  });
};

export const showLagUpdatedNotification = (dependency: RescheduledDependency) => {
  const lagInDays = getDaysFromDuration(dependency.lagInMinutes);
  const notificationStore = useNotificationStore();

  notificationStore.push({
    titleI18nKey: 'objects.realTimeCollaboration.lagUpdatedNotificationTitle',
    bodyI18nKey: 'objects.realTimeCollaboration.lagUpdatedNotificationDescription',
    bodyI18nKeyVariables: {
      lag: dependency.lagInMinutes < 0 ? `-${lagInDays}` : `${lagInDays}`,
      fromName: escapeCharacters(dependency.fromName),
      toName: escapeCharacters(dependency.toName),
      count: lagInDays,
    },
    bodyI18nKeyVariablesKeys: {
      fromType: dependency.fromType === NodeName.ORDER ? 'entities.orders' : 'entities.milestones',
      toType: dependency.toType === NodeName.ORDER ? 'entities.orders' : 'entities.milestones',
    },
    primaryAction: {
      callback: () => {
        notificationStore.closeCurrentNotification();
        window.open(support.HOW_DEPENDENCIES_WORK, '_blank');
      },
      i18nKey: 'objects.realTimeCollaboration.lagUpdatedNotificationLinkText',
    },
    icon: 'info',
    type: 'blue',
    timeout: SEVEN_SECONDS_IN_MS,
  });
};
