import { RouteLocationNormalized } from 'vue-router';

import {
  MembershipRepository,
  ProjectSubcontractorWorkerRepository,
} from '@/interfaces/repositories';
import { MembershipWithTenant } from '@/interfaces/repositories/memberships';
import { ProjectSubcontractorWorker } from '@/interfaces/repositories/projectSubcontractorWorker';
import { AuthenticationService, LoggingService } from '@/interfaces/services';
import { useUserStore } from '@/services/store/user';
import { AppErrorCode } from '@/utils/errors';

/** Returns all memberships of user and logs possible errors */
async function getMemberships(
  loggingService: LoggingService,
  membershipRepository: MembershipRepository,
): Promise<MembershipWithTenant[]> {
  const memberships = await membershipRepository.fetchAllOfUserHack().catch((e) => {
    loggingService.error(e, { code: AppErrorCode.NAVIGATION });
    return [];
  });

  return memberships;
}

/** Sets new membership to store */
function setAndReturnMembership(
  to: RouteLocationNormalized,
  memberships: MembershipWithTenant[],
  subcontractorWorkers: readonly ProjectSubcontractorWorker[],
): MembershipWithTenant | null | undefined {
  const userStore = useUserStore();

  const userHasOnlyOneMembershipAndIsNoSubcontractorWorker =
    memberships.length === 1 && !subcontractorWorkers.length;

  const membershipToSet = userHasOnlyOneMembershipAndIsNoSubcontractorWorker
    ? memberships[0]
    : memberships.find((mem) => {
        const isCurrentTenantMembership = mem.tenant.id === to.params.tenantId;
        const isCurrentlySetMembership = mem.id === userStore.membershipId;
        return isCurrentTenantMembership || isCurrentlySetMembership;
      });

  userStore.setMembershipId(membershipToSet?.id ?? null);

  return membershipToSet;
}

export interface GuardMeta {
  authenticationService: AuthenticationService;
  loggingService: LoggingService;
  membershipRepository: MembershipRepository;
  projectSubcontractorWorkerRepository?: ProjectSubcontractorWorkerRepository;
}

export type UseWithMembershipGuard = (
  activeMembership: MembershipWithTenant | undefined | null,
  memberships: MembershipWithTenant[],
  activeSubcontractorWorker: ProjectSubcontractorWorker | undefined | null,
  subcontractorWorkers: readonly ProjectSubcontractorWorker[],
) => any;

/** Use provided guard in addition to related membership */
export async function useWithMembership(
  { to }: { to: RouteLocationNormalized },
  {
    loggingService,
    membershipRepository,
    projectSubcontractorWorkerRepository,
  }: Omit<GuardMeta, 'authenticationService'>,
  guard: UseWithMembershipGuard,
): Promise<any> {
  const [memberships, subcontractorWorkers] = await Promise.all([
    getMemberships(loggingService, membershipRepository),
    getSubcontractorWorkers(projectSubcontractorWorkerRepository),
  ]);

  const activeMembership = setAndReturnMembership(to, memberships, subcontractorWorkers);
  const activeWorker = getActiveSubcontractorWorker(to, subcontractorWorkers, memberships);

  return guard(activeMembership, memberships, activeWorker, subcontractorWorkers);
}

/** Return project subcontractor worker entries for this user */
async function getSubcontractorWorkers(
  projectSubcontractorWorkerRepo: ProjectSubcontractorWorkerRepository | undefined,
): Promise<readonly ProjectSubcontractorWorker[]> {
  if (!projectSubcontractorWorkerRepo) {
    return [];
  }
  return projectSubcontractorWorkerRepo.fetchAllForOwnUserHack().catch(() => {
    return [];
  });
}

function getActiveSubcontractorWorker(
  to: RouteLocationNormalized,
  subcontractorWorkers: readonly ProjectSubcontractorWorker[] | undefined,
  memberships: MembershipWithTenant[],
): ProjectSubcontractorWorker | undefined {
  if (!subcontractorWorkers?.length) {
    return undefined;
  }

  // Only select a subcontract automatically if the user does NOT have memberships
  if (subcontractorWorkers.length === 1 && !memberships.length) {
    return subcontractorWorkers[0];
  }

  return subcontractorWorkers.find(({ subcontractor }) => subcontractor.id === to.params.tenantId);
}
