import gql from 'graphql-tag';

import { gql as gqlFn } from '@/graphql/__generated__';

export const projectSubcontractorsQuery = gqlFn(/* GraphQL */ `
  query projectSubcontractors($projectId: ID!) {
    projectSubcontractors(project: $projectId) {
      edges {
        node {
          ...ProjectSubcontractor
        }
      }
    }
  }
`);

export const projectSubcontractorDetailsQuery = gqlFn(/* GraphQL */ `
  query projectSubcontractorDetails($projectId: ID!) {
    projectSubcontractors(project: $projectId) {
      edges {
        node {
          ...ProjectSubcontractorDetails
        }
      }
    }
  }
`);

export const projectSubcontractorWorkerFragment = gql`
  fragment ProjectSubcontractorWorker on ProjectSubcontractorWorkerNode {
    id
    user {
      ...User
    }
    role
    subcontractor {
      id
      name
    }
    project {
      id
    }
  }
`;

export const projectSubcontractorDetailsFragment = gql`
  fragment ProjectSubcontractorDetails on ProjectSubcontractorNode {
    id
    contacts {
      edges {
        node {
          ...ProjectSubcontractorWorker
        }
      }
    }
  }
`;

export const projectSubcontractorFragment = gql`
  fragment ProjectSubcontractor on ProjectSubcontractorNode {
    id
    tenant {
      ...Tenant
    }
    tenantTradeVariationAssignments {
      edges {
        node {
          id
          isDefaultSubcontractor
          tenantTradeVariation {
            id
          }
        }
      }
    }
  }
`;

export const createSubcontractor = gqlFn(/* GraphQL */ `
  mutation createProjectSubcontractor($input: CreateProjectSubcontractorInput!) {
    createProjectSubcontractor(input: $input) {
      projectSubcontractor {
        ...ProjectSubcontractor
        ...ProjectSubcontractorDetails
      }
    }
  }
`);

export const updateSubcontractor = gqlFn(/* GraphQL */ `
  mutation updateProjectSubcontractor($input: UpdateProjectSubcontractorInput!) {
    updateProjectSubcontractor(input: $input) {
      projectSubcontractor {
        ...ProjectSubcontractor
        ...ProjectSubcontractorDetails
      }
    }
  }
`);

export const deleteSubcontractor = gqlFn(/* GraphQL */ `
  mutation deleteProjectSubcontractor($input: DeleteProjectSubcontractorInput!) {
    deleteProjectSubcontractor(input: $input) {
      success
    }
  }
`);
