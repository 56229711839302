import { Router } from 'vue-router';

import { AppApolloClient } from '@/interfaces/graphql';
import { IoCContainer } from '@/ioc/framework/container';
import { GoogleMaps } from '@/plugins/googleMaps';

import RepositoryModule from './repositories';
import ServiceModule from './services';

export function setupModules(
  container: IoCContainer,
  client: AppApolloClient,
  router: Router,
  googleMaps: GoogleMaps,
): void {
  RepositoryModule.onCreate(container, client);
  ServiceModule.onCreate(container, client, router, googleMaps);
}
