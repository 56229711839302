import { InteractiveEntityChanges } from '@/common/types';

import { ProjectChangeEventContext } from '../realTimeCollaboration/types';
import { callEntityStoreFromCommit, FullInteractiveEntityStores } from './actions';
import { changesAreConflictingToCurrentState } from './conflicts';
import { UndoRedoCommit } from './types';

interface InternalUndoRedoCommit extends UndoRedoCommit {
  prev: string | null;
}

export class UndoRedoQueue {
  private undoList: Map<string, InternalUndoRedoCommit> = new Map();

  private undoPointer: Ref<string | null> = ref(null);

  private redoList: Map<string, InternalUndoRedoCommit> = new Map();

  private redoPointer: Ref<string | null> = ref(null);

  private commitChanges: Map<string, InteractiveEntityChanges> = new Map();

  private commitContexts: Map<string, ProjectChangeEventContext> = new Map();

  public constructor(private entityStores: FullInteractiveEntityStores) {}

  public commit(
    commit: UndoRedoCommit,
    changes: InteractiveEntityChanges,
    context?: ProjectChangeEventContext,
  ): UndoRedoCommit {
    this.undoList.set(commit.id, {
      ...commit,
      prev: this.undoPointer.value,
    });
    this.undoPointer.value = commit.id;
    this.redoList.clear();
    this.redoPointer.value = null;
    this.commitChanges.set(commit.id, changes);
    if (context) {
      this.commitContexts.set(commit.id, context);
    } else {
      this.commitContexts.delete(commit.id);
    }
    return commit;
  }

  private reverseCommit(
    id: string,
    commit: UndoRedoCommit,
    changes: InteractiveEntityChanges,
    context?: ProjectChangeEventContext,
  ): UndoRedoCommit {
    if (!this.undoList.has(id) && !this.redoList.has(id)) {
      throw new Error('Commit not found');
    }
    const undoCommit = this.undoList.get(id);
    if (undoCommit) {
      this.undoList.delete(undoCommit.id);
      this.commitChanges.delete(undoCommit.id);
      this.commitContexts.delete(undoCommit.id);

      this.undoPointer.value = undoCommit.prev ?? null;
      this.redoList.set(commit.id, {
        ...commit,
        prev: this.redoPointer.value,
      });
      this.redoPointer.value = commit.id;
      this.commitChanges.set(commit.id, changes);
      if (context) {
        this.commitContexts.set(commit.id, context);
      }
      return commit;
    }

    const redoCommit = this.redoList.get(id)!;
    this.redoList.delete(redoCommit.id);
    this.commitChanges.delete(redoCommit.id);
    this.commitContexts.delete(redoCommit.id);

    this.redoPointer.value = redoCommit.prev ?? null;
    this.undoList.set(commit.id, {
      ...commit,
      prev: this.undoPointer.value,
    });
    this.undoPointer.value = commit.id;
    this.commitChanges.set(commit.id, changes);
    if (context) {
      this.commitContexts.set(commit.id, context);
    }
    return commit;
  }

  public async undo(): Promise<UndoRedoCommit | undefined> {
    if (!this.undoPointer.value) {
      return Promise.resolve(undefined);
    }
    const undoCommit = this.undoList.get(this.undoPointer.value);
    if (!undoCommit) {
      return Promise.resolve(undefined);
    }

    if (this.isConflicting(undoCommit)) {
      this.drop(undoCommit.id);
      return Promise.reject(new Error('Conflict detected in commit'));
    }
    return callEntityStoreFromCommit(
      this.entityStores,
      undoCommit,
      this.commitContexts.get(undoCommit.id),
    )
      .then(({ commit, changes, context }) => {
        return this.reverseCommit(undoCommit.id, commit, changes, context);
      })
      .catch((error) => {
        this.drop(undoCommit.id);
        return Promise.reject(error);
      });
  }

  public async redo(): Promise<UndoRedoCommit | undefined> {
    if (!this.redoPointer.value) {
      return Promise.resolve(undefined);
    }
    const redoCommit = this.redoList.get(this.redoPointer.value);
    if (!redoCommit) {
      return Promise.resolve(undefined);
    }

    if (this.isConflicting(redoCommit)) {
      this.drop(redoCommit.id);
      return Promise.reject(new Error('Conflict detected in commit'));
    }
    return callEntityStoreFromCommit(
      this.entityStores,
      redoCommit,
      this.commitContexts.get(redoCommit.id),
    )
      .then(({ commit, changes, context }) => {
        return this.reverseCommit(redoCommit.id, commit, changes, context);
      })
      .catch((error) => {
        this.drop(redoCommit.id);
        return Promise.reject(error);
      });
  }

  public getCommit(id: string): UndoRedoCommit | undefined {
    return this.undoList.get(id) || this.redoList.get(id);
  }

  public drop(id: string): void {
    if (this.undoList.has(id)) {
      const commit = this.undoList.get(id)!;
      if (this.undoPointer.value === id) {
        this.undoPointer.value = commit.prev ?? null;
      }
      this.undoList.delete(id);
    } else if (this.redoList.has(id)) {
      const commit = this.redoList.get(id)!;
      if (this.redoPointer.value === id) {
        this.redoPointer.value = commit.prev ?? null;
      }
      this.redoList.delete(id);
    }
    this.commitChanges.delete(id);
    this.commitContexts.delete(id);
  }

  public reset(): void {
    this.undoList.clear();
    this.undoPointer.value = null;
    this.redoList.clear();
    this.redoPointer.value = null;
    this.commitChanges.clear();
    this.commitContexts.clear();
  }

  public get canUndo(): ComputedRef<boolean> {
    return computed(() => !!this.undoPointer.value);
  }

  public get canRedo(): ComputedRef<boolean> {
    return computed(() => !!this.redoPointer.value);
  }

  private isConflicting(commit: UndoRedoCommit): boolean {
    const historicChanges = this.commitChanges.get(commit.id);
    if (!historicChanges) {
      return false;
    }
    return changesAreConflictingToCurrentState(this.entityStores, historicChanges);
  }
}
