<template>
  <span
    ref="badge"
    class="ds-badge"
    :class="[`--${color}`, `--${size}`, { '--outline': shouldOutline, '--dark': dark }]"
    :style="customStyle"
    :title="badgeTitle"
  >
    <slot />
  </span>
</template>

<script setup lang="ts">
import { getIconColorOfBackground, hasHighLuminance } from '@/helpers/utils/colors';

import { DsBadgeColor } from './types';

const props = defineProps({
  color: {
    type: String as PropType<DsBadgeColor>,
    default: 'grey',
  },
  customColor: { type: String, default: '' },
  outline: { type: Boolean, default: null },
  size: { type: String as PropType<'sm' | 'md' | 'lg'>, default: 'md' },
  dark: { type: Boolean, default: false },
});

const customStyle = computed(() =>
  props.customColor
    ? { backgroundColor: props.customColor, color: getIconColorOfBackground(props.customColor) }
    : undefined,
);

const shouldOutline = computed(() => {
  if (props.outline !== null) {
    return props.outline;
  }
  if (props.customColor) {
    return hasHighLuminance(props.customColor);
  }

  return false;
});

const badge = ref<HTMLElement | null>(null);
const badgeTitle = computed(() => badge.value?.innerText || '');
</script>

<style scoped>
.ds-badge {
  @apply tw-inline-block tw-items-center tw-truncate;
  @apply tw-rounded-xs tw-mix-blend-multiply;
}
.ds-badge.--primary {
  @apply tw-bg-blue-50 tw-text-blue-700;
}
.ds-badge.--primary.--dark {
  @apply tw-bg-blue-700 tw-text-white;
}
.ds-badge.--grey {
  @apply tw-bg-grey-50 tw-text-grey-700;
}
.ds-badge.--grey.--dark {
  @apply tw-bg-grey-500 tw-text-white;
}
.ds-badge.--blue-grey {
  @apply tw-bg-info-50 tw-text-info-700;
}
.ds-badge.--warning {
  @apply tw-bg-warning-50 tw-text-warning-700;
}
.ds-badge.--success {
  @apply tw-bg-success-50 tw-text-success-700;
}
.ds-badge.--grey.--outline {
  @apply tw-border tw-border-solid tw-border-grey-700 tw-bg-white;
}
.ds-badge.--light.--outline {
  @apply tw-border tw-border-solid tw-border-grey-100 tw-bg-white tw-text-grey-500;
}
.ds-badge.--warning.--outline {
  @apply tw-border tw-border-solid tw-border-warning-500 tw-bg-white tw-text-warning-500;
}
.ds-badge.--sm {
  @apply tw-ds-text-xs--medium tw-gap-1 tw-px-2 tw-py-0.5;
}
.ds-badge.--md {
  @apply tw-ds-text-sm--medium tw-gap-1.5 tw-px-2.5 tw-py-0.5;
}
.ds-badge.--lg {
  @apply tw-ds-text-sm--medium tw-gap-1.5 tw-px-3 tw-py-1;
}
</style>
