// define by backend, do not change by hand
export const enum GraphQLErrorCode {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  PERMISSION_DENIED = 'FORBIDDEN',
  ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND',
  GRAPHQL_VALIDATION_FAILED = 'GRAPHQL_VALIDATION_FAILED',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  BAD_USER_INPUT = 'BAD_USER_INPUT',
}

// can be changed freely
export const enum AppErrorCode {
  NAVIGATION = 'NAVIGATION_ERROR',
  NETWORK = 'NETWORK_ERROR',
  FIREBASE = 'FIREBASE_ERROR',
  SERVICE_WORKER = 'SERVICE_WORKER_ERROR',
  STORE_ENTITY_NOT_FOUND = 'STORE_ENTITY_NOT_FOUND',
  BULK_API_ERROR = 'BULK_API_ERROR',
}

type GraphQLErrorArgs = {
  message: string;
  code: string;
  operationName: string;
};

export class GraphQLError extends Error {
  public message: string;

  public code: string;

  public operationName: string;

  public constructor({ message, code, operationName }: GraphQLErrorArgs) {
    super();
    this.message = `Operation named "${operationName}" failed: ${message}`;
    this.code = code;
    this.operationName = operationName;

    this.name = 'GraphQLError';
  }
}

class BaseError extends Error {
  public code?: string;

  public constructor(message: string, code?: string) {
    super(message);
    this.code = code;

    if (!this.name) Object.defineProperty(this, 'name', { value: 'BaseError' });
  }
}

export class StoreEntityNotFoundError extends BaseError {
  public constructor(entityName: string, id?: unknown) {
    const idPart = id === undefined ? '' : `${id}`;
    const message = `The given ${entityName} with ID "${idPart}" was not found`;
    super(message, AppErrorCode.STORE_ENTITY_NOT_FOUND);

    Object.defineProperty(this, 'name', { value: 'StoreEntityNotFoundError' });
  }
}

export class BulkApiError extends BaseError {
  public constructor(message: string, code: string = AppErrorCode.BULK_API_ERROR) {
    super(message, code);
    Object.defineProperty(this, 'name', { value: 'BulkApiError' });
  }
}
