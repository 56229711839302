import { OrderDependencyEntity, OrderEntity, PartialEntity } from '@/common/types';
import { useOrderDependencyStoreV2 } from '@/features/orderDependencies';
import { useOrderStoreV2 } from '@/features/orders/orderStoreV2';
import { OperationInputType } from '@/features/realTimeCollaboration/types';
import { StatusReport } from '@/helpers/orders/status';
import { NodeName } from '@/repositories/utils/cache';

export function convertOrderUpdateOperationInputToPartialOrderEntity(
  input: OperationInputType<'UpdateOrders'>,
): PartialEntity<OrderEntity>[] {
  return input.map((o) => ({
    id: o.id,
    ...(o.calendarId !== undefined ? { calendar: { id: o.calendarId! } } : {}),
    ...(o.name !== undefined ? { name: o.name! } : {}),
    ...(o.startAt !== undefined ? { startAt: new SchedulingDate(o.startAt!) } : {}),
    ...(o.finishAt !== undefined ? { finishAt: new SchedulingDate(o.finishAt!) } : {}),
    ...(o.wbsSectionId !== undefined ? { wbsSection: { id: o.wbsSectionId! } } : {}),
    ...(o.isFixed !== undefined ? { isFixed: o.isFixed! } : {}),
    ...(o.subcontractorId !== undefined
      ? { subcontractor: o.subcontractorId ? { id: o.subcontractorId } : null }
      : {}),
    ...(o.dryingBreak !== undefined ? { dryingBreak: o.dryingBreak } : {}),
    ...(o.tradeId ? { tenantTradeVariation: { id: o.tradeId } } : undefined),
    ...(o.workingTimeDuration != null ? { duration: o.workingTimeDuration } : {}),
  }));
}

export function convertOrderCopyOperationInputToEntities(input: OperationInputType<'CopyOrders'>): {
  orders: OrderEntity[];
  dependencies: OrderDependencyEntity[];
} {
  const orderStore = useOrderStoreV2();
  const orderDependencyStore = useOrderDependencyStoreV2();

  const newOrderIdsToOriginalOrderIds = new Map<string, string>();

  const generatedOrders = input.orders.map<OrderEntity>((copiedOrder) => {
    const originalOrder = orderStore.orders.get(copiedOrder.originalOrderId);
    if (!originalOrder) {
      throw new Error('Trying to copy an order that does not exist');
    }
    newOrderIdsToOriginalOrderIds.set(copiedOrder.newId, copiedOrder.originalOrderId);
    return {
      id: copiedOrder.newId,
      calendar: { id: originalOrder.calendar.id },
      finishAt: new SchedulingDate(copiedOrder.finishAt),
      startAt: new SchedulingDate(copiedOrder.startAt),
      finishedAt: null,
      dryingBreak: copiedOrder.dryingBreak,
      isFixed: false,
      name: originalOrder.name,
      progress: 0,
      status: StatusReport.NOT_SET,
      tradeSequenceInstanceId: null,
      tenantTradeVariation: originalOrder.tenantTradeVariation,
      subcontractor: originalOrder.subcontractor,
      wbsSection: {
        id: copiedOrder.wbsSectionId!,
        __typename: NodeName.SECTION,
      },
    };
  });

  const generatedOrderDependencies = input.idMapping.dependencies
    .map<OrderDependencyEntity | null>((dependencyMapping) => {
      const originalFromId = newOrderIdsToOriginalOrderIds.get(dependencyMapping.fromOrderId);
      const originalToId = newOrderIdsToOriginalOrderIds.get(dependencyMapping.toOrderId);
      if (!originalFromId || !originalToId) return null;

      const originalOrderDependency = orderDependencyStore.getDependencyForRelation(
        originalFromId,
        originalToId,
      );
      if (!originalOrderDependency) return null;
      return {
        ...originalOrderDependency,
        id: dependencyMapping.dependencyId,
        from: { id: dependencyMapping.fromOrderId, __typename: NodeName.ORDER },
        to: { id: dependencyMapping.toOrderId, __typename: NodeName.ORDER },
      };
    })
    .filter(Boolean) as OrderDependencyEntity[];

  return {
    orders: generatedOrders,
    dependencies: generatedOrderDependencies,
  };
}
