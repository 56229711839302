import { Key } from '@/interfaces';
import {
  AttachmentRepository,
  MembershipRepository,
  ProjectSubcontractorWorkerRepository,
  TenantRepository,
  UserRepository,
} from '@/interfaces/repositories';
import {
  AuthenticationService,
  CompressionService,
  FileDownloadService,
  FileUploadService,
  LocationService,
  LoggingService,
  MembershipService,
} from '@/interfaces/services';

import { IoCContainer } from './framework/container';
import { Provider } from './provider';

export interface RuntimeError {
  code?: string | undefined;
  message: string;
}

/**
 * Resolves a service from the IoCContainer
 */
function get<T>(key: Key, container: IoCContainer): T {
  const value: T | undefined = container.resolve<T>(key);
  if (!value) {
    const error: RuntimeError = {
      message: `No builder provided for key '${key}'.`,
    };
    throw error;
  }
  return value;
}

/** Repository access helpers */

export function getMembershipRepository(container: IoCContainer): MembershipRepository {
  return get<MembershipRepository>('MembershipRepository', container);
}

export function getTenantRepository(container: IoCContainer): TenantRepository {
  return get<TenantRepository>('TenantRepository', container);
}

export function getUserRepository(container: IoCContainer): UserRepository {
  return get<UserRepository>('UserRepository', container);
}

/** Service access helpers */
export function getAuthenticationServie(container: IoCContainer): AuthenticationService {
  return get<AuthenticationService>('AuthenticationService', container);
}

export function getLoggingService(container: IoCContainer): LoggingService {
  return get<LoggingService>('LoggingService', container);
}

export function getLocationService(container: IoCContainer): LocationService {
  return get<LocationService>('LocationService', container);
}

export class ContainerProvider implements Provider {
  public constructor(private container: IoCContainer) {}

  /** Repositories */

  public get attachmentRespository(): AttachmentRepository {
    return get<AttachmentRepository>('AttachmentRepository', this.container);
  }

  public get membershipRepository(): MembershipRepository {
    return getMembershipRepository(this.container);
  }

  public get projectSubcontractorWorkerRepository(): ProjectSubcontractorWorkerRepository {
    return get<ProjectSubcontractorWorkerRepository>(
      'ProjectSubcontractorWorkerRepository',
      this.container,
    );
  }

  public get tenantRepository(): TenantRepository {
    return getTenantRepository(this.container);
  }

  public get userRepository(): UserRepository {
    return getUserRepository(this.container);
  }

  /** Services */

  public get authenticationService(): AuthenticationService {
    return getAuthenticationServie(this.container);
  }

  public get compressionService(): CompressionService {
    return get<CompressionService>('CompressionService', this.container);
  }

  public get fileDownloadService(): FileDownloadService {
    return get<FileDownloadService>('FileDownloadService', this.container);
  }

  public get fileUploadService(): FileUploadService {
    return get<FileUploadService>('FileUploadService', this.container);
  }

  public get loggingService(): LoggingService {
    return getLoggingService(this.container);
  }

  public get membershipService(): MembershipService {
    return get<MembershipService>('MembershipService', this.container);
  }

  public get locationService(): LocationService {
    return getLocationService(this.container);
  }
}
