import gql from 'graphql-tag';

export enum TicketStatus {
  PENDING = 'PENDING',
  REPORTED_DONE = 'REPORTED_DONE',
  REJECTED_BY_CREATOR = 'REJECTED_BY_CREATOR',
  CLOSED = 'CLOSED',
}

// TODO TITO: Rename to Ticket when old ticket stuff is removed
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TitoTicket = gql`
  fragment TitoTicket on TicketNode {
    id
    title
    createdAt
    deadline
    description
    updatedAt
    subcontractor {
      id
      name
      #logoUrl
    }
    #project?
    responsibleUser {
      ...User
    }
    status
    order {
      id
      name
      status
      tenantTradeVariation {
        name
      }
      wbsSection {
        id
      }
    }
    attachments {
      edges {
        node {
          ...Attachment
        }
      }
    }
    links {
      ...Link
    }
  }
`;
