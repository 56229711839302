import { FirebaseOptions, initializeApp } from 'firebase/app';

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyDhUDz0grSS7hu_zOumkMRi3SwzZm_gouE',
  authDomain: 'api-7703238896555888020-368429.firebaseapp.com',
  databaseURL: 'https://api-7703238896555888020-368429.firebaseio.com',
  projectId: 'api-7703238896555888020-368429',
  storageBucket: 'api-7703238896555888020-368429.appspot.com',
  messagingSenderId: '1065048304126',
  appId: '1:1065048304126:web:3b724d4710bfa91fdc38af',
  measurementId: 'G-DNZCDXKF29',
};

export const FIREBASE_API_KEY = firebaseConfig.apiKey!;

export function initializeFirebaseApp(): void {
  initializeApp(firebaseConfig);
}

export function getMicrosoftFirebaseApp() {
  return initializeApp(firebaseConfig, 'microsoft');
}
