import {
  addDays,
  differenceInDays,
  differenceInSeconds,
  endOfISOWeek,
  formatISO,
  getDay,
  isBefore,
  isDate,
  isEqual,
  isValid,
  setDay,
  startOfDay,
  startOfISOWeek,
  subSeconds,
} from 'date-fns';
import { Composer, ComposerTranslation } from 'vue-i18n';

import { WorkingTime } from '@/features/calendars/calendarTypes';
import { START_OF_WEEK } from '@/helpers/dates/config';
import { HOURS_PER_DAY, ISO_WEEKDAYS, MINUTES_PER_HOUR } from '@/helpers/utils/timeConstants';

import { copy } from './objects';

/** Returns ISO string of weekday */
export function getISOWeekday(weekday: number): string {
  return ISO_WEEKDAYS[weekday % 7];
}

/** Returns the index of the weekDay starting on day of the input date */
export function getWeekDay(date: Date): number {
  return (getDay(date) + 7 - START_OF_WEEK) % 7;
}

/** Returns a date with the given weekday */
export function getDateOfWeekday(day: number): Date {
  return setDay(new Date(), (day + START_OF_WEEK) % 7, { weekStartsOn: START_OF_WEEK });
}

/** Returns readable representation of the days, hours and minutes of a duration */
export function getReadableDaysHoursMinutes(
  t: ComposerTranslation,
  duration: number,
  long: boolean = false,
  minutesPerDay: number = MINUTES_PER_HOUR * HOURS_PER_DAY,
): string {
  const { days, hours, minutes } = getDaysHoursMinutesFromDuration(duration, minutesPerDay);

  const dayLabel = long ? t('objects.duration.dayLong', days) : t('objects.duration.day');
  const hourLabel = long ? t('objects.duration.hourLong', hours) : t('objects.duration.hour');
  const minuteLabel = long ? t('objects.duration.minuteLong') : t('objects.duration.minute');

  let daysHoursMinutes = '';

  if (days) {
    daysHoursMinutes = `${daysHoursMinutes}${long ? `${days} ` : days}${dayLabel}`;
  }
  if (hours) {
    daysHoursMinutes = daysHoursMinutes
      ? `${daysHoursMinutes} ${long ? `${hours} ` : hours}${hourLabel}`
      : `${long ? `${hours} ` : hours}${hourLabel}`;
  }
  if (minutes) {
    daysHoursMinutes = daysHoursMinutes
      ? `${daysHoursMinutes} ${long ? `${minutes} ` : minutes}${minuteLabel}`
      : `${long ? `${minutes} ` : minutes}${minuteLabel}`;
  }

  return daysHoursMinutes;
}

/** Returns readable representation of weeks and days of a duration */
export function getReadableWeeksAndDays(t: ComposerTranslation, duration: number): string {
  const { weeks, days } = getWeeksAndDaysFromDuration(duration);

  let weeksAndDays = '';
  if (weeks) {
    weeksAndDays += `${t('time.weeks', weeks)} `;
  }
  if (days) {
    weeksAndDays += `${t('time.days', days)} `;
  }

  return weeksAndDays.trim() || t('time.days', 0);
}

/** Retuns date and time string of a given date / dateString */
export function getDateTime(dateVar: Date | string): { date: string; time: string } {
  const dateTime = dateVar instanceof Date ? formatISO(dateVar) : dateVar;
  const [date, time] = dateTime.split('T');
  return { date, time };
}

/** Replaces time of given date or date string with the provided time string */
export function replaceTimeString<T extends Date | string>(date: T, newTime: string): T {
  const regex = /T.*/gi;

  if (isDate(date)) {
    const dateObject = new Date(date);
    const total = getMinutesOfTimeString(newTime);
    const hours = Math.floor(total / MINUTES_PER_HOUR);
    const minutes = total - hours * MINUTES_PER_HOUR;

    dateObject.setHours(hours);
    dateObject.setMinutes(minutes);
    return dateObject as T;
  }

  return (date as string).replace(regex, `T${newTime}`) as T;
}

export interface UseTimeCount {
  startTimeCount: () => void;
  stopTimeCount: () => number;
}

/** Provides functionality to start and stop watching passed time in seconds */
export function useTimeCount(): UseTimeCount {
  let startDate: Date | null = null;

  function startTimeCount(): void {
    startDate = new Date();
  }

  function stopTimeCount(): number {
    const endDate = new Date();
    return startDate ? differenceInSeconds(endDate, startDate) : 0;
  }

  return {
    startTimeCount,
    stopTimeCount,
  };
}

/** Checks if a given date is at the start of the day */
export function isStartOfDay(date: Date | null): boolean {
  if (!date) return false;
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  return hour === 0 && minute === 0 && second === 0;
}

/** Provides an array of strings which depict all the times 15min apart */
const possibleMinutes = ['00', '15', '30', '45'];
export const timeValues = [...Array(HOURS_PER_DAY).keys()].flatMap((hour) =>
  possibleMinutes.map((minute) => `${padTime(hour)}:${minute}`),
);

/** Retrieves the number of minutes of a time in the format hh:mm */
export function getMinutesOfTimeString(value: string): number {
  const time = getHoursAndMinutesOfString(to24HourTimeString(value));
  if (!time) return 0;
  return time.hours * MINUTES_PER_HOUR + time.minutes;
}

/**
 * Returns hours and minutes separately as number from time string
 * @param value
 */
export function getHoursAndMinutesOfString(
  value: string,
): { hours: number; minutes: number } | undefined {
  // clean string from appendix, e.g. AM, PM
  const time = value.split(' ')[0];
  if (!time.includes(':')) return undefined;

  const [hours, minutes] = time.split(':');
  return {
    hours: parseInt(hours, 10),
    minutes: parseInt(minutes, 10),
  };
}

/**
 * Returns 24 hour representation of given time string
 * @param value
 */
export function to24HourTimeString(value: string): string {
  if (!value) return value;
  const time = getHoursAndMinutesOfString(value);
  if (!time) return value;
  const { hours, minutes } = time;

  if (value.includes('AM')) {
    return `${padTime(hours === 12 ? 0 : hours)}:${padTime(minutes)}`;
  }

  if (value.includes('PM')) {
    return `${padTime(hours === 12 ? hours : hours + 12)}:${padTime(minutes)}`;
  }

  return `${padTime(hours)}:${padTime(minutes)}`;
}

/** Prepend time if necessary to be 2-digits */
export function padTime(time: number | string): string {
  const value = typeof time === 'string' ? time : time.toString();
  return value.padStart(2, '0');
}

/**
 * Returns 12 hour representation of given time string
 * @param value
 */
export function to12HourTimeString(value: string): string {
  if (!value || value.includes('AM') || value.includes('PM')) return value;

  const [hour, minute] = value.split(':');
  const parsedHour = parseInt(hour, 10);
  // handle case 0:00
  if (!parsedHour) return `12:${minute} AM`;
  // handle cases 1:00 to 11:00
  if (parsedHour < 12) {
    return `${padTime(parsedHour)}:${minute} AM`;
  }
  // handle case 12:00
  if (parsedHour === 12) return `12:${minute} PM`;
  // handle cases 13:00 to 23:00
  return `${padTime(parsedHour - 12)}:${minute} PM`;
}

/** Returns the next date with the given weekday, starting from the provided date */
export function getNextWeekDay(date: Date, weekDay: number): Date {
  let nextDate = date;
  while (nextDate.getDay() !== weekDay) {
    nextDate = addDays(nextDate, 1);
  }
  return nextDate;
}

/** Returns ISO string of date or given date string */
export function getStringOfDateOrString(date: string | Date): string {
  if (typeof date === 'string') return new Date(date).toISOString();
  return date?.toISOString() ?? '';
}

/** Returns displayed time depending on current time. E.g. shows time only if different to midnight */
export function useDisplayedDateDependentToTime({
  d,
}: Composer): (start: Date, end: Date) => string {
  const formatDate = (date: SchedulingDate) => d(date, 'dateWithWeekday');
  const formatLong = (date: SchedulingDate) => d(date, 'longWithShortW');
  const formatShort = (date: SchedulingDate) => d(date, 'time');

  function getDisplayedDate(_start: SchedulingDate, _end: SchedulingDate): string {
    const dayDifference = differenceInDays(startOfDay(_end), startOfDay(_start));
    // hide time, if dates are midnight
    if (isStartOfDay(_start) && isStartOfDay(_end)) {
      // cutoff end, if start and end are on same day
      if (dayDifference > 1) {
        return `${formatDate(_start)} - ${formatDate(subSeconds(_end, 1))}`;
      }

      return `${formatDate(_start)}`;
    }

    if (!isValidDate(_start) && !isValidDate(_end)) return '';
    if (!isValidDate(_end)) return `${formatLong(_start)} -`;

    // merge end time into start date, if start and end are on same day
    return dayDifference > 0
      ? `${formatLong(_start)} - ${formatLong(_end)}`
      : `${formatLong(_start)} - ${formatShort(_end)}`;
  }

  return getDisplayedDate;
}

/** Creates a new UTC date */
function newDateUTC(
  fullYear: number,
  month: number,
  day: number,
  hour: number,
  minute: number,
  second: number,
  millisecond: number,
): Date {
  // https://github.com/marnusw/date-fns-tz/blob/master/src/_lib/newDateUTC/index.js
  const utcDate = new Date(0);
  utcDate.setUTCFullYear(fullYear, month, day);
  utcDate.setUTCHours(hour, minute, second, millisecond);
  return utcDate;
}

/** Converts a zoned time to UTC */
export function zonedTimeToUtc(d: Date): Date {
  // Does the same as zonedTimeToUtc but doesn't require
  // timezone as string.
  // https://github.com/marnusw/date-fns-tz/blob/master/src/zonedTimeToUtc/index.js
  const utc = newDateUTC(
    d.getFullYear(),
    d.getMonth(),
    d.getDate(),
    d.getHours(),
    d.getMinutes(),
    d.getSeconds(),
    d.getMilliseconds(),
  ).getTime();

  return new Date(utc);
}

/** Returns indicator, if string is a valid time */
export function isValidTimeString(text: string): boolean {
  return !!text && /[0-2][0-9]:[0-5][0-9]/.test(text.split(' ')[0]); // clean from appendix like: AM,PM
}

/**
 * Checks if a given working time interval is valid
 * @param from
 * @param to
 */
export function isValidWorkingTime(from: string, to: string): boolean {
  return (
    isValidTimeString(from) &&
    isValidTimeString(to) &&
    ((getMinutesOfTimeString(to) === 0 && getMinutesOfTimeString(from) === 0) ||
      getMinutesOfTimeString(to) > getMinutesOfTimeString(from))
  );
}

/**
 * Returns all working times, that are overlapping to other intervals
 * @param workingTimes
 */
export function getOverlappingWorkingTimes(workingTimes: WorkingTime[]): WorkingTime[] {
  return copy(workingTimes)
    .sort((a, b) => getMinutesOfTimeString(a.from) - getMinutesOfTimeString(b.from))
    .reduce((intervals, time, i, array) => {
      if (
        // push, if new from overlaps with previous to, or previous to already was 0:00 on next day
        i > 0 &&
        (getMinutesOfTimeString(time.from) < getMinutesOfTimeString(array[i - 1].to) ||
          !getMinutesOfTimeString(array[i - 1].to))
      ) {
        intervals.push(time);
      }

      return intervals;
    }, [] as WorkingTime[]);
}

/**
 * Checks if a given collection of working time intervals is valid and does not overlap
 * @param from
 * @param to
 */
export function hasValidWorkingTimes(workingTimes: WorkingTime[]): boolean {
  return (
    !!workingTimes.length &&
    workingTimes.every((workingTime) => isValidWorkingTime(workingTime.from, workingTime.to)) &&
    !getOverlappingWorkingTimes(workingTimes).length
  );
}

/**
 * Returns duration, and respects the minutes defined per day
 * @param duration
 * @param minutesPerDay
 */
export function getDurationAndRespectMinutesPerDay(
  duration: number,
  minutesPerDay: number,
): number {
  const days = Math.floor(duration / (MINUTES_PER_HOUR * HOURS_PER_DAY));
  // replace days with minutes per day, instead of whole day
  if (days > 0) {
    const remainingMinutes = duration - days * MINUTES_PER_HOUR * HOURS_PER_DAY;
    return days * minutesPerDay + remainingMinutes;
  }
  return duration;
}

/** Returns indicator, if given date is valid */
export function isValidDate(date: Date): boolean {
  return date instanceof Date && !Number.isNaN(date.getTime());
}

export interface TimeComparisonObject {
  equals: boolean;
  additionalTimes: WorkingTime[];
}

/**
 * Datatype that holds start and end dates of a timerange
 */
export interface DateRangeObject {
  startDate: Date;
  endDate: Date;
}

/** Compares two given lists with time periods for equality. Allows the latter one to be longer and
 *  still returns true.
 * @param prev - Previous working times to compare
 * @param current - Current working times to compare
 * @returns {TimeComparisonObject} - Returns a boolean value for similarity and the possible additional times from latter argument
 */
export function compareTimes(prev: WorkingTime[], current: WorkingTime[]): TimeComparisonObject {
  const timeObject = {} as TimeComparisonObject;
  // Early return if previous day had more times
  if (prev.length > current.length) {
    return timeObject;
  }

  // Check whether all elements that were in the previous working day times are in the current one
  timeObject.equals = prev.every(
    (time, index) => time.from === current[index].from && time.to === current[index].to,
  );
  // Is is allowed for the current Working time to have additional times (e.g. 9:00 - 17:00, 17:30 - 19:00)
  // This time will then be appended as individual time entry
  if (timeObject.equals && prev.length < current.length) {
    timeObject.additionalTimes = current.slice(prev.length);
  }

  return timeObject;
}

/**
 * Checks whether given value is parsable as date object.
 * @param value
 * @returns
 */
export function isDateLikeObject(value: string | Date): boolean {
  return (typeof value === 'string' && isValid(new Date(value))) || value instanceof Date;
}

/**
 * Parses date like objects and compares millisecond-wise.
 * @param dateLeft
 * @param dateRight
 * @returns
 */
export function dateLikesAreEqual(dateLeft: string | Date, dateRight: string | Date): boolean {
  if (!isDateLikeObject(dateLeft) || !isDateLikeObject(dateRight)) return false;
  return new Date(dateLeft).getTime() === new Date(dateRight).getTime();
}

/** Returns the number of events, that are max. simultaneously happening
 * Assumes that for all orders have startDate < EndDate
 */
export function getNumberOfMaxOverlappingEvents<T extends { start: Date; end: Date }>(
  objects: T[],
): number {
  if (!objects.length) return 0;
  // Create a list from the provided events that hold one of the dates and an operation
  // This list is then used to create a stack, which basically represents the simultaneous events at a given time
  const dateListWithOperation: { date: Date; operation: 'begin' | 'end'; element: T }[] = [];
  objects.forEach((element) => {
    dateListWithOperation.push({ date: element.start, operation: 'begin', element });
    dateListWithOperation.push({ date: element.end, operation: 'end', element });
  });
  // Sort the list of dates with operation, because we use it to create a stack
  dateListWithOperation.sort((a, b) => {
    if (!isEqual(a.date, b.date)) return isBefore(a.date, b.date) ? -1 : 1;

    // if both elements have zero time range, they should be considered stacked
    if (isEqual(a.element.start, a.element.end) && isEqual(b.element.start, b.element.end)) {
      if (a.operation === 'end') return 1;
      return -1;
    }
    if (a.operation === 'end') return -1;
    return 1;
  });
  let maxOverlapping = 0;
  let currentlyOverlapping = 0;
  // Iterate through the list of dates and increase the stack if we have an order beginning, remove one if one ended
  dateListWithOperation.forEach((dateWithOperation) => {
    if (dateWithOperation.operation === 'begin') {
      currentlyOverlapping++;
    } else currentlyOverlapping--;
    maxOverlapping = Math.max(currentlyOverlapping, maxOverlapping);
  });
  return maxOverlapping;
}

/** Adds a delta to a given date */
export function addDelta(
  date: Date,
  deltaDates: { endDate: SchedulingDate; startDate: SchedulingDate },
): Date {
  const deltaInMs = deltaDates.endDate.getTime() - deltaDates.startDate.getTime();
  const newDate = new SchedulingDate(date.getTime() + deltaInMs);
  return newDate;
}

/** Returns the days, hours, and minutes from a given duration */
export function getDaysHoursMinutesFromDuration(
  duration: number,
  minutesPerDay: number = MINUTES_PER_HOUR * 24,
): { days: number; hours: number; minutes: number } {
  const absDuration = Math.abs(duration);
  const days = Math.floor(absDuration / minutesPerDay);
  const hours = Math.floor((absDuration - days * minutesPerDay) / MINUTES_PER_HOUR);
  const minutes = absDuration - days * minutesPerDay - hours * MINUTES_PER_HOUR;
  return { days, hours, minutes };
}

/** Returns the weeks and days from a given duration */
export function getWeeksAndDaysFromDuration(
  duration: number,
  minutesPerDay: number = MINUTES_PER_HOUR * 24,
): { weeks: number; days: number } {
  const minutesPerWeek = minutesPerDay * 7;
  const absDuration = Math.abs(duration);
  const weeks = Math.floor(absDuration / minutesPerWeek);
  const days = Math.floor((absDuration % minutesPerWeek) / minutesPerDay);
  return { weeks, days };
}

/** Returns the days from a given duration */
export function getDaysFromDuration(
  duration: number,
  minutesPerDay: number = MINUTES_PER_HOUR * 24,
): number {
  const absDuration = Math.abs(duration);

  return Math.floor(absDuration / minutesPerDay);
}

// converts "weekAndYear" from w#-year to the following format 06.02.2023 – 12.02.2023
export function getWeekRange(weekAndYear: string, i18n: Composer) {
  const [week, year] = weekAndYear.split('-');
  const date = new Date(parseInt(year, 10), 0, (1 + parseInt(week, 10) - 1) * 7);
  const firstDay = startOfISOWeek(date).toLocaleDateString(i18n.locale.value);
  const lastDay = endOfISOWeek(date).toLocaleDateString(i18n.locale.value);
  return `${firstDay} - ${lastDay}`;
}
