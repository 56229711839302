import { formatText } from '@/jsx/components/util';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { h } from '../jsxFactory';

const getStyle = (
  position: 'top' | 'top-left' | 'bottom',
  triggerElement: HTMLElement,
  tooltipElement: HTMLElement,
) => {
  const OVERLAY_MARGIN = 4;

  const triggerRect = triggerElement.getBoundingClientRect();
  const tooltipRect = tooltipElement.getBoundingClientRect();
  let top: number;
  let bottom: number | undefined;
  let left: number | undefined;

  const overlayWouldOverlapBottomEdge = (topReference: number) =>
    topReference + tooltipRect.height > window.innerHeight; // tooltip does not start below but right, check to adapt this formula with a prop accordingly
  switch (position) {
    case 'top':
      left = triggerRect.left + (triggerRect.width - tooltipRect.width) / 2;
      top = triggerRect.top - tooltipRect.height - 4;
      break;
    case 'top-left':
      left = triggerRect.left;
      top = triggerRect.top - tooltipRect.height - 4;
      break;
    case 'bottom':
      left = triggerRect.left + (triggerRect.width - tooltipRect.width) / 2;
      if (overlayWouldOverlapBottomEdge(triggerRect.bottom + 4)) {
        top = triggerRect.top - tooltipRect.height - 4;
        bottom = window.innerHeight - triggerRect.top + OVERLAY_MARGIN;
      } else {
        top = triggerRect.bottom + 4;
      }
      top = triggerRect.bottom + 4;
      break;
    default: // top
      left = triggerRect.left + (triggerRect.width - tooltipRect.width) / 2;
      top = triggerRect.top - tooltipRect.height - 4;
  }
  top = Math.max(OVERLAY_MARGIN, top);

  return {
    top: `${top}px`,
    bottom: bottom ? `${bottom}px` : undefined,
    left: `${left}px`,
  };
};

type TooltipProps = {
  $children?: any; // workaround so ts does not complain
  id?: string;
  position: 'top-left' | 'bottom';
  disabled: boolean;
  text: string;
  shortcut?: string;
  appendix?: string;
};

export default function Tooltip(
  { id, position = 'bottom', disabled = false, text, shortcut = '', appendix = '' }: TooltipProps,
  children: (HTMLElement | string)[],
) {
  const tooltip = (
    <div class="jsx-tooltip tw-z-[10000] tw-fixed tw-overflow-visible tw-transition-opacity tw-duration-200 tw-ease-in-out tw-opacity-0">
      <div
        class="tw-flex tw-flex-col tw-gap-1 tw-max-w-sm tw-w-max tw-py-2 tw-px-3 tw-rounded-xs
      tw-shadow-lg tw-ds-text-xs tw-bg-grey-800 tw-text-white"
      >
        <p>
          {formatText(text)}
          {shortcut ? (
            <span class="tw-inline-block tw-rounded-2xs tw-ml-4 tw-px-2.5 tw-bg-[rgba(255,255,255,0.16)]">
              {shortcut}
            </span>
          ) : (
            ''
          )}
          {appendix ? <span class="tw-italic">{appendix}</span> : ''}
        </p>
      </div>
    </div>
  );

  let hoverTimeout: number | undefined;

  const onMouseEnter = (event: MouseEvent) => {
    clearTimeout(hoverTimeout);
    document.body.appendChild(tooltip);
    const { top, left, bottom } = getStyle(position, event.target as HTMLElement, tooltip);
    tooltip.style.top = top;
    tooltip.style.left = left;
    if (bottom) {
      tooltip.style.bottom = bottom;
    }
    requestAnimationFrame(() => {
      tooltip.classList.replace('tw-opacity-0', 'tw-opacity-100');
    });
  };
  const onMouseLeave = () => {
    tooltip.classList.replace('tw-opacity-100', 'tw-opacity-0');
    hoverTimeout = window.setTimeout(() => {
      if (tooltip.parentNode === document.body) {
        document.body.removeChild(tooltip);
      }
    }, 200);
  };

  return (
    <div
      id={id}
      onMouseenter={onMouseEnter}
      onMouseleave={onMouseLeave}
      class={`tw-flex tw-justify-center tw-items-center ${!disabled ? 'tw-cursor-pointer' : ''}`}
    >
      {...children}
    </div>
  );
}
