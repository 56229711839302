import { Ref, ref } from 'vue';

import { buildAddress } from '@/helpers/address';
import { Address } from '@/interfaces/repositories/utility';
import { LocationService } from '@/interfaces/services';
import {
  AutocompleteService,
  AutocompleteSessionToken,
  GeocoderService,
  LocationPrediction,
  LocationServiceQueryResult,
  PlacesService,
} from '@/interfaces/services/location';
import { GoogleMaps } from '@/plugins/googleMaps';

export class GoogleMapsLocationService implements LocationService {
  private sessionToken = ref(null) as Ref<AutocompleteSessionToken | null>;

  private autocompleteService = ref(null) as Ref<AutocompleteService | null>;

  private placesService = ref(null) as Ref<PlacesService | null>;

  private geocoderService = ref(null) as Ref<GeocoderService | null>;

  public canUseLocation = ref(false);

  public constructor(private googleMaps: GoogleMaps) {
    this.initialize();
  }

  private async initialize(): Promise<void> {
    await this.googleMaps.$gmapApiPromiseLazy();

    this.canUseLocation.value = true;

    /**
     * Places service requires google map element that we don't want to have,
     *  to hack this a non-visible div element will be added and used by the service
     * */
    const fakeMap = document.createElement('div');

    this.autocompleteService.value = new google.maps.places.AutocompleteService();
    this.sessionToken.value = new google.maps.places.AutocompleteSessionToken();
    this.placesService.value = new google.maps.places.PlacesService(fakeMap);
    this.geocoderService.value = new google.maps.Geocoder();
  }

  public usePredictions(): LocationServiceQueryResult<string, LocationPrediction[]> {
    const predictions = ref([]) as Ref<LocationPrediction[]>;
    const loading = ref(false);

    return {
      result: predictions,
      refetch: (searchTerm) => {
        loading.value = true;
        this.getPredictions(searchTerm, predictions, loading);
      },
      loading,
    };
  }

  public usePlace(): LocationServiceQueryResult<string, Address> {
    const address = ref(buildAddress());
    const loading = ref(false);

    return {
      result: address,
      refetch: (id) => {
        loading.value = true;
        this.getPlace(id, address, loading);
      },
      loading,
    };
  }

  public useState(): LocationServiceQueryResult<string, string> {
    const state = ref('');
    const loading = ref(false);

    return {
      result: state,
      refetch: (address) => {
        loading.value = true;
        this.getState(address, state, loading);
      },
      loading,
    };
  }

  public useGeoAddress(): LocationServiceQueryResult<string, google.maps.LatLng | null> {
    const geoAddress = ref(null) as Ref<google.maps.LatLng | null>;
    const loading = ref(false);

    return {
      result: geoAddress,
      refetch: (address) => {
        loading.value = true;
        this.getGeoAddress(address, geoAddress, loading);
      },
      loading,
    };
  }

  private getPredictions(
    searchTerm: string,
    result: Ref<LocationPrediction[]>,
    loading: Ref<boolean>,
  ): void {
    if (!this.autocompleteService.value) return;

    // fetch place predictions depending on search term
    this.autocompleteService.value.getPlacePredictions(
      {
        input: searchTerm,
        sessionToken: this.sessionToken.value ?? undefined,
        types: [],
      },
      (_predictions, status) => {
        loading.value = false;
        if (status !== google.maps.places.PlacesServiceStatus.OK) return;
        result.value = (_predictions ?? []).map((prediction) => ({
          placeId: prediction.place_id,
          description: prediction.description,
        }));
      },
    );
  }

  private getPlace(id: string, result: Ref<Address>, loading: Ref<boolean>): void {
    if (!this.placesService.value || !id) return;

    // fetch address details of google places id
    this.placesService.value.getDetails(
      {
        placeId: id,
        fields: ['address_component'],
      },
      (details, status) => {
        loading.value = false;
        if (status !== google.maps.places.PlacesServiceStatus.OK) return;

        // extract address data from return object
        const postalCode =
          details?.address_components?.find((data) => data.types.includes('postal_code')) ?? null;
        const street =
          details?.address_components?.find((data) => data.types.includes('route')) ?? null;
        const streetNumber =
          details?.address_components?.find((data) => data.types.includes('street_number')) ?? null;
        const city =
          details?.address_components?.find((data) => data.types.includes('locality')) ?? null;
        const country =
          details?.address_components?.find((data) => data.types.includes('country')) ?? null;

        // format data to required variable type
        result.value = {
          ...result.value,
          postalCode: postalCode?.long_name ?? '',
          street: (street ? `${street.long_name} ${streetNumber?.long_name ?? ''}` : '').trim(),
          city: city?.long_name ?? '',
          country: country?.short_name ?? '',
        };
      },
    );
  }

  private getState(address: string, result: Ref<string>, loading: Ref<boolean>): void {
    if (!this.placesService.value) return;

    // fetch possible places from address
    this.placesService.value.findPlaceFromQuery(
      {
        query: address,
        fields: ['place_id'],
      },
      (possiblePlaces, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          loading.value = false;
          return;
        }
        const placeId = possiblePlaces?.length ? (possiblePlaces[0].place_id ?? '') : '';
        // fetch place details
        this.placesService.value?.getDetails(
          {
            placeId,
            fields: ['address_component'],
          },
          (details, _status) => {
            loading.value = false;
            if (_status !== google.maps.places.PlacesServiceStatus.OK) return;

            const state =
              details?.address_components?.find((component) =>
                component.types.includes('administrative_area_level_1'),
              )?.long_name ?? '';

            result.value = state;
          },
        );
      },
    );
  }

  private getGeoAddress(
    address: string,
    result: Ref<google.maps.LatLng | null>,
    loading: Ref<boolean>,
  ): void {
    if (!this.geocoderService.value || !address || address === '-') return;

    this.geocoderService.value.geocode(
      {
        address,
      },
      (geocodes) => {
        loading.value = false;
        if (geocodes) result.value = geocodes.length ? geocodes[0].geometry.location : null;
      },
    );
  }
}
