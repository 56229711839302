<template>
  <v-footer class="tw-mt-2 text-caption-passive" app color="transparent">
    <p class="text-label-first tw-text-white">&copy; koppla GmbH {{ new Date().getFullYear() }}</p>

    <v-spacer />
    <v-btn
      :href="privacyLink"
      color="white"
      variant="text"
      target="_blank"
      rel="noopener noreferrer"
      size="x-small"
    >
      {{ t('footer.privacy') }}
      <v-icon size="x-small" end>open_in_new</v-icon>
    </v-btn>
    <v-btn
      :href="imprintLink"
      color="white"
      variant="text"
      target="_blank"
      rel="noopener noreferrer"
      size="x-small"
    >
      {{ t('footer.imprint') }}
      <v-icon size="x-small" end>open_in_new</v-icon>
    </v-btn>
  </v-footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Composer, useI18n } from 'vue-i18n';

import { website } from '@/utils/pages';

interface Data extends Composer {
  imprintLink: string;
  privacyLink: string;
}

export default defineComponent({
  setup(): Data {
    return {
      imprintLink: website.IMPRINT,
      privacyLink: website.PRIVACY,
      ...useI18n(),
    };
  },
});
</script>
