import { Page } from '@playwright/test';

function findDateElement(page: Page, date: Date) {
  const dateStr = date.getDate().toString();
  return page.locator(`.dp__calendar_item >> text=/^${dateStr}$/`).first();
}

export const dsComponentSelectors = {
  colorPicker: {
    defineOwnColorButton: 'color-picker-custom-define-own-color-button',
    customColorInput: 'color-picker-custom-color-input',
    submitButton: 'color-picker-submit-button',
  },
  // Unfortunately, this is still the old date picker component
  dateTimePicker: {
    datePicker: 'date-time-picker-datepicker',
    dateCell: (page: Page, date: Date) => {
      return findDateElement(page, date);
    },
    submitButton: 'date-time-picker-submit-button',
  },
  dateRangePicker: {
    datePicker: 'date-range-picker-datepicker',
    dateCell: (page: Page, date: Date) => {
      return findDateElement(page, date);
    },
    inputField: 'date-range-picker-input',
  },
  nestedSelectOption: {
    toggleExpand: 'nested-select-option-toggle-expand',
    listItem: 'nested-select-option-list-item',
  },
  snackbar: 'snackbar',
  snackbarButton: 'snackbar-button',
  select: {
    item: (id: string) => `select-item-${id}`,
  },
  sliderInput: 'slider-input',
  menuItem: 'menu-item',
};
