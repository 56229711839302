import { RouteLocationNormalized, RouteLocationRaw, RouteRecordRaw } from 'vue-router';

import { MembershipWithTenant } from '@/interfaces/repositories/memberships';
import { ProjectSubcontractorWorker } from '@/interfaces/repositories/projectSubcontractorWorker';
import { GuardMeta, useWithMembership } from '@/router/helpers/memberships';
import { getRouteForMembershipStatus } from '@/router/helpers/redirection';
import { useRouteNames } from '@/router/helpers/routeNames';

export function useLeanProjectsRoutes(meta: GuardMeta): RouteRecordRaw[] {
  return routes(meta);
}

export function useLeanProjectsGuard({
  loggingService,
  membershipRepository,
  projectSubcontractorWorkerRepository,
}: GuardMeta): (to: RouteLocationNormalized, from: RouteLocationNormalized) => Promise<any> {
  return (to): any => {
    const guard = (
      membership: MembershipWithTenant | undefined | null,
      memberships: MembershipWithTenant[],
      subcontraction: ProjectSubcontractorWorker | undefined | null,
      subcontractions: readonly ProjectSubcontractorWorker[],
    ): boolean | RouteLocationRaw => {
      const redirection = getRouteForMembershipStatus(
        to,
        membership,
        memberships,
        subcontraction,
        subcontractions,
      );

      return redirection;
    };

    return useWithMembership(
      { to },
      { loggingService, membershipRepository, projectSubcontractorWorkerRepository },
      guard,
    );
  };
}

/** Guard wrapper used for children */
export function useChildGuard(
  { loggingService, membershipRepository, projectSubcontractorWorkerRepository }: GuardMeta,
  guard?: (to: RouteLocationNormalized, from: RouteLocationNormalized) => Promise<any>,
): (to: RouteLocationNormalized, from: RouteLocationNormalized) => Promise<any> {
  return (to, _from): Promise<void> =>
    useWithMembership(
      { to },
      { loggingService, membershipRepository, projectSubcontractorWorkerRepository },
      async (
        membership: MembershipWithTenant | undefined | null,
        memberships: MembershipWithTenant[],
        subcontraction: ProjectSubcontractorWorker | undefined | null,
        subcontractions: readonly ProjectSubcontractorWorker[],
      ): Promise<void> => {
        if (
          getRouteForMembershipStatus(to, membership, memberships, subcontraction, subcontractions)
        ) {
          return;
        }

        if (guard) guard(to, _from);
      },
    );
}

const { Routes } = useRouteNames();

const routes = (meta: GuardMeta): RouteRecordRaw[] => {
  const leanProjectRoutes: RouteRecordRaw[] = [
    {
      path: '',
      name: Routes.Lean.Dashboard,
      component: () => import('@/features/dashboard/components/Dashboard.vue'),
    },
    {
      path: 'participants',
      name: Routes.Lean.Participants,
      component: () => import('@/views/leanProjects/dashboard/Participants.vue'),
      children: [
        {
          path: 'subcontractors',
          name: Routes.Lean.Subcontractors,
          meta: {
            group: Routes.Lean.Participants,
          },
          component: () => import('@/views/leanProjects/dashboard/participants/Subcontractors.vue'),
          beforeEnter: useChildGuard(meta),
        },
        {
          path: 'memberships',
          name: Routes.Lean.Memberships,
          meta: {
            group: Routes.Lean.Participants,
          },
          component: () => import('@/views/leanProjects/dashboard/participants/Memberships.vue'),
          beforeEnter: useChildGuard(meta),
        },
      ],
      redirect: { name: Routes.Lean.Memberships },
    },
    {
      path: 'schedule/:objectId?', // e.g., object is order, milestone, pause
      name: Routes.Lean.Schedule,
      component: () => import('@/views/leanProjects/dashboard/Schedule.vue'),
      beforeEnter: useChildGuard(meta),
      props: (route) => ({
        notification: route.query.notification,
      }),
    },
    {
      path: 'tickets/:ticketId?',
      name: Routes.Lean.Tickets,
      component: () => import('@/features/tickets/pages/TicketView.vue'),
      beforeEnter: useChildGuard(meta),
    },
    {
      path: 'photos/:photoId?',
      name: Routes.Lean.PhotoDocumentation,
      component: () => import('@/features/photoDocumentation/pages/PhotoDocumentation.vue'),
      beforeEnter: useChildGuard(meta),
    },
  ];

  return leanProjectRoutes;
};
