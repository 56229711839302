import {
  MembershipsOfUserQuery,
  MembershipsOfUserQueryVariables,
  OwnUserQuery,
  OwnUserQueryVariables,
  UserProjectMembershipQuery,
  UserProjectMembershipQueryVariables,
} from '@/graphql/__generated__/graphql';
import OWN_MEMBERSHIPS from '@/graphql/membership/AllOwn.gql';
import USER_PROJECT_MEMBERSHIP from '@/graphql/projectMemberships/Own.gql';
import OWN_USER from '@/graphql/users/OwnUser.gql';
import { useApolloClient } from '@/plugins/apollo';
import { flattenNodeConnection } from '@/repositories/utils/fetchAll';

export class UserStoreRepository {
  public async fetchOwn() {
    const client = useApolloClient();

    return client
      .query<OwnUserQuery, OwnUserQueryVariables>({
        query: OWN_USER,
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        if (!result.data.ownUser) return null;
        return result.data.ownUser;
      });
  }

  public async fetchIsGeneralContractor(projectTenantId: string, projectId: string) {
    const client = useApolloClient();

    const projectMembership = await client
      .query<UserProjectMembershipQuery, UserProjectMembershipQueryVariables>({
        query: USER_PROJECT_MEMBERSHIP,
        variables: {
          projectId,
        },
      })
      .then((result) => result.data.userProjectMembership);

    if (projectMembership) return true;

    const memberships = await client
      .query<MembershipsOfUserQuery, MembershipsOfUserQueryVariables>({
        query: OWN_MEMBERSHIPS,
      })
      .then((result) => flattenNodeConnection(result.data.memberships));

    return memberships.some((membership) => membership.tenant.id === projectTenantId);
  }
}
