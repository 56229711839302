import { useApolloClient } from '@/plugins/apollo';

import { sectionsQuery } from '../sectionGql';
import { WbsSection } from '../types';

export async function queryProjectSections(projectId: string) {
  const client = useApolloClient();

  const result = await client.query({
    query: sectionsQuery,
    variables: {
      project: projectId,
    },
    fetchPolicy: 'no-cache',
  });

  const fragments: WbsSection[] =
    result.data.wbsSections?.map((v) => ({
      id: v.id,
      name: v.name,
      position: v.position,
      parentId: v.parent?.id ?? null,
    })) ?? [];
  return fragments;
}
