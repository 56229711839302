import { CalendarEntity, InteractiveEntityChanges } from '@/common/types';
import { UnwrapEdges } from '@/common/types/graphql';
import { OperationInputType } from '@/features/realTimeCollaboration/types';
import { CalendarFragment } from '@/graphql/__generated__/graphql';
import { to24HourTimeString } from '@/helpers/utils/dates';
import { deleteUndefinedValues } from '@/helpers/utils/objects';
import { MINUTES_PER_DAY } from '@/helpers/utils/timeConstants';
import { flattenNodeConnection } from '@/repositories/utils/fetchAll';

import { Weekday, WorkingTime } from './calendarTypes';

export function convertCalendarFragmentsToCalendarEntities(
  fragments: CalendarFragment[],
): CalendarEntity[] {
  return fragments.map((fragment) => {
    return toCalendarEntity({
      ...fragment,
      workingDays: flattenNodeConnection(fragment.workingDays).map((workingDay) => ({
        ...workingDay,
        workingTimes: flattenNodeConnection(workingDay.workingTimes),
      })),
      exceptions: flattenNodeConnection(fragment.exceptions).map((exception) => ({
        ...exception,
        workingTimes: flattenNodeConnection(exception.workingTimes),
      })),
    });
  });
}

export function convertCalendarCreateOperationInputToChanges(
  input: OperationInputType<'CreateCalendars'>,
  state: Map<string, CalendarEntity>,
): InteractiveEntityChanges {
  const { currentDefaultCalendar, unassignDefaultCalendar } = needsDefaultCalendarUnassignment(
    input,
    state,
  );

  return {
    add: {
      calendars: input.map(toCalendarEntity),
    },
    update: unassignDefaultCalendar
      ? { calendars: [{ id: currentDefaultCalendar.id, isDefault: false }] }
      : {},
  };
}

export function convertCalendarUpdateOperationInputToChanges(
  input: OperationInputType<'UpdateCalendars'>,
  state: Map<string, CalendarEntity>,
): InteractiveEntityChanges {
  const { currentDefaultCalendar, unassignDefaultCalendar } = needsDefaultCalendarUnassignment(
    input,
    state,
  );

  return {
    update: {
      calendars: [
        ...input.map((calendar) => ({
          ...deleteUndefinedValues({
            isDefault: calendar.isDefault ?? undefined,
            name: calendar.name ?? undefined,
            workingDays: calendar.workingDays ?? undefined,
            exceptions: calendar.exceptions
              ? calendar.exceptions.map((e) => ({
                  ...e,
                  startAt: new Date(e.startAt),
                  finishAt: new Date(e.finishAt),
                  workingTimes: e.workingTimes,
                }))
              : undefined,
          }),
          id: calendar.id,
        })),
        ...(unassignDefaultCalendar ? [{ id: currentDefaultCalendar.id, isDefault: false }] : []),
      ],
    },
  };
}

function needsDefaultCalendarUnassignment(
  input: OperationInputType<'UpdateCalendars'> | OperationInputType<'CreateCalendars'>,
  state: Map<string, CalendarEntity>,
) {
  const currentDefaultCalendar = findDefaultCalendar(state);
  const unassignDefaultCalendar = input.some(
    (calendar) => calendar.isDefault === true && calendar.id !== currentDefaultCalendar.id,
  );

  return { currentDefaultCalendar, unassignDefaultCalendar };
}

export function toCalendarEntity(
  calendar: UnwrapEdges<CalendarFragment> | OperationInputType<'CreateCalendars'>[0],
): CalendarEntity {
  return {
    id: calendar.id,
    isDefault: calendar.isDefault ?? false,
    name: calendar.name,
    minutesPerDay: 'minutesPerDay' in calendar ? calendar.minutesPerDay : MINUTES_PER_DAY,
    workingDays: calendar.workingDays.map((workingDay) => ({
      weekday: (workingDay.weekday % 7) as Weekday,
      workingTimes: workingDay.workingTimes.map(filterSeconds),
    })),
    exceptions: (calendar.exceptions ?? []).map((exception) => ({
      ...exception,
      startAt: new SchedulingDate(exception.startAt),
      finishAt: new SchedulingDate(exception.finishAt),
      workingTimes: exception.workingTimes.map(filterSeconds),
    })),
  };
}

export function parseEntityExceptionIntoOperationInputException(
  exception: CalendarEntity['exceptions'][0],
): NonNullable<OperationInputType<'CreateCalendars'>[0]['exceptions']>[0] {
  return {
    startAt: exception.startAt.toISOString(),
    finishAt: exception.finishAt.toISOString(),
    isWorkingDay: exception.isWorkingDay,
    workingTimes: exception.workingTimes.map(addSeconds),
  };
}
export function parseEntityWorkingDayIntoOperationInputWorkingDay(
  workingDay: CalendarEntity['workingDays'][0],
): NonNullable<OperationInputType<'CreateCalendars'>[0]['workingDays']>[0] {
  return {
    ...workingDay,
    workingTimes: workingDay.workingTimes.map(addSeconds),
  };
}

export function sortCalendars(calendarsToSort: CalendarEntity[]): CalendarEntity[] {
  return calendarsToSort.slice().sort((a, b) => {
    if (a.isDefault) return -1;
    if (b.isDefault) return 1;

    return a.name.localeCompare(b.name);
  });
}

export function addSeconds(workingTime: WorkingTime): WorkingTime {
  return {
    // add seconds and timezone appendix required for backend
    from: `${to24HourTimeString(workingTime.from)}:00Z`,
    to: `${to24HourTimeString(workingTime.to)}:00Z`,
  };
}

function filterSeconds(workingTime: WorkingTime): WorkingTime {
  function removeSeconds(time: string): string {
    const partials = time.split(':');
    return `${partials[0]}:${partials[1]}`;
  }
  return {
    // filter out unnecessary seconds and timezone appendix
    from: removeSeconds(workingTime.from?.replace('Z', '')),
    to: removeSeconds(workingTime.to?.replace('Z', '')),
  };
}

export function findDefaultCalendar(calendars: Map<string, CalendarEntity>): CalendarEntity {
  const defaultCalendar = Array.from(calendars.values()).find((calendar) => calendar.isDefault);
  if (!defaultCalendar) {
    throw new Error('Project has no default calendar');
  }
  return defaultCalendar;
}
