<template>
  <div :class="{ 'ds-notification-shadow': shadow }">
    <div
      class="ds-notification"
      :class="{
        'tw-items-center': !$slots['supporting-text'],
        'ds-notification-shadow-offset': shadow,
        'tw-w-full': full,
      }"
      role="alert"
      @click.stop
    >
      <div v-if="$slots.icon || icon" class="ds-notification-icon">
        <slot name="icon">
          <DsIcon :size="20" theme="halo-light" :color="type" outlined>
            {{ icon }}
          </DsIcon>
        </slot>
      </div>
      <section class="ds-notification-body">
        <h2 v-if="$slots.text" class="tw-ds-text-sm--semibold tw-text-grey-900">
          <slot name="text" />
        </h2>
        <p
          v-if="$slots['supporting-text']"
          class="tw-mt-1 tw-ds-text-sm tw-text-grey-600 tw-whitespace-pre-line"
        >
          <slot name="supporting-text" />
        </p>
        <footer v-if="$slots.actions" class="ds-notification-actions">
          <slot name="actions" />
        </footer>
      </section>
      <CloseButton
        v-if="displayCloseButton"
        class="ds-notification-close-button"
        color="grey"
        size="sm"
        @click="emit('close-request')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  icon: {
    type: String as PropType<string>,
    default: '',
  },
  type: {
    type: String as PropType<'blue' | 'success' | 'warning' | 'attention'>,
    default: 'success',
  },
  displayCloseButton: {
    type: Boolean,
    default: true,
  },
  shadow: {
    type: Boolean,
    default: true,
  },
  full: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  (ev: 'close-request'): void;
}>();
</script>

<style scoped>
.ds-notification {
  @apply tw-flex tw-items-start tw-gap-4 tw-w-[400px];
  @apply tw-rounded-sm tw-border tw-border-solid tw-border-grey-100 tw-p-4 tw-bg-white;
}

.ds-notification-icon {
  @apply tw-flex tw-items-center -tw-mt-1 -tw-mb-1 -tw-ml-2 -tw-mr-2;
}

.ds-notification-body {
  @apply tw-flex tw-flex-col tw-grow;
}

.ds-notification-actions {
  @apply tw-flex tw-gap-3 tw-mt-2.5;
}

.ds-notification-close-button {
  @apply tw-flex-none -tw-mt-1 -tw-mb-1 -tw-mr-2.5;
}
.ds-notification-shadow {
  background: radial-gradient(
    ellipse at top right,
    rgba(0, 0, 0, 0.24) 5%,
    rgba(255, 255, 255, 0) 70%
  );
}
.ds-notification-shadow-offset {
  @apply tw-shadow-lg;
  @apply tw-mr-6 tw-mt-5;
}
</style>
