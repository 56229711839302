import { isSupportOrTechSupportUserId } from '@/features/authentication/utils/sanitization';
import { TenantFragment, UserFragment } from '@/graphql/__generated__/graphql';

import { LiveUser } from '../../types';

/**
 * Generates a hash value for a given user ID.
 *
 * This function uses a simple hashing algorithm to convert a user ID into a number.
 * It iterates over each character in the user ID, converts it to a character code,
 * and then updates the hash value by multiplying the current hash by 31 and adding the character code.
 * It also ensures that the hash stays within the range of a 32-bit integer.
 */
export function getUserIdHash(userId: string): number {
  let hash = 0;
  for (let i = 0; i < userId.length; i++) {
    const char = userId.charCodeAt(i);
    hash = Math.imul(hash, 31) + char;
    hash -= Math.floor(hash / 2147483648) * 2147483648; // Keep it within 32-bit integer range
  }
  return hash;
}

export function getUserInitials(user: LiveUser) {
  return `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
}

export function getUserColor(user: { id: string } | undefined): string {
  const usersColors = [
    '#287B8E',
    '#32747E',
    '#394185',
    '#3A7E3D',
    '#5C33A1',
    '#718521',
    '#923277',
    '#AA601B',
    '#AA7309',
    '#B23F26',
  ];

  const hash = getUserIdHash(user?.id || '');
  const colorIndex = Math.abs(hash) % usersColors.length;
  return usersColors[colorIndex];
}

export const MAX_DISPLAYED_ICONS = 5;

/**
 * Filters out users with specific IDs.
 */
function filterSupportUsers(users: LiveUser[]): LiveUser[] {
  return users.filter((u) => !isSupportOrTechSupportUserId(u.id));
}

/**
 * Sorts an array of users based on their `joinedAt` property in descending order.
 */
function sortByJoinedAt(users: LiveUser[]): LiveUser[] {
  return users.sort((u1, u2) => new Date(u2.joinedAt).getTime() - new Date(u1.joinedAt).getTime());
}

export function filterAndSortLiveUsers(users: LiveUser[] = []): LiveUser[] {
  const filteredUsers = filterSupportUsers(users);
  const sortedUsers = sortByJoinedAt(filteredUsers);
  return sortedUsers;
}

export function addOrUpdateCurrentUserToLiveUsers(
  users: LiveUser[] = [],
  currentUser: UserFragment,
  currentTenant: TenantFragment,
): LiveUser[] {
  return [
    {
      id: currentUser.id ?? '',
      firstName: currentUser.firstName ?? '',
      lastName: currentUser.lastName ?? '',
      thumbnailUrl: currentUser.profilePicture?.imageThumbnail?.url ?? '',
      tenant: currentTenant,
      tenantId: 0,
      tenantName: currentTenant.name,
      joinedAt: new Date().toISOString(),
    },
    ...users.filter((user) => user.id !== currentUser.id),
  ];
}
