<template>
  <button type="button" class="ds-icon-button" :class="`--${size} --${color} --${theme}`">
    <DsSvgIcon :icon="icon" :size="iconSize" />
  </button>
</template>

<script setup lang="ts">
import { PropType } from 'vue';

import { SvgIconType } from '@/assets/generated/svgIcons/_types';

const props = defineProps({
  icon: {
    type: String as PropType<SvgIconType>,
    required: true,
    default: null,
  },
  size: {
    type: String as PropType<'sm' | 'md' | 'lg' | 'xl'>,
    default: 'md',
  },
  color: {
    type: String as PropType<'primary' | 'grey'>,
    default: 'primary',
  },
  theme: {
    type: String as PropType<'light' | 'dark'>,
    default: 'light',
  },
});

const iconSize = computed(() => {
  const iconSizeMap: Record<string, 'lg' | 'xxl'> = {
    sm: 'lg',
    md: 'lg',
    lg: 'xxl',
    xl: 'xxl',
  };
  return iconSizeMap[props.size];
});
</script>
<style>
.ds-icon-button {
  @apply tw-flex tw-items-center tw-justify-center tw-rounded-xs;
}
.ds-icon-button.--sm {
  @apply tw-w-9 tw-h-9;
}
.ds-icon-button.--md {
  @apply tw-w-10 tw-h-10;
}
.ds-icon-button.--lg {
  @apply tw-w-11 tw-h-11;
}
.ds-icon-button.--xl {
  @apply tw-w-12 tw-h-12;
}

.ds-icon-button.--primary.--light {
  @apply tw-bg-grey-25 tw-text-grey;
  @apply hover:tw-bg-grey-100;
  @apply active:tw-bg-grey-50;
  @apply focus-visible:tw-bg-grey-25 focus-visible:tw-outline-none;
}
.ds-icon-button.--grey.--light {
  @apply tw-bg-transparent tw-text-grey-500;
  @apply hover:tw-bg-grey-50;
  @apply active:tw-bg-grey-25;
  @apply focus-visible:tw-bg-grey-50 focus-visible:tw-outline-none;
}
.ds-icon-button.--primary.--dark {
  @apply tw-bg-[rgba(255,255,255,0.08)] tw-text-white;
  @apply hover:tw-bg-[rgba(255,255,255,0.16)];
  @apply active:tw-bg-[rgba(255,255,255,0.08)];
  @apply focus-visible:tw-bg-[rgba(255,255,255,0.16)] focus-visible:tw-outline-none;
}

.ds-icon-button.--grey.--dark {
  @apply tw-bg-transparent tw-text-white;
  @apply hover:tw-bg-[rgba(255,255,255,0.12)];
  @apply active:tw-bg-[rgba(255,255,255,0.08)];
  @apply focus-visible:tw-bg-[rgba(255,255,255,0.12)] focus-visible:tw-outline-none;
}
</style>
