import { defineStore } from 'pinia';

import {
  CalendarEntity,
  CreateOrdersInput,
  Entity,
  InteractiveEntityChanges,
  OrderDependencyEntity,
  OrderEntity,
  OrderStatusEntity,
  PartialEntity,
  PauseEntity,
  UpdateOrdersInput,
  WrapRef,
} from '@/common/types';
import { useRTCController } from '@/features/realTimeCollaboration';
import {
  OperationInputType,
  ProjectChangeEventContext,
} from '@/features/realTimeCollaboration/types';
import { pushLocalChangeEventAndCommit } from '@/features/realTimeCollaboration/utils';
import {
  OrderDetailsFragment,
  OrderDocumentFragment,
  OrderStatusReportFragment,
  OrderTaskFragment,
  UpdateOrderTaskStatusMutation,
  UpdateOrderTaskStatusMutationVariables,
} from '@/graphql/__generated__/graphql';
import { getFileExtension, isDocumentFragment } from '@/helpers/files/config';
import { getStatusReportOfStatus, StatusReport } from '@/helpers/orders/status';
import { sortObjectsByKey } from '@/helpers/utils/arrays';
import { deleteUndefinedValues } from '@/helpers/utils/objects';
import { useApolloClient } from '@/plugins/apollo';
import { AttachmentCache } from '@/repositories/attachmentCache';
import { createConnection, NodeName } from '@/repositories/utils/cache';
import { flattenNodeConnection } from '@/repositories/utils/fetchAll';
import { getBulkApiClient } from '@/services/bulkApiClient';

import { useOrderDependencyStoreV2 } from '../orderDependencies';
import { useLocalOrderSchedulingEngine } from '../schedule';
import { UndoRedoCommit } from '../undoRedo/types';
import {
  createUndoRedoCopyCommit,
  createUndoRedoCreateCommit,
  createUndoRedoDeleteCommit,
  createUndoRedoRestoreCommit,
  createUndoRedoRevertCopyCommit,
  createUndoRedoUpdateCommit,
} from './orderCommits';
import {
  createProjectChangeCopyEvent,
  createProjectChangeCreateEvent,
  createProjectChangeCreateOrderStatusReportsEvent,
  createProjectChangeDeleteEvent,
  createProjectChangeRestoreEvent,
  createProjectChangeUpdateEvent,
} from './orderEvents';
import {
  orderDetailsQuery,
  orderPolledDetailsQuery,
  orderTasksQuery,
  orderTaskStatusMutation,
} from './orderGql';
import { convertOrderCopyOperationInputToEntities } from './orderUtils';
import {
  CopyOrdersVariables,
  OrderStoreV2,
  RemoveCopiedOrdersVariables,
  RestoreCopiedOrdersVariables,
} from './types';

export const useOrderStoreV2 = defineStore(
  'order-store-v2',
  (): WrapRef<OrderStoreV2, 'orders' | 'orderDetails' | 'fetchAllPromise'> => {
    const orders = ref(new Map<string, OrderEntity>());
    const deletedOrders = ref(new Map<string, { order: OrderEntity; dependencies: string[] }>());
    const orderDetails = ref(new Map<string, OrderDetailsFragment>());

    const create = async (
      vars: CreateOrdersInput,
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const newVars = vars.map((o) => injectWorkingTimeDuration(o));
      const event = createProjectChangeCreateEvent(newVars, context);
      const commit = createUndoRedoCreateCommit(newVars);
      return pushLocalChangeEventAndCommit(event, commit, createCommit, {
        dependencies: useOrderDependencyStoreV2().copyState(),
      });
    };

    const update = async (
      vars: UpdateOrdersInput,
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const newVars = vars.map((o) => injectWorkingTimeDuration(o));
      const event = createProjectChangeUpdateEvent(newVars, context);
      const commit = createUndoRedoUpdateCommit(newVars, orders.value);
      return pushLocalChangeEventAndCommit(event, commit, createCommit, {
        dependencies: useOrderDependencyStoreV2().copyState(),
      });
    };

    const restore = async (
      vars: OrderEntity[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const restoredAdjacentDependencies = vars.flatMap((order) => {
        const deletedDependencies = deletedOrders.value.get(order.id)?.dependencies ?? [];
        return deletedDependencies
          .map((dependencyId) => useOrderDependencyStoreV2().dependencies.get(dependencyId))
          .filter(Boolean) as OrderDependencyEntity[];
      });

      const event = createProjectChangeRestoreEvent(
        vars,
        undefined,
        context,
        restoredAdjacentDependencies,
      );
      const commit = createUndoRedoRestoreCommit(vars);
      return pushLocalChangeEventAndCommit(event, commit, createCommit, {
        dependencies: useOrderDependencyStoreV2().copyState(),
      });
    };

    const remove = async (
      vars: OrderEntity[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeDeleteEvent(vars, undefined, context);
      const commit = createUndoRedoDeleteCommit(vars);
      return pushLocalChangeEventAndCommit(event, commit, createCommit, {
        dependencies: useOrderDependencyStoreV2().copyState(),
      });
    };

    const copy = async (
      vars: CopyOrdersVariables,
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeCopyEvent(vars, context);
      const { orders: copiedOrders, dependencies: copiedDependencies } =
        convertOrderCopyOperationInputToEntities(vars);

      const commit = createUndoRedoCopyCommit(copiedOrders, copiedDependencies);
      return pushLocalChangeEventAndCommit(event, commit, createCommit);
    };

    const restoreCopiedOrders = async (
      vars: RestoreCopiedOrdersVariables,
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeRestoreEvent(vars.orders, vars.dependencies, context);
      const commit = createUndoRedoCopyCommit(vars.orders, vars.dependencies);
      return pushLocalChangeEventAndCommit(event, commit, createCommit);
    };

    const removeCopiedOrders = async (
      vars: RemoveCopiedOrdersVariables,
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeDeleteEvent(vars.orders, vars.dependencies, context);
      const commit = createUndoRedoRevertCopyCommit(vars.orders, vars.dependencies);
      return pushLocalChangeEventAndCommit(event, commit, createCommit);
    };

    const injectWorkingTimeDuration = <T extends PartialEntity<OrderEntity>>(
      order: T,
      updatedCalendars?: PartialEntity<CalendarEntity>[],
      updatedPauses?: PartialEntity<PauseEntity>[],
    ): T => {
      if (order.duration) return order;
      if (!order.finishAt && !order.startAt) return order;

      const originalOrder = orders.value.get(order.id);
      if (!originalOrder) return order;
      const calendarId = order.calendar?.id ?? originalOrder.calendar?.id;

      const localOrderSchedulingEngine = useLocalOrderSchedulingEngine();
      const duration = localOrderSchedulingEngine.computeWorkingTimeBetween(
        order.finishAt ?? originalOrder.finishAt,
        order.startAt ?? originalOrder.startAt,
        calendarId,
        updatedCalendars?.map((c) => deleteUndefinedValues(c) as PartialEntity<CalendarEntity>),
        updatedPauses?.map((p) => deleteUndefinedValues(p) as PartialEntity<CalendarEntity>),
      );

      return { ...order, duration };
    };

    const createOrderStatusReports = async (
      vars: OperationInputType<'CreateOrderStatusReports'>,
    ) => {
      const sanitizedVars = vars.map((report) => {
        return {
          ...report,
          progress: [StatusReport.DONE, StatusReport.REPORTED_DONE].includes(
            report.status as StatusReport,
          )
            ? 100
            : report.progress,
        };
      });
      const event = createProjectChangeCreateOrderStatusReportsEvent(sanitizedVars);

      return useRTCController().pushLocalProjectChangeEvent(event);
    };

    const fetchAllPromise: Ref<Promise<OrderEntity[]> | null> = ref(null);

    const fetchAll = async (projectId: string): Promise<OrderEntity[]> => {
      const client = getBulkApiClient();

      fetchAllPromise.value = client.getOrders(projectId).then((o) => {
        const newOrders = o.map<OrderEntity>((order) => {
          return {
            __typename: NodeName.ORDER,
            ...order,
            startAt: new SchedulingDate(order.startAt),
            finishAt: new SchedulingDate(order.finishAt),
            finishedAt:
              order.finishedAt === null ? order.finishedAt : new SchedulingDate(order.finishedAt),
            status: getStatusReportOfStatus(order.status),
            progress: order.progress ?? 0,
            wbsSection: {
              ...order.wbsSection!,
              __typename: NodeName.SECTION,
            },
            calendar: { ...order.calendar, __typename: NodeName.CALENDAR },
            tenantTradeVariation: {
              ...order.tenantTradeVariation!,
              __typename: NodeName.TRADE_VARIATION,
            },
            subcontractor: order.subcontractor
              ? { ...order.subcontractor, __typename: NodeName.TENANT }
              : null,
            tradeSequenceActivity: order.tradeSequenceActivity
              ? { ...order.tradeSequenceActivity, __typename: NodeName.TRADE_SEQUENCE_ACTIVITY }
              : null,
          };
        });

        orders.value = new Map(newOrders.map((order) => [order.id, order]));
        return newOrders;
      });

      return fetchAllPromise.value;
    };

    const reset = () => {
      orders.value = new Map();
      fetchAllPromise.value = null;
    };

    const setState = (state?: Map<string, OrderEntity>) => {
      if (!state) {
        return;
      }
      state.forEach((order) => {
        if (deletedOrders.value.has(order.id)) {
          const previousResult = deletedOrders.value.get(order.id)!;
          deletedOrders.value.set(order.id, { ...previousResult, order });
        }
      });
      orders.value.forEach((order) => {
        const dependencyStore = useOrderDependencyStoreV2();
        if (!state.has(order.id)) {
          const relatedDependencies = dependencyStore.getDependenciesPartiallyRelatedToEntityIds(
            new Set([order.id]),
          );
          deletedOrders.value.set(order.id, {
            order,
            dependencies: relatedDependencies.map((d) => d.id),
          });
          relatedDependencies.forEach((dependency) => {
            dependencyStore.setSoftDeletedEntity(dependency);
          });
        }
      });
      orders.value = new Map(state);
    };

    const setOrderStatusState = (state: Map<string, OrderStatusEntity> | undefined) => {
      if (!state) {
        return;
      }
      state.forEach((orderStatus) => {
        const orderDetailsForStatus = orderDetails.value.get(orderStatus.orderId);
        if (orderDetailsForStatus) {
          orderDetails.value.set(orderStatus.orderId, {
            ...orderDetailsForStatus,
            latestStatusReport: orderStatus,
          });
        }
      });
    };

    const applyChanges = (changes: {
      add?: OrderEntity[];
      update?: PartialEntity<OrderEntity>[];
      delete?: Entity[];
      addStatus?: OrderStatusEntity[];
    }): void => {
      changes.add?.forEach((order) => {
        orders.value.set(order.id, order);
      });
      changes.update?.forEach((order) => {
        orders.value.set(order.id, {
          ...orders.value.get(order.id)!,
          ...order,
        });
        if (deletedOrders.value.has(order.id)) {
          const previousResult = deletedOrders.value.get(order.id)!;
          deletedOrders.value.set(order.id, {
            ...previousResult,
            order: { ...previousResult.order, ...order },
          });
        }
      });
      changes.delete?.forEach((order) => {
        const existingOrder = orders.value.get(order.id);
        const dependencyStore = useOrderDependencyStoreV2();
        if (existingOrder) {
          const relatedDependencies =
            useOrderDependencyStoreV2().getDependenciesPartiallyRelatedToEntityIds(
              new Set([order.id]),
            );
          deletedOrders.value.set(order.id, {
            order: existingOrder,
            dependencies: relatedDependencies.map((d) => d.id),
          });
          relatedDependencies.forEach((dependency) => {
            dependencyStore.setSoftDeletedEntity(dependency);
          });
          orders.value.delete(order.id);
        }
      });
      changes.addStatus?.forEach((status) => {
        orderDetails.value.set(status.orderId, {
          ...orderDetails.value.get(status.orderId)!,
          latestStatusReport: status,
        });
      });
    };

    const copyState = () => {
      return new Map(orders.value);
    };

    const getSoftDeletedEntity = (
      id: string,
    ): { order: OrderEntity; dependencies: string[] } | undefined => {
      return deletedOrders.value.get(id);
    };

    const setSoftDeletedEntity = (id: string, dependency?: string): void => {
      const existingOrder = deletedOrders.value.get(id);
      if (!existingOrder) return;
      deletedOrders.value.set(id, {
        order: existingOrder.order,
        dependencies: dependency
          ? [...existingOrder.dependencies, dependency]
          : existingOrder.dependencies,
      });
    };

    function processOrderDetails(
      entityId: string,
      order: OrderDetailsFragment | null,
      previousStatusReport?: OrderStatusReportFragment | null | undefined,
    ) {
      if (order === null) {
        return null;
      }
      const tasks = createConnection<
        OrderTaskFragment,
        'OrderTaskNodeConnection',
        'OrderTaskNodeEdge'
      >(
        sortObjectsByKey(flattenNodeConnection(order.tasks), 'sequenceNumber'),
        NodeName.ORDER_TASK,
      );

      const documentNodes = flattenNodeConnection(order.documents);

      const documentNodeIds = new Set(documentNodes.map((node) => node.id));

      const attachments = AttachmentCache.getAttachments(entityId);
      attachments.forEach((attachment) => {
        if (!isDocumentFragment(attachment)) return;
        if (documentNodeIds.has(attachment.id)) {
          AttachmentCache.removeAttachment({
            attachmentId: attachment.id,
            entityId,
          });
          return;
        }

        const document: OrderDocumentFragment = {
          __typename: 'OrderDocumentNode',
          id: attachment.id,
          name: attachment.name,
          fileExtension: getFileExtension(attachment.name) ?? attachment.fileExtension ?? '',
          fileSize: attachment.fileSize,
          createdAt: attachment.createdAt,
          file: {
            url: attachment.file.url,
            downloadUrl: attachment.file.downloadUrl,
          },
        };
        documentNodes.push(document);
      });

      const photos = {
        totalCount: order.photos.totalCount ?? 0,
      };

      const documents = {
        ...createConnection<
          OrderDocumentFragment,
          'OrderDocumentNodeConnection',
          'OrderDocumentNodeEdge'
        >(sortObjectsByKey(documentNodes, 'createdAt'), NodeName.ORDER_DOCUMENT),
        totalCount: order.documents.totalCount ?? 0,
      };

      if (previousStatusReport) {
        order.latestStatusReport = previousStatusReport;
      }

      const orderDetailsResult = {
        ...order,
        tasks,
        photos,
        documents,
      };

      orderDetails.value.set(entityId, orderDetailsResult);
      return orderDetailsResult;
    }

    const fetchDetails = async (id: string) => {
      const immediateResult = orderDetails.value.get(id) ?? null;

      const result = await useApolloClient()
        .query({
          query: orderDetailsQuery,
          variables: { id },
          fetchPolicy: 'no-cache',
        })
        .then((response) => response.data.order ?? null)
        .then((order) => processOrderDetails(id, order));

      return {
        result,
        immediateResult,
      };
    };

    const fetchTasks = async (orderIds: string[]) => {
      const result = await useApolloClient()
        .query({
          query: orderTasksQuery,
          variables: { ids: orderIds },
          fetchPolicy: 'no-cache',
        })
        .then((response) => response.data.orders ?? [])
        .then((orders) =>
          orders.map((order) =>
            sortObjectsByKey(flattenNodeConnection(order?.tasks), 'sequenceNumber'),
          ),
        );
      return result;
    };

    const pollDetails = async (id: string) => {
      const immediateResult = orderDetails.value.get(id) ?? null;

      const result = await useApolloClient()
        .query({
          query: orderPolledDetailsQuery,
          variables: { id },
          fetchPolicy: 'no-cache',
        })
        .then((response) => response.data.order ?? null)
        .then((order) => processOrderDetails(id, order, immediateResult?.latestStatusReport));

      return {
        result,
        immediateResult,
      };
    };

    const replaceTrade = (previousTradeId: string, newTradeId: string): void => {
      orders.value.forEach((order) => {
        if (order.tenantTradeVariation.id === previousTradeId) {
          orders.value.set(order.id, {
            ...order,
            tenantTradeVariation: {
              ...order.tenantTradeVariation,
              id: newTradeId,
            },
          });
        }
      });
    };

    const updateOrderTaskStatus = async (taskId: string, completedAt: Date | null) => {
      const client = useApolloClient();
      const result = client.mutate<
        UpdateOrderTaskStatusMutation,
        UpdateOrderTaskStatusMutationVariables
      >({
        mutation: orderTaskStatusMutation,
        variables: { id: taskId, completedAt },
      });

      return result;
    };

    const unassignSubcontractorIfNecessary = (
      subcontractors: Set<string> | string[],
    ): Set<string> => {
      const unassignedOrderIds = new Set<string>();
      const subcontractorsSet = new Set(subcontractors);
      orders.value.forEach((order) => {
        if (!subcontractorsSet.has(order.subcontractor?.id ?? '')) {
          orders.value.set(order.id, {
            ...order,
            subcontractor: null,
          });
          unassignedOrderIds.add(order.id);
        }
      });
      return unassignedOrderIds;
    };

    return {
      orders,
      orderDetails,
      create,
      update,
      restore,
      delete: remove,
      copy,
      restoreCopiedOrders,
      removeCopiedOrders,
      createOrderStatusReports,
      applyChanges,
      setState,
      setOrderStatusState,
      copyState,
      fetchAll,
      fetchAllPromise,
      reset,
      getSoftDeletedEntity,
      setSoftDeletedEntity,
      fetchDetails,
      pollDetails,
      replaceTrade,
      unassignSubcontractorIfNecessary,
      injectWorkingTimeDuration,
      updateOrderTaskStatus,
      fetchTasks,
    };
  },
);
