import { defineStore } from 'pinia';
import { Ref } from 'vue';

import {
  Entity,
  InteractiveEntityChanges,
  PartialEntity,
  ProjectSubcontractorEntity,
  WrapRef,
} from '@/common/types';
import { UndoRedoCommit } from '@/features/undoRedo';
import {
  CreateProjectSubcontractorMutationVariables,
  ProjectSubcontractorWorkerFragment,
  UpdateProjectSubcontractorMutationVariables,
} from '@/graphql/__generated__/graphql';
import { useApolloClient } from '@/plugins/apollo';
import { flattenNodeConnection } from '@/repositories/utils/fetchAll';

import {
  createSubcontractor,
  deleteSubcontractor,
  projectSubcontractorDetailsQuery,
  projectSubcontractorsQuery,
  updateSubcontractor,
} from './projectSubcontractorGql';
import { ProjectSubcontractorDetails, ProjectSubcontractorStore } from './types';

export const useProjectSubcontractorStore = defineStore(
  'project-subcontractor-store',
  (): WrapRef<
    ProjectSubcontractorStore,
    | 'projectSubcontractors'
    | 'fetchAllPromise'
    | 'areProjectSubcontractorsLoading'
    | 'projectSubcontractorDetails'
  > => {
    const projectSubcontractors = ref(new Map<string, ProjectSubcontractorEntity>());
    const projectSubcontractorDetails = ref(new Map<string, ProjectSubcontractorDetails>());
    const fetchAllPromise: Ref<Promise<ProjectSubcontractorEntity[]> | null> = ref(null);
    const areProjectSubcontractorsLoading = ref(true);

    const fetchAll = async (projectId: string): Promise<ProjectSubcontractorEntity[]> => {
      const client = useApolloClient();

      fetchAllPromise.value = client
        .query({
          query: projectSubcontractorsQuery,
          variables: {
            projectId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((result) => {
          const newProjectSubcontractors = flattenNodeConnection(
            result.data.projectSubcontractors,
          ).map((subcontractor) => ({
            ...subcontractor,
            tenantTradeVariationAssignments: flattenNodeConnection(
              subcontractor.tenantTradeVariationAssignments,
            ),
          }));
          projectSubcontractors.value = new Map(
            newProjectSubcontractors.map((subcontractor) => [subcontractor.id, subcontractor]),
          );
          areProjectSubcontractorsLoading.value = false;
          return newProjectSubcontractors;
        });
      return fetchAllPromise.value;
    };

    const fetchDetails = async (projectId: string): Promise<ProjectSubcontractorDetails[]> => {
      const client = useApolloClient();

      return client
        .query({
          query: projectSubcontractorDetailsQuery,
          variables: {
            projectId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((result) => {
          const newProjectSubcontractors = flattenNodeConnection(
            result.data.projectSubcontractors,
          ).map((subcontractor) => ({
            ...subcontractor,
            contacts: flattenNodeConnection(subcontractor.contacts),
          }));
          projectSubcontractorDetails.value = new Map(
            newProjectSubcontractors.map((subcontractor) => [subcontractor.id, subcontractor]),
          );
          return newProjectSubcontractors;
        });
    };

    const reset = () => {
      projectSubcontractors.value = new Map();
      fetchAllPromise.value = null;
    };

    const setState = (state?: Map<string, ProjectSubcontractorEntity>) => {
      if (!state) {
        return;
      }
      projectSubcontractors.value = new Map(state);
    };

    const setPartialState = (state: Map<string, Partial<ProjectSubcontractorEntity>>) => {
      const oldState = new Map(
        Array.from(projectSubcontractors.value.values()).map((entity) => [entity.id, entity]),
      );
      projectSubcontractors.value = new Map();
      state.forEach((value, key) => {
        projectSubcontractors.value.set(key, {
          ...(oldState.get(key) ?? {}),
          ...value,
        } as ProjectSubcontractorEntity);
      });
    };

    const applyChanges = (changes: {
      add?: ProjectSubcontractorEntity[];
      update?: PartialEntity<ProjectSubcontractorEntity>[];
      delete?: Entity[];
    }): void => {
      changes.add?.forEach((subcontractor) => {
        projectSubcontractors.value.set(subcontractor.id, subcontractor);
      });
      changes.update?.forEach((subcontractor) => {
        projectSubcontractors.value.set(subcontractor.id, {
          ...projectSubcontractors.value.get(subcontractor.id)!,
          ...subcontractor,
        });
      });
      changes.delete?.forEach((subcontractor) => {
        projectSubcontractors.value.delete(subcontractor.id);
      });
    };

    const copyState = () => {
      return new Map(projectSubcontractors.value);
    };

    const create = async (vars: CreateProjectSubcontractorMutationVariables) => {
      const client = useApolloClient();

      return client
        .mutate({
          mutation: createSubcontractor,
          variables: vars,
        })
        .then((result) => {
          const subcontractor =
            result.data?.createProjectSubcontractor?.projectSubcontractor ?? null;
          if (subcontractor) {
            const newSubcontractor = {
              ...subcontractor,
              tenantTradeVariationAssignments: flattenNodeConnection(
                subcontractor.tenantTradeVariationAssignments,
              ),
            };
            const newSubcontractorDetails = {
              ...subcontractor,
              contacts: flattenNodeConnection(subcontractor.contacts),
            };
            projectSubcontractors.value.set(subcontractor.id, newSubcontractor);
            projectSubcontractorDetails.value.set(subcontractor.id, newSubcontractorDetails);
            return newSubcontractor;
          }
          return null;
        });
    };

    const update = async (vars: UpdateProjectSubcontractorMutationVariables) => {
      const client = useApolloClient();

      return client
        .mutate({
          mutation: updateSubcontractor,
          variables: vars,
        })
        .then((result) => {
          const subcontractor =
            result.data?.updateProjectSubcontractor?.projectSubcontractor ?? null;
          if (subcontractor) {
            const newSubcontractor = {
              ...subcontractor,
              tenantTradeVariationAssignments: flattenNodeConnection(
                subcontractor.tenantTradeVariationAssignments,
              ),
            };
            const newSubcontractorDetails = {
              ...subcontractor,
              contacts: flattenNodeConnection(subcontractor.contacts),
            };
            projectSubcontractors.value.set(subcontractor.id, newSubcontractor);
            projectSubcontractorDetails.value.set(subcontractor.id, newSubcontractorDetails);
            return newSubcontractor;
          }
          return null;
        });
    };

    const remove = async (vars: string) => {
      const client = useApolloClient();

      return client
        .mutate({
          mutation: deleteSubcontractor,
          variables: {
            input: {
              id: vars,
            },
          },
        })
        .then((result) => {
          const wasDeleteSuccessful = result.data?.deleteProjectSubcontractor?.success ?? false;
          if (wasDeleteSuccessful) {
            projectSubcontractors.value.delete(vars);
          }
          return wasDeleteSuccessful;
        });
    };

    const addWorkerToSubcontractor = (
      subcontractorId: string,
      worker: ProjectSubcontractorWorkerFragment,
    ) => {
      projectSubcontractorDetails.value.get(subcontractorId)?.contacts.push(worker);
    };

    const deleteWorkerFromSubcontractor = (subcontractorId: string, workerId: string) => {
      const subcontractor = projectSubcontractorDetails.value.get(subcontractorId);
      if (!subcontractor) return;
      subcontractor.contacts = subcontractor.contacts.filter((worker) => worker.id !== workerId);
    };

    return {
      create,
      delete: remove,
      restore(): Promise<{
        changes: InteractiveEntityChanges;
        commit: UndoRedoCommit;
      }> {
        throw new Error('Implement restore on projectSubcontractorStore before using it');
      },
      update,
      getSoftDeletedEntity(): ProjectSubcontractorEntity | undefined {
        throw new Error(
          'Implement getSoftDeletedEntity on projectSubcontractorStore before using it',
        );
      },
      projectSubcontractors,
      projectSubcontractorDetails,
      fetchAll,
      fetchAllPromise,
      fetchDetails,
      applyChanges,
      copyState,
      setState,
      setPartialState,
      reset,
      addWorkerToSubcontractor,
      deleteWorkerFromSubcontractor,
      areProjectSubcontractorsLoading,
    };
  },
);
