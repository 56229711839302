import {
  Milestone,
  Order,
  OrderSchedulingEngine,
  ReducedOrder,
} from '@koppla-tech/scheduling-engine';
import { Mutex } from 'async-mutex';
import { OperationNames } from 'events.schema';
import {
  BehaviorSubject,
  filter,
  firstValueFrom,
  Observer,
  Subject,
  Subscription,
  timeout,
} from 'rxjs';

import {
  InteractiveEntityChanges,
  InteractiveEntityMaps,
  InteractiveEntityStores,
  PartialEntity,
} from '@/common/types';
import { useNotificationStore } from '@/features/notifications/notificationStore';
import { TradeType } from '@/features/projectTrades';
import {
  showActionFailedNotification,
  showLagUpdatedNotification,
} from '@/features/realTimeCollaboration';
import {
  RTC_CONTROLLER_INIT_TIMEOUT,
  RTC_CONTROLLER_POLLING_INTERVAL,
  RTC_OUT_OF_ORDER_EVENT_TIMEOUT,
} from '@/features/realTimeCollaboration/const';
import { UndoRedoQueue } from '@/features/undoRedo/queue';
import { mapDomainToSENGOrderStatus, mapStatusReportToOrderStatus } from '@/helpers/orders/status';
import { getRandomId } from '@/helpers/utils/strings';
import { LoggingService } from '@/interfaces/services';
import { ConsoleLoggingService } from '@/services';
import { IS_PROD_ENV } from '@/utils/config';
import { useMonitoring } from '@/utils/performance/useMonitoring';

import { RTCClient } from '../rtcClient';
import { RTCClientResponseStatus } from '../rtcClient/types';
import { RTCConflictDetection } from '../rtcConflictDetection';
import {
  BlockingInteraction,
  ExternalProjectDataChange,
  LocalProjectChangeEvent,
  LocalProjectChangeEventTemplate,
  RemoteProjectChangeEvent,
} from '../types';
import {
  distributeChangesToStores,
  getChangesFromEvent,
  sanitizeSchedulingChanges,
  unionizeChanges,
} from './changes';
import { RestoredEntityNotFoundError } from './errors';
import { mapInteractiveChangesToSENG } from './mapper';
import { copyStateMaps, copyStoreStates, setStoreStates, updateStateMaps } from './states';

interface RTCControllerProjectContext {
  tenantId: string;
  projectId: string;
}

const IGNORED_LOCAL_OPERATIONS = [OperationNames.DeleteProjectAlternative];

const { measureCallbackDuration, startManualDurationMeasurement, endManualDurationMeasurement } =
  useMonitoring();

export class RTCController {
  private initialized = ref(false);

  private context: RTCControllerProjectContext | undefined;

  private remoteProjectChangeChannel: Subject<RemoteProjectChangeEvent> | undefined;

  /* concurrency control start */
  private initializationMutex: Mutex = new Mutex();

  private latestEnqueuedProjectId: string | undefined;

  private destroyPromise: Promise<void> | undefined;
  /* concurrency control end */

  private state: RTCControllerProjectState;

  private pollingListeners: (() => void)[] = [];

  private pollingInterval: number | null = null;

  public constructor(
    private rtcClient: () => RTCClient,
    undoRedoQueue: () => UndoRedoQueue,
    localOrderSchedulingEngine: () => OrderSchedulingEngine,
    entityStores: InteractiveEntityStores,
    // TODO: make state required with next refactoring and remove other arguments
    state?: RTCControllerProjectState,
    loggingService: LoggingService = new ConsoleLoggingService(),
  ) {
    this.state =
      state ??
      new RTCControllerProjectState(
        rtcClient,
        undoRedoQueue,
        localOrderSchedulingEngine,
        entityStores,
        loggingService,
      );
  }

  public registerPollingListener(listener: () => void): void {
    this.pollingListeners.push(listener);
  }

  public unregisterPollingListener(listener: () => void): void {
    const index = this.pollingListeners.indexOf(listener);
    if (index !== -1) {
      this.pollingListeners.splice(index, 1);
    }
  }

  public async initialize(context: RTCControllerProjectContext): Promise<void> {
    const { initializationMutex } = this;
    this.latestEnqueuedProjectId = context.projectId;
    // Changing the line below to `const release = () => {};` should cause concurrency tests to fail
    const release = await initializationMutex.acquire();

    try {
      /**
       * Initialize has been called for another project while acquiring mutex.
       * We skip the init logic for this project as it would immediately
       * be destroyed on the following initialize.
       */
      if (this.latestEnqueuedProjectId !== context.projectId) {
        return;
      }

      // Same project already initialized
      if (this.context?.projectId === context.projectId && this.initialized.value) {
        return;
      }
      await this.runInitialize(context);
    } finally {
      release();
    }
  }

  public get isInitialized(): ComputedRef<boolean> {
    return computed(() => this.initialized.value);
  }

  public get initializationPromise(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.initialized.value) {
        resolve();
        return;
      }

      const stop = watch(this.initialized, (newValue) => {
        if (newValue) {
          resolve();
          stop();
        }
      });
    });
  }

  public subscribeToRemoteProjectChangeEvents(
    observer:
      | Partial<Observer<RemoteProjectChangeEvent>>
      | ((value: RemoteProjectChangeEvent) => void)
      | undefined,
  ): Subscription {
    if (!this.remoteProjectChangeChannel) this.remoteProjectChangeChannel = new Subject();
    return this.remoteProjectChangeChannel.subscribe(observer);
  }

  private async runInitialize(context: RTCControllerProjectContext) {
    if (this.initialized.value) {
      // mutex is already acquired, trying to acquire it again would cause deadlock
      await this.runDestroy(false);
    }
    // runDestroy resets internal state, we need to set projectId afterwards
    this.context = context;
    const rtcClient = this.rtcClient();
    rtcClient.connect();
    rtcClient.registerProjectChangeEventListener({
      id: 'RTCControllerProjectChangeEventListener',
      callback: (event, isOwn) => {
        if (isOwn) {
          endManualDurationMeasurement(event.messageId);
        }
        this.remoteProjectChangeChannel?.next(event);
        return this.state.onRemoteEvent(event, isOwn);
      },
    });
    rtcClient.registerExternalProjectDataChangeListener({
      id: 'RTCControllerExternalProjectDataChangeListener',
      callback: (payload) => {
        return this.state.onExternalDataChange(payload);
      },
    });
    await rtcClient.subscribeToProject(context.projectId);
    await this.state.initialize(context);
    this.initialized.value = true;

    this.pollingInterval = window.setInterval(() => {
      this.pollingListeners.forEach((listener) => listener());
    }, RTC_CONTROLLER_POLLING_INTERVAL);
  }

  public async destroy(): Promise<void> {
    if (this.destroyPromise) return this.destroyPromise;

    const destroyPromise = this.runDestroy(true).finally(() => {
      this.destroyPromise = undefined;
    });
    this.destroyPromise = destroyPromise;

    return destroyPromise;
  }

  private async runDestroy(requireLock: boolean): Promise<void> {
    const release = requireLock ? await this.initializationMutex.acquire() : () => {};

    try {
      await this.rtcClient().unsubscribeFromProject();
      if (this.remoteProjectChangeChannel) {
        this.remoteProjectChangeChannel.complete();
        this.remoteProjectChangeChannel = undefined;
      }
      this.initialized.value = false;
      this.context = undefined;
      this.state.reset();
      if (this.pollingInterval !== null) clearInterval(this.pollingInterval);
    } finally {
      release();
    }
  }

  public pushRemoteProjectChangeEvent(event: RemoteProjectChangeEvent): Promise<void> {
    return this.state.pushRemoteProjectChangeEvent(event);
  }

  public onRemoteEvent(event: RemoteProjectChangeEvent, isOwn: boolean): void {
    return this.state.onRemoteEvent(event, isOwn);
  }

  public async pushLocalProjectChangeEvent(
    template: LocalProjectChangeEventTemplate,
    {
      commitId,
    }: {
      commitId?: string;
    } = {},
  ): Promise<InteractiveEntityChanges> {
    return this.state.pushLocalProjectChangeEvent(template, { commitId });
  }

  public get currentBlockingInteraction(): Ref<BlockingInteraction | null> {
    return this.state.currentBlockingInteraction;
  }

  public removeBlockingInteraction(): void {
    this.state.removeBlockingInteraction();
  }

  public get currentProjectId(): string {
    return this.context?.projectId ?? '';
  }

  public getValidStoreStates(): InteractiveEntityMaps {
    return this.state.getValidStoreStates();
  }
}

export class RTCControllerProjectState {
  private remoteOrderSchedulingEngine = new OrderSchedulingEngine();

  /**
   * Holds all remote events that arrive while the controller hasn't been fully initialized yet, which
   * will be processed once the controller is initialized.
   */
  private pendingRemoteProjectChangeEvents: {
    isRemoteEvent: boolean;
    payload: RemoteProjectChangeEvent | ExternalProjectDataChange;
  }[] = [];

  /**
   * Holds all local events that have been sent to the server, but haven't been validated by the server yet.
   */
  private localProjectChangeEvents: LocalProjectChangeEvent[] = [];

  /**
   * Holds all local events that have been validated by the server, but that have arrived out of order.
   * These events will be replayed once the server has validated the previous events.
   */
  private outOfOrderLocalProjectChangeEvents: {
    predecessorIds: string[];
    event: RemoteProjectChangeEvent;
  }[] = [];

  private outOfOrderEventTimeoutId: number | undefined;

  private isInitialized = new BehaviorSubject<boolean>(false);

  private validStoreStates: InteractiveEntityMaps = {};

  private messageIdToCommitIdDictionary: Record<string, string> = {};

  private blockingInteraction = ref<BlockingInteraction | null>(null);

  public constructor(
    private rtcClient: () => RTCClient,
    private undoRedoQueue: () => UndoRedoQueue,
    private localOrderSchedulingEngine: () => OrderSchedulingEngine,
    private entityStores: InteractiveEntityStores,
    private loggingService: LoggingService = new ConsoleLoggingService(),
  ) {}

  public async initialize(context: RTCControllerProjectContext): Promise<void> {
    const initializationId = getRandomId();
    startManualDurationMeasurement(initializationId, `RTC Initialization`);

    // Trade sequences aren't needed for SENG initialization so we don't add it to blocking promises.
    const tradeSequenceStoreSetupPromise = this.entityStores
      .projectTradeSequenceStore()
      .fetchAll(context.projectId);
    const tenantTradeStoreSetupPromise = this.entityStores
      .tenantTradeStore()
      .fetchAll({ tenant: context.tenantId });
    const projectTradeStoreSetupPromise = this.entityStores
      .projectTradeStore()
      .fetchAll({ project: context.projectId });

    const [calendars, pauses, orders, milestones, dependencies] = await Promise.all([
      this.entityStores.calendarStore().fetchAll(context.projectId),
      this.entityStores.pauseStore().fetchAll(context.projectId),
      this.entityStores.orderStore().fetchAll(context.projectId),
      this.entityStores.milestoneStore().fetchAll(context.projectId),
      this.entityStores.orderDependencyStore().fetchAll(context.projectId),
      this.entityStores.projectSubcontractorStore().fetchAll(context.projectId),
      this.entityStores.wbsSectionStore().fetchAll(context.projectId),
    ]);

    const reducedOrders = orders.map<ReducedOrder>((order) => {
      return {
        ...order,
        status: mapDomainToSENGOrderStatus(mapStatusReportToOrderStatus(order.status)),
      };
    });

    this.remoteOrderSchedulingEngine.initialize({
      pauses,
      orders: reducedOrders,
      milestones: milestones as Milestone[], // this conversion should be removed when removing isFixed from Scheduling Engine
      dependencies,
      calendars,
    });
    this.localOrderSchedulingEngine().rebase(this.remoteOrderSchedulingEngine);

    await Promise.all([
      tradeSequenceStoreSetupPromise,
      tenantTradeStoreSetupPromise,
      projectTradeStoreSetupPromise,
    ]);

    this.validStoreStates = copyStoreStates(this.entityStores);

    this.isInitialized.next(true);
    endManualDurationMeasurement(initializationId);

    this.pendingRemoteProjectChangeEvents.forEach((event) => {
      if (event.isRemoteEvent) {
        this.pushRemoteProjectChangeEvent(event.payload as RemoteProjectChangeEvent);
      } else {
        this.onExternalDataChange(event.payload as ExternalProjectDataChange);
      }
    });
  }

  public reset(): void {
    this.pendingRemoteProjectChangeEvents = [];
    this.localProjectChangeEvents = [];
    this.validStoreStates = {};
    this.isInitialized.next(false);
    this.messageIdToCommitIdDictionary = {};
    this.undoRedoQueue().reset();
    this.remoteOrderSchedulingEngine.reset();
    this.localOrderSchedulingEngine().reset();

    this.entityStores.pauseStore().reset();
    this.entityStores.milestoneStore().reset();
    this.entityStores.orderStore().reset();
    this.entityStores.calendarStore().reset();
    this.entityStores.orderDependencyStore().reset();
    this.entityStores.projectTradeSequenceStore().reset();
    this.entityStores.projectSubcontractorStore().reset();
    this.entityStores.wbsSectionStore().reset();
  }

  public onRemoteEvent(event: RemoteProjectChangeEvent, isOwn: boolean): void {
    if (isOwn) {
      this.handleOwnRemoteEvent(event);
    } else {
      this.handleOtherRemoteEvent(event);
    }
  }

  private handleOtherRemoteEvent(event: RemoteProjectChangeEvent): void {
    this.handleRemoteEventBlockingInteractions(event);
    if (event.status === RTCClientResponseStatus.SUCCESS) {
      this.pushRemoteProjectChangeEvent(event);
    }
  }

  private handleOwnRemoteEvent(event: RemoteProjectChangeEvent): void {
    measureCallbackDuration(`RTC FE Validation handling ${event.operation.name}`, () => {
      if (event.status === RTCClientResponseStatus.SUCCESS) {
        this.validateProjectChangeEvent(event);
      } else {
        this.invalidateProjectChangeEvent(event);
      }
    });
    this.replayOutOfOrderEvents();
  }

  /**
   * Due to network latency, events might arrive out of order at the client,
   * which can lead to conflicts when replaying them. To avoid this, we try to replay
   * out of order events in the correct order by making sure all predecessor events have been processed.
   */
  private replayOutOfOrderEvents(): void {
    if (!this.outOfOrderLocalProjectChangeEvents.length) {
      if (this.outOfOrderEventTimeoutId) {
        clearTimeout(this.outOfOrderEventTimeoutId);
        this.outOfOrderEventTimeoutId = undefined;
      }
      return;
    }
    const localEventIds = new Set(this.localProjectChangeEvents.map((event) => event.messageId));

    // Since local events are added sequentially, only one event can be processed at a time, which
    // is the one that has no unresolved predecessors. Hence, we sort for the event with the least
    // unresolved predecessors.
    const sortedOutOfOrderEvents = this.outOfOrderLocalProjectChangeEvents
      .map((event) => ({
        event: event.event,
        unresolvedPredecessors: event.predecessorIds.filter((id) => localEventIds.has(id)),
      }))
      .sort((a, b) => {
        return a.unresolvedPredecessors.length - b.unresolvedPredecessors.length;
      });

    const outOfOrderEvent = sortedOutOfOrderEvents[0];
    const predecessorEventsResolved = !outOfOrderEvent.unresolvedPredecessors.length;

    if (!IS_PROD_ENV) {
      // eslint-disable-next-line no-console
      console.log('Try to replay out of order event', outOfOrderEvent, {
        allResolved: predecessorEventsResolved,
      });
    }

    if (predecessorEventsResolved) {
      this.outOfOrderLocalProjectChangeEvents = this.outOfOrderLocalProjectChangeEvents.filter(
        (event) => event.event.messageId !== outOfOrderEvent.event.messageId,
      );
      this.handleOwnRemoteEvent(outOfOrderEvent.event);
    }
  }

  public onExternalDataChange(payload: ExternalProjectDataChange): void {
    if (!this.isInitialized.value) {
      this.pendingRemoteProjectChangeEvents.push({ isRemoteEvent: false, payload });
      return;
    }

    if (payload.name === 'TradeChange') {
      if (payload.data.isTenantChange) {
        this.entityStores.tenantTradeStore().setState(payload.data.trades);
        this.entityStores.projectTradeStore().setTenantTrades(payload.data.trades);
      } else {
        this.entityStores.projectTradeStore().setProjectTrades(payload.data.trades);
        this.entityStores
          .tenantTradeStore()
          .setState(payload.data.trades.filter((trade) => trade.type === TradeType.Tenant));
      }
    }
    if (payload.name === 'SubcontractorChange') {
      this.entityStores.projectSubcontractorStore().setPartialState(
        new Map(
          payload.data.subcontractors.map((subcontractor) => [
            subcontractor.subcontractorAssignmentId,
            {
              ...subcontractor,
              id: subcontractor.subcontractorAssignmentId,
              tenant: {
                id: subcontractor.id,
                name: subcontractor.name,
              },
              tenantTradeVariationAssignments: subcontractor.tenantTradeVariationAssignments.map(
                (assignment) => ({
                  ...assignment,
                  id: '',
                  tenantTradeVariation: {
                    id: assignment.tenantTradeVariationId,
                  },
                }),
              ),
            },
          ]),
        ),
      );
      const unassignedOrders = this.entityStores
        .orderStore()
        .unassignSubcontractorIfNecessary(
          Array.from(
            this.entityStores.projectSubcontractorStore().projectSubcontractors.values(),
          ).map((subcontractor) => subcontractor.id),
        );

      const filterEngineOrders = (order: Order) => unassignedOrders.has(order.id);
      const amendEngineOrders = (order: Order) => ({
        id: order.id,
        subcontractor: null,
      });
      this.amendOrdersInEngine(
        this.localOrderSchedulingEngine(),
        filterEngineOrders,
        amendEngineOrders,
      );
      this.amendOrdersInEngine(
        this.remoteOrderSchedulingEngine,
        filterEngineOrders,
        amendEngineOrders,
      );
    }
    if (payload.name === 'TradeReplacement') {
      this.entityStores
        .orderStore()
        .replaceTrade(
          payload.data.replacement.previousTradeId,
          payload.data.replacement.newTradeId,
        );
      this.entityStores
        .projectTradeSequenceStore()
        .replaceTrade(
          payload.data.replacement.previousTradeId,
          payload.data.replacement.newTradeId,
        );
      this.entityStores
        .tenantTradeSequenceStore()
        .replaceTrade(
          payload.data.replacement.previousTradeId,
          payload.data.replacement.newTradeId,
        );

      const filterEngineOrders = (order: Order) =>
        order.tenantTradeVariation?.id === payload.data.replacement.previousTradeId;
      const amendEngineOrders = (order: Order) => ({
        id: order.id,
        tenantTradeVariation: { id: payload.data.replacement.newTradeId },
      });
      this.amendOrdersInEngine(
        this.localOrderSchedulingEngine(),
        filterEngineOrders,
        amendEngineOrders,
      );
      this.amendOrdersInEngine(
        this.remoteOrderSchedulingEngine,
        filterEngineOrders,
        amendEngineOrders,
      );

      this.entityStores.tenantTradeStore().deleteTrade(payload.data.replacement.previousTradeId);
      this.entityStores.projectTradeStore().deleteTrade(payload.data.replacement.previousTradeId);
    }
  }

  public async pushRemoteProjectChangeEvent(event: RemoteProjectChangeEvent): Promise<void> {
    if (!this.isInitialized.value) {
      this.pendingRemoteProjectChangeEvents.push({ isRemoteEvent: true, payload: event });
      return;
    }

    let remoteChanges: InteractiveEntityChanges;
    try {
      const { changes } = getChangesFromEvent(
        event,
        this.remoteOrderSchedulingEngine,
        this.entityStores,
        this.validStoreStates,
      );
      remoteChanges = { ...changes };

      this.validStoreStates = updateStateMaps(this.validStoreStates, remoteChanges);
      this.localOrderSchedulingEngine().rebase(this.remoteOrderSchedulingEngine);
      const { invalidEvents } = this.replayValidLocalEvents(event, remoteChanges, {
        initialStoreStates: this.validStoreStates,
      });
      this.notifyAndDropInvalidEvents(invalidEvents);
    } catch (error) {
      if (error instanceof RestoredEntityNotFoundError) {
        this.blockingInteraction.value = {
          reason: 'RESTORED_ENTITY_NOT_FOUND',
        };
      }
      this.loggingService.error(error as Error, {
        code: 'RTCController.pushRemoteProjectChangeEvent',
      });
    }
  }

  public get currentBlockingInteraction(): Ref<BlockingInteraction | null> {
    return computed(() => this.blockingInteraction.value);
  }

  public removeBlockingInteraction(): void {
    this.blockingInteraction.value = null;
  }

  public async pushLocalProjectChangeEvent(
    template: LocalProjectChangeEventTemplate,
    {
      commitId,
    }: {
      commitId?: string;
    } = {},
  ): Promise<InteractiveEntityChanges> {
    await this.ensureInitialized();

    startManualDurationMeasurement(
      template.messageId,
      `RTC FE Processing ${template.operation.name}`,
    );

    let localChanges: InteractiveEntityChanges;
    try {
      const { changes, idMappings, rescheduledDependencies } = getChangesFromEvent(
        template,
        this.localOrderSchedulingEngine(),
        this.entityStores,
        copyStoreStates(this.entityStores),
        false,
        IGNORED_LOCAL_OPERATIONS,
      );
      localChanges = { ...changes };

      rescheduledDependencies.forEach((rescheduledDependency) => {
        showLagUpdatedNotification(rescheduledDependency);
      });

      if (template.operation.name === OperationNames.InsertTradeSequence) {
        template.operation.input.idMapping = {
          activityToOrderId: idMappings![0].activityIdToOrderId,
          dependencies: idMappings![0].orderIdsToOrderDependencyId,
        };
      } else if (template.operation.name === OperationNames.UpdateTradeSequence) {
        template.operation.input.idMappings = idMappings!.map((idMapping) => ({
          tradeSequenceInstanceId: idMapping.tradeSequenceInstanceId,
          activityToOrderId: idMapping.activityIdToOrderId,
          dependencies: idMapping.orderIdsToOrderDependencyId,
        }));
      }

      const event = this.rtcClient().prepareProjectChangeEventForPublishing(template);
      if (commitId) {
        this.messageIdToCommitIdDictionary[event.messageId] = commitId;
      }

      distributeChangesToStores(this.entityStores, changes);

      // NOTE: we don't return the actual promise here, since the validate/invalidate handling will happen separately via the socket
      this.rtcClient().publishProjectChangeEvent(event, () => {
        this.localProjectChangeEvents.push(event);
      });

      endManualDurationMeasurement(template.messageId);
      startManualDurationMeasurement(
        template.messageId,
        `RTC BE Validation ${template.operation.name}`,
      );
    } catch (error) {
      endManualDurationMeasurement(template.messageId);
      this.loggingService.error(error as Error, {
        code: 'RTCController.pushLocalProjectChangeEvent',
      });
      throw error;
    }

    return localChanges;
  }

  private validateProjectChangeEvent(event: RemoteProjectChangeEvent): void {
    const localEvent = this.retrieveLocalEventFromQueue(event);
    if (!localEvent) {
      return;
    }

    try {
      const { changes } = getChangesFromEvent(
        event,
        this.remoteOrderSchedulingEngine,
        this.entityStores,
        this.validStoreStates,
      );
      this.validStoreStates = updateStateMaps(this.validStoreStates, changes);
      this.localOrderSchedulingEngine().rebase(this.remoteOrderSchedulingEngine);
      this.replayAllLocalEvents(this.validStoreStates);
    } catch (error) {
      this.loggingService.error(error as Error, {
        code: 'RTCController.validateProjectChangeEvent',
      });
    }
  }

  private invalidateProjectChangeEvent(event: RemoteProjectChangeEvent): void {
    const localEvent = this.retrieveLocalEventFromQueue(event);
    if (!localEvent) {
      return;
    }

    showActionFailedNotification();

    try {
      const { changes: invalidChanges } = getChangesFromEvent(
        localEvent,
        this.remoteOrderSchedulingEngine,
        this.entityStores,
        this.validStoreStates,
        true,
      );
      this.localOrderSchedulingEngine().rebase(this.remoteOrderSchedulingEngine);
      const { invalidEvents } = this.replayValidLocalEvents(localEvent, invalidChanges, {
        initialStoreStates: this.validStoreStates,
        checkForEventConflicts: false,
        unionizeSubsequentEventChanges: true,
      });

      this.undoRedoQueue().drop(this.messageIdToCommitIdDictionary[event.messageId]);
      this.notifyAndDropInvalidEvents(invalidEvents);
    } catch (error) {
      this.loggingService.error(error as Error, {
        code: 'RTCController.invalidateProjectChangeEvent',
      });
    }
  }

  private retrieveLocalEventFromQueue(
    event: RemoteProjectChangeEvent,
  ): LocalProjectChangeEvent | undefined {
    const localEventIdx = this.localProjectChangeEvents.findIndex((localEvent) => {
      return localEvent.messageId === event.messageId;
    });
    if (localEventIdx === -1) {
      return undefined;
    }
    /**
     * If events arrive out of order, we push them to a separate queue and wait for the missing events to arrive.
     * To not end up in a deadlock, where we wait for an event that will never arrive, we set a timeout.
     */
    if (localEventIdx > 0) {
      const predecessorIds = this.localProjectChangeEvents
        .slice(0, localEventIdx)
        .map((e) => e.messageId);
      this.outOfOrderLocalProjectChangeEvents.push({
        predecessorIds,
        event,
      });
      if (!this.outOfOrderEventTimeoutId) {
        this.outOfOrderEventTimeoutId = window.setTimeout(() => {
          this.blockingInteraction.value = {
            reason: 'INVALID_LOCAL_EVENT_ORDER',
          };
          this.loggingService.error(
            new Error(`Out Of Order Event Timeout Reached with index ${localEventIdx}`),
            {
              code: 'OutOfOrderEventTimeout',
            },
          );
        }, RTC_OUT_OF_ORDER_EVENT_TIMEOUT);
      }
      return undefined;
    }
    const localEvent = this.localProjectChangeEvents[localEventIdx];
    this.localProjectChangeEvents.splice(localEventIdx, 1);
    return localEvent;
  }

  /**
   * Replay all events that are currently on the local queue. This will update the local order scheduling engine
   * as well as all other entity stores based on an initial state.
   */
  private replayAllLocalEvents(initialStoreStates: InteractiveEntityMaps): void {
    let storeStates = copyStateMaps(initialStoreStates);

    this.localProjectChangeEvents.forEach((localEvent) => {
      try {
        const { changes: schedulingChanges } = getChangesFromEvent(
          localEvent,
          this.localOrderSchedulingEngine(),
          this.entityStores,
          storeStates,
        );
        storeStates = updateStateMaps(storeStates, schedulingChanges as InteractiveEntityChanges);
      } catch (error) {
        this.localProjectChangeEvents.splice(this.localProjectChangeEvents.indexOf(localEvent), 1);
        this.loggingService.error(error as Error, {
          code: 'RTCController.replayAllLocalEvents',
        });
      }
    });

    setStoreStates(this.entityStores, storeStates);
  }

  /**
   * Replay all events that are currently on the local queue and that don't have any conflicts with a processed (remote) event.
   * This will update the local order scheduling engine as well as all other entity stores based on an initial state.
   * Invalid events are skipped and returned in the result.
   */
  private replayValidLocalEvents(
    processedEvent: LocalProjectChangeEvent | RemoteProjectChangeEvent,
    processedEventChanges: InteractiveEntityChanges,
    {
      initialStoreStates,
      checkForEventConflicts = true,
      unionizeSubsequentEventChanges = false,
    }: {
      initialStoreStates: InteractiveEntityMaps;
      checkForEventConflicts?: boolean;
      unionizeSubsequentEventChanges?: boolean;
    },
  ): { invalidEvents: LocalProjectChangeEvent[] } {
    const invalidEvents: LocalProjectChangeEvent[] = [];
    let storeStates = copyStateMaps(initialStoreStates);

    this.localProjectChangeEvents.forEach((localEvent) => {
      let isInvalid = false;

      if (checkForEventConflicts) {
        isInvalid = RTCConflictDetection.eventsHaveConflicts(processedEvent, localEvent);
      }

      let localEventChanges: InteractiveEntityChanges | undefined;
      try {
        const { changes: schedulingChanges } = getChangesFromEvent(
          localEvent,
          this.localOrderSchedulingEngine(),
          this.entityStores,
          storeStates,
          true,
        );
        localEventChanges = { ...(schedulingChanges as InteractiveEntityChanges) };
      } catch (error) {
        this.loggingService.error(error as Error, {
          code: 'RTCController.replayValidLocalEvents',
        });
        isInvalid = true;
      }
      if (!localEventChanges) {
        isInvalid = true;
        return;
      }

      if (RTCConflictDetection.changesHaveConflicts(processedEventChanges, localEventChanges)) {
        isInvalid = true;
      }

      if (!isInvalid) {
        this.localOrderSchedulingEngine().commit(
          mapInteractiveChangesToSENG(sanitizeSchedulingChanges(localEventChanges)),
        );
        storeStates = updateStateMaps(storeStates, localEventChanges);
      } else {
        invalidEvents.push(localEvent);
        if (unionizeSubsequentEventChanges) {
          processedEventChanges = unionizeChanges(processedEventChanges, localEventChanges);
        }
      }
    });

    setStoreStates(this.entityStores, storeStates);

    return { invalidEvents };
  }

  private notifyAndDropInvalidEvents(invalidEvents: LocalProjectChangeEvent[]) {
    if (invalidEvents.length > 0) {
      showActionFailedNotification();
    }

    invalidEvents.forEach((invalidEvent) => {
      this.localProjectChangeEvents.splice(this.localProjectChangeEvents.indexOf(invalidEvent), 1);
      this.undoRedoQueue().drop(this.messageIdToCommitIdDictionary[invalidEvent.messageId]);
    });
  }

  private amendOrdersInEngine(
    engine: OrderSchedulingEngine,
    filterEngineOrders: (order: Order) => boolean,
    amendEngineOrders: (order: Order) => PartialEntity<Order>,
  ): void {
    const filteredOrders = this.localOrderSchedulingEngine()
      .getState()
      .orders.filter(filterEngineOrders);
    engine.amend(
      {
        orders: filteredOrders.map((o) => o.id),
      },
      {
        orders: filteredOrders.map(amendEngineOrders),
      },
    );
  }

  private handleRemoteEventBlockingInteractions(event: RemoteProjectChangeEvent): void {
    if (
      event.operation.name === OperationNames.PublishProjectAlternative ||
      event.operation.name === OperationNames.RestoreProjectVersion
    ) {
      if (event.status === RTCClientResponseStatus.SUCCESS) {
        this.blockingInteraction.value = {
          reason: 'SCHEDULE_VERSION_CHANGE',
          data: { user: event.user },
        };
      } else if (event.status === RTCClientResponseStatus.ERROR) {
        const notificationStore = useNotificationStore();
        notificationStore.push({
          titleI18nKey: 'objects.realTimeCollaboration.scheduleVersionChangeFailedTitle',
          type: 'attention',
          icon: 'info',
        });
        this.blockingInteraction.value = null;
      }
    } else if (
      event.operation.name === OperationNames.PublishProjectAlternativeResult ||
      event.operation.name === OperationNames.RestoreProjectVersionResult
    ) {
      if (event.operation.input?.status === RTCClientResponseStatus.SUCCESS) {
        this.blockingInteraction.value = {
          reason: 'SCHEDULE_VERSION_CHANGE_COMPLETED',
          data: { user: event.user },
        };
      } else if (event.operation.input?.status === RTCClientResponseStatus.ERROR) {
        const notificationStore = useNotificationStore();
        notificationStore.push({
          titleI18nKey: 'objects.realTimeCollaboration.scheduleVersionChangeFailedTitle',
          type: 'attention',
          icon: 'info',
        });
        this.blockingInteraction.value = null;
      }
    } else if (event.operation.name === OperationNames.DeleteProjectAlternative) {
      this.blockingInteraction.value = {
        reason: 'PROJECT_ALTERNATIVE_DELETED',
        data: {
          user: event.user,
        },
      };
    } else if (event.operation.name === OperationNames.DeleteProject) {
      this.blockingInteraction.value = {
        reason: 'PROJECT_DELETED',
        data: {
          user: event.user,
        },
      };
    }
  }

  private async ensureInitialized(): Promise<void> {
    if (!this.isInitialized.value) {
      try {
        await firstValueFrom(
          this.isInitialized.pipe(
            filter((isInitialized) => isInitialized),
            timeout(RTC_CONTROLLER_INIT_TIMEOUT),
          ),
        );
      } catch {
        throw new Error('RTCControllerProjectState could not be initialized.');
      }
    }
  }

  public getValidStoreStates(): InteractiveEntityMaps {
    return this.validStoreStates;
  }
}
