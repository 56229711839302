import { computed } from 'vue';

import { LiveUser } from '../../types';
import { filterAndSortLiveUsers } from './liveUsersUtils';

const liveUsers = ref<LiveUser[]>([]);

/**
 * A Vue composable function to manage live user data.
 * It provides a reactive `users` computed property that reflects the current state of live users,
 * and a `setLiveUsers` method to update the live users list after filtering support users and sorting by joined at.
 */
export function useLiveUsers() {
  const users = computed(() => liveUsers.value);

  const setLiveUsers = (newLiveUsers: LiveUser[] = []) => {
    liveUsers.value = filterAndSortLiveUsers(newLiveUsers);
  };

  return { users, setLiveUsers };
}
