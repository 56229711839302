import { defineStore } from 'pinia';
import { RouteLocationNormalizedLoaded, useRoute } from 'vue-router';

import { useIocProvider } from '@/ioc/injectKey';

export const useGlobalStore = defineStore('global-store', () => {
  const route = useRoute();
  const { authenticationService } = useIocProvider();

  const currentProjectId = ref<string | null>(null);
  const currentTenantId = ref<string | null>(null);
  const projectInitializationPromise = ref<Promise<void> | null>(null);
  const appInitializationPromise = ref<Promise<void> | null>(null);

  const getParamFromRoute = (
    routeObj: RouteLocationNormalizedLoaded,
    paramName: string,
  ): string | null => {
    const value = routeObj.params[paramName];
    return typeof value === 'string' ? value : null;
  };

  // value set by alternative store
  const currentAlternativeProjectId = ref<string | null>(null);
  const scheduleProjectId = computed(
    () => currentAlternativeProjectId.value ?? currentProjectId.value,
  );

  const whenLoggedIn = (callback: () => void) => {
    watchEffect(async () => {
      if (currentTenantId.value) {
        const authenticated = await authenticationService.isAuthenticated();
        if (authenticated) callback();
      }
    });
  };

  watchEffect(
    () => {
      if (!route.fullPath.includes('project')) {
        currentProjectId.value = null;
        return;
      }
      currentProjectId.value = getParamFromRoute(route, 'id');
    },
    // Important otherwise the router already handles the new route
    // but components that access this store receive the previous project id.
    // TheLeanLayout.vue will cause an error without sync flushing.
    { flush: 'sync' },
  );

  watchEffect(() => {
    currentTenantId.value = getParamFromRoute(route, 'tenantId');
  });

  const whenEnteringProject = (callback: () => void) => {
    watch(
      currentProjectId,
      (newProjectId, oldProjectId) => {
        // make sure tenant id is always set when entering a project
        if (!currentTenantId.value) currentTenantId.value = getParamFromRoute(route, 'tenantId');
        if (newProjectId && newProjectId !== oldProjectId) callback();
      },
      { immediate: true },
    );
  };
  const whenExitingProject = (callback: () => void) => {
    watch(
      currentProjectId,
      (newProjectId, oldProjectId) => {
        if (!newProjectId && oldProjectId) callback();
      },
      { immediate: true },
    );
  };

  return {
    appInitializationPromise,
    projectInitializationPromise,
    currentProjectId,
    scheduleProjectId,
    currentTenantId,
    currentAlternativeProjectId,
    whenExitingProject,
    whenEnteringProject,
    whenLoggedIn,
  };
});
