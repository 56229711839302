import { TenantQuery, TenantQueryVariables } from '@/graphql/__generated__/graphql';
import OWN_TENANT from '@/graphql/tenants/Detail.gql';
import { useApolloClient } from '@/plugins/apollo';

export class TenantStoreRepository {
  public async fetchOwn(vars: TenantQueryVariables) {
    const client = useApolloClient();

    return client
      .query<TenantQuery, TenantQueryVariables>({
        query: OWN_TENANT,
        variables: vars,
      })
      .then((result) => {
        if (!result.data.tenant) return null;
        return result.data.tenant;
      });
  }
}
