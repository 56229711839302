import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

import { MembershipWithTenant } from '@/interfaces/repositories/memberships';
import { ProjectSubcontractorWorker } from '@/interfaces/repositories/projectSubcontractorWorker';
import { AuthenticationService } from '@/interfaces/services';

import { useRouteNames } from './routeNames';

const { Routes } = useRouteNames();

export function getLoginRoute(): RouteLocationRaw {
  return { name: Routes.Unauthenticated.Login };
}

export function getNoMembershipsRoute(): RouteLocationRaw {
  return {
    name: Routes.User.NoMemberships,
  };
}

export function getProjectsAllOrPickTenantRoute(tenantId?: string) {
  if (tenantId) {
    return { name: Routes.Lean.All, params: { tenantId } };
  }
  return { name: Routes.Base.PickTenant };
}

export function getPickTenantRoute(): RouteLocationRaw {
  return { name: Routes.Base.PickTenant };
}

export async function getVerificationFailedRoute(
  to: RouteLocationNormalized,
  authenticationService: AuthenticationService,
): Promise<RouteLocationRaw> {
  if (to.name === Routes.Unauthenticated.VerificationFailed) {
    return { name: Routes.Unauthenticated.VerificationFailed };
  }
  const user = await authenticationService.getUser();
  return {
    name: Routes.Unauthenticated.VerificationFailed,
    query: { email: user?.email ?? '' },
  };
}

export function getRouteForMembershipStatus(
  to: RouteLocationNormalized,
  membership: MembershipWithTenant | null | undefined,
  memberships: MembershipWithTenant[],
  subcontraction: ProjectSubcontractorWorker | null | undefined,
  subcontractions: readonly ProjectSubcontractorWorker[],
): boolean | RouteLocationRaw {
  if (membership || subcontraction) return false;

  if (memberships.length || subcontractions.length) {
    return getPickTenantRoute();
  }
  return to.name !== Routes.User.NoMemberships ? getNoMembershipsRoute() : true;
}
