import { WbsSectionEntity } from '@/common/types';
import { getSectionsMap } from '@/features/projectStructure';

const constructSectionsParentChildMap = (sections: Map<string, WbsSectionEntity>) => {
  const noParentId = '';
  const parentChildMap = new Map<string, WbsSectionEntity[]>([[noParentId, []]]);

  sections.forEach((section) => {
    parentChildMap.set(section.id, []);
  });

  sections.forEach((section) => {
    const parentId = section.parentId || '';

    const children = parentChildMap.get(parentId) || [];
    children.push(section);
    parentChildMap.set(parentId, children);
  });
  return parentChildMap;
};

export const applyCorrectPositionsToSections = (sections: WbsSectionEntity[]) => {
  const sectionsParentChildMap = constructSectionsParentChildMap(getSectionsMap(sections));

  const sectionsWithFixedPositions: WbsSectionEntity[] = [];

  sectionsParentChildMap.forEach((children) => {
    children.forEach((section, idx) => {
      sectionsWithFixedPositions.push({
        ...section,
        position: idx,
      });
    });
  });

  return sectionsWithFixedPositions;
};
