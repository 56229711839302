import { defineStore } from 'pinia';

import {
  MembershipsOfUserQuery,
  MembershipWithTenantFragment,
  ProjectMembershipFragment,
  ProjectSubcontractorWorkerFragment,
  ProjectSubcontractorWorkersQuery,
  ProjectSubcontractorWorkersQueryVariables,
  UserProjectMembershipQuery,
} from '@/graphql/__generated__/graphql';
import MembershipsOwnQuery from '@/graphql/membership/AllOwn.gql';
import UserProjectMembership from '@/graphql/projectMemberships/Own.gql';
import ProjectSubcontractorWorkersForUser from '@/graphql/projectSubcontractorWorkers/AllForUser.gql';
import { useApolloClient } from '@/plugins/apollo';
import { flattenNodeConnection } from '@/repositories/utils/fetchAll';

export const useMembershipStore = defineStore('membership-store', () => {
  const projectMembershipLoading = ref(false);
  const projectMembership = ref<ProjectMembershipFragment | null>(null);
  const tenantMembershipsLoading = ref(false);
  const tenantMemberships = ref<MembershipWithTenantFragment[]>([]);
  const projectSubcontractorWorkersLoading = ref(false);
  const projectSubcontractorWorkers = ref<ProjectSubcontractorWorkerFragment[]>([]);

  const loading = computed(
    () =>
      (projectMembershipLoading.value && !projectMembership.value) ||
      (tenantMembershipsLoading.value && !tenantMemberships.value) ||
      (projectSubcontractorWorkersLoading.value && !projectSubcontractorWorkers.value),
  );

  const fetchProjectMembership = async (projectId: string) => {
    projectMembershipLoading.value = true;
    return useApolloClient()
      .query<UserProjectMembershipQuery>({
        query: UserProjectMembership,
        variables: { projectId },
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        projectMembership.value = result.data.userProjectMembership ?? null;
        return projectMembership.value;
      })
      .finally(() => {
        projectMembershipLoading.value = false;
      });
  };
  const fetchTenantMemberships = async () => {
    tenantMembershipsLoading.value = true;
    return useApolloClient()
      .query<MembershipsOfUserQuery>({
        query: MembershipsOwnQuery,
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const memberships = result.data.memberships
          ? flattenNodeConnection(result.data.memberships)
          : [];
        tenantMemberships.value = memberships;
        return memberships;
      })
      .finally(() => {
        tenantMembershipsLoading.value = false;
      });
  };

  const fetchProjectSubcontractorWorkers = async () => {
    projectSubcontractorWorkersLoading.value = true;
    await useApolloClient()
      .query<ProjectSubcontractorWorkersQuery, ProjectSubcontractorWorkersQueryVariables>({
        query: ProjectSubcontractorWorkersForUser,
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        projectSubcontractorWorkers.value = result.data.projectSubcontractorWorkers
          ? flattenNodeConnection(result.data.projectSubcontractorWorkers)
          : [];
      })
      .finally(() => {
        projectSubcontractorWorkersLoading.value = false;
      });
  };

  return {
    projectMembershipLoading,
    projectMembership,
    tenantMembershipsLoading,
    tenantMemberships,
    projectSubcontractorWorkersLoading,
    projectSubcontractorWorkers,
    loading,
    fetchProjectMembership,
    fetchTenantMemberships,
    fetchProjectSubcontractorWorkers,
  };
});
