import { defineStore } from 'pinia';

import { OrderDependencyEntity } from '@/common/types';
import { useMilestoneStoreV2 } from '@/features/milestones';
import {
  createUndoRedoRemoveNodesCommit,
  createUndoRedoRescheduleScheduleNodesCommit,
  createUndoRedoRestoreNodesCommit,
} from '@/features/multiSelectActions/multiSelectActionCommits';
import {
  createProjectChangeRemoveNodesEvent,
  createProjectChangeRescheduleNodesEvent,
  createProjectChangeRestoreNodesEvent,
} from '@/features/multiSelectActions/multiSelectActionEvents';
import { useOrderStoreV2 } from '@/features/orders';
import { usePauseStoreV2 } from '@/features/pauses';
import { gql } from '@/graphql/__generated__/gql';
import { useApolloClient } from '@/plugins/apollo';

import { useCalendarStore } from '../calendars';
import { useOrderDependencyStoreV2 } from '../orderDependencies';
import { ProjectChangeEventContext } from '../realTimeCollaboration/types';
import { pushLocalChangeEventAndCommit } from '../realTimeCollaboration/utils';
import {
  RemoveEntitiesVariables,
  RescheduleEntitiesVariables,
  RestoreEntitiesVariables,
} from './types';

export const useMultiSelectActionsStore = defineStore('multi-select-actions-store', () => {
  const client = useApolloClient();

  const fetchOrdersTasks = async (orderIds: string[]) => {
    const query = gql(/* GraphQL */ `
      query OrdersTasks($ids: [ID!]!) {
        orders(ids: $ids) {
          id
          tasks {
            edges {
              node {
                id
                name
                sequenceNumber
              }
            }
          }
        }
      }
    `);

    const { data, loading } = await client.query({
      query,
      variables: {
        ids: orderIds,
      },
      fetchPolicy: 'no-cache',
    });

    return {
      data,
      loading,
    };
  };

  const rescheduleEntities = async (
    vars: RescheduleEntitiesVariables,
    createCommit = true,
    context?: ProjectChangeEventContext,
  ) => {
    const newVars = {
      ...vars,
      orders: vars.orders?.map((o) =>
        useOrderStoreV2().injectWorkingTimeDuration(o, vars.calendars, vars.pauses),
      ),
    };
    const event = createProjectChangeRescheduleNodesEvent(newVars, context);
    const commit = createUndoRedoRescheduleScheduleNodesCommit(
      newVars,
      useOrderStoreV2().orders,
      useMilestoneStoreV2().milestones,
      usePauseStoreV2().pauses,
      useCalendarStore().calendars,
    );
    return pushLocalChangeEventAndCommit(event, commit, createCommit, {
      dependencies: useOrderDependencyStoreV2().copyState(),
      orders: useOrderStoreV2().copyState(),
      milestones: useMilestoneStoreV2().copyState(),
      sendElementContext: vars.pauses && vars.pauses?.length > 0,
      injectWorkingTimeDuration: (o) => useOrderStoreV2().injectWorkingTimeDuration(o),
    });
  };

  const removeEntities = async (
    vars: RemoveEntitiesVariables,
    createCommit = true,
    context?: ProjectChangeEventContext,
  ) => {
    const event = createProjectChangeRemoveNodesEvent(vars, context);
    const commit = createUndoRedoRemoveNodesCommit(vars);
    return pushLocalChangeEventAndCommit(event, commit, createCommit, {
      dependencies: useOrderDependencyStoreV2().copyState(),
      orders: useOrderStoreV2().copyState(),
      milestones: useMilestoneStoreV2().copyState(),
      sendElementContext: vars.pauses && vars.pauses?.length > 0,
      injectWorkingTimeDuration: (o) => useOrderStoreV2().injectWorkingTimeDuration(o),
    });
  };

  const restoreEntities = async (
    vars: RestoreEntitiesVariables,
    createCommit = true,
    context?: ProjectChangeEventContext,
  ) => {
    const restoredAdjacentDependencies = vars.orders
      ?.flatMap((order) => {
        const deletedDependencies =
          useOrderStoreV2().getSoftDeletedEntity(order.id)?.dependencies ?? [];
        return deletedDependencies
          .map((dependencyId) => useOrderDependencyStoreV2().dependencies.get(dependencyId))
          .filter(Boolean) as OrderDependencyEntity[];
      })
      .concat(
        vars.milestones?.flatMap((order) => {
          const deletedDependencies =
            useMilestoneStoreV2().getSoftDeletedEntity(order.id)?.dependencies ?? [];
          return deletedDependencies
            .map((dependencyId) => useOrderDependencyStoreV2().dependencies.get(dependencyId))
            .filter(Boolean) as OrderDependencyEntity[];
        }) ?? [],
      );

    const event = createProjectChangeRestoreNodesEvent(vars, context, restoredAdjacentDependencies);
    const commit = createUndoRedoRestoreNodesCommit(vars);
    return pushLocalChangeEventAndCommit(event, commit, createCommit, {
      dependencies: useOrderDependencyStoreV2().copyState(),
      orders: useOrderStoreV2().copyState(),
      milestones: useMilestoneStoreV2().copyState(),
      sendElementContext: vars.pauses && vars.pauses?.length > 0,
    });
  };

  return { fetchOrdersTasks, rescheduleEntities, restoreEntities, removeEntities };
});
