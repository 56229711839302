export type UserWithName = Partial<{
  firstName: string | null;
  lastName: string | null;
  email: string | null;
}>;

export function useUserName(user: UserWithName | undefined | null): string {
  if (!user) return '';
  const { firstName, lastName, email } = user;
  if (!firstName && !lastName) return `${email}`;
  return `${firstName || ''} ${lastName || ''}`;
}

export function parseAvatarPlaceholder(user: UserWithName | undefined | null): string {
  return `${user?.firstName?.[0] || ''}${user?.lastName?.[0] || ''}`;
}
