import {
  DependencyType,
  generateOrdersFromTradeSequence,
  IdMapping,
  Order,
  OrderSchedulingEngine,
  OrderTaskTemplate,
  syncDerivedOrdersWithTradeSequence,
} from '@koppla-tech/scheduling-engine';
import { OperationNames, Operations } from 'events.schema';

import {
  CalendarEntity,
  Entity,
  InteractiveEntities,
  InteractiveEntityChanges,
  InteractiveEntityMaps,
  InteractiveEntityStores,
  MilestoneEntity,
  OrderDependencyEntity,
  OrderEntity,
  PartialEntity,
  PartialInteractiveEntities,
  PauseEntity,
  TradeSequenceEntity,
  WbsSectionEntity,
} from '@/common/types';
import {
  convertCalendarCreateOperationInputToChanges,
  convertCalendarUpdateOperationInputToChanges,
  toCalendarEntity,
} from '@/features/calendars/calendarUtils';
import { convertMilestoneUpdateOperationInputToPartialMilestoneEntity } from '@/features/milestones/milestoneUtils';
import { MilestoneType } from '@/features/milestones/types';
import {
  convertOrderDependencyCreateEventInputIntoOrderDependencyEntity,
  convertOrderDependencyUpdateEventInputIntoOrderDependencyEntity,
  getDependentEntities,
} from '@/features/orderDependencies/orderDependencyUtils';
import {
  convertOrderCopyOperationInputToEntities,
  convertOrderUpdateOperationInputToPartialOrderEntity,
} from '@/features/orders/orderUtils';
import {
  convertPauseCreateOperationInputToPauseEntity,
  convertPauseUpdateOperationInputToPartialPauseEntity,
} from '@/features/pauses/pauseUtils';
import {
  convertSectionCreateOperationInputToChanges,
  convertSectionDeleteOperationInputToChanges,
  convertSectionIndentOperationInputToChanges,
  convertSectionOutdentOperationInputToChanges,
  convertSectionUpdateOperationInputToChanges,
} from '@/features/projectStructure/utils/sectionOperationsToChangesConversion';
import { defaultHoursPerDay } from '@/helpers/leanProjects/config';
import { convertOrderStatusToStatusReport, StatusReport } from '@/helpers/orders/status';
import { mergeArrays } from '@/helpers/utils/arrays';
import { omitKeys, pickKeys } from '@/helpers/utils/objects';
import { MINUTES_PER_DAY, MINUTES_PER_HOUR } from '@/helpers/utils/timeConstants';
import { NodeName, toGlobalId } from '@/repositories/utils/cache';
import { useUserStore } from '@/services/store/user';

import { useLiveUsers } from '../components/liveUsers/useLiveUsers';
import {
  LocalProjectChangeEvent,
  LocalProjectChangeEventTemplate,
  ProjectChangeEventContext,
  RemoteProjectChangeEvent,
  RescheduledDependency,
} from '../types';
import { RestoredEntityNotFoundError } from './errors';
import { mapInteractiveChangesToSENG } from './mapper';

export function getChangesFromEvent(
  event: LocalProjectChangeEvent | RemoteProjectChangeEvent | LocalProjectChangeEventTemplate,
  engine: OrderSchedulingEngine,
  entityStores: InteractiveEntityStores,
  entityStates: InteractiveEntityMaps,
  stateless = false,
  operationsToIgnore: Operations['name'][] = [],
): {
  changes: InteractiveEntityChanges;
  idMappings: IdMapping[] | undefined;
  rescheduledDependencies: RescheduledDependency[];
} {
  if (operationsToIgnore.includes(event.operation.name)) {
    return { changes: {}, idMappings: undefined, rescheduledDependencies: [] };
  }

  let changes = getDirectChangesFromEvent(engine, event, entityStores, entityStates);
  const { context } = event.operation;
  let idMappings: IdMapping[] | undefined;
  let rescheduledDependencies: RescheduledDependency[] = [];

  if (event.operation.name === OperationNames.RestoreScheduleNodes) {
    changes = handleRestoreEvent(event.restoredEntities, entityStores);
  }

  if (event.operation.name === OperationNames.InsertTradeSequence) {
    const currentTradeSequence = entityStates.tradeSequences?.get(
      event.operation.input.tradeSequenceId,
    );
    if (!currentTradeSequence) {
      throw new Error('Could not find to be inserted trade sequence in the store');
    }

    const { changes: schedulingChanges, idMapping: generatedIdMapping } =
      generateOrdersFromTradeSequence(
        engine,
        {
          ...currentTradeSequence,
          dependencies: currentTradeSequence.dependencies.map((dependency) => ({
            ...dependency,
            type: dependency.type as DependencyType,
          })),
        },
        {
          date: new SchedulingDate(event.operation.input.startAt),
          sectionId: event.operation.input.wbsSectionId,
          tradeSequenceInstanceId: event.operation.input.instanceId,
          idMapping: {
            tradeSequenceInstanceId: event.operation.input.instanceId,
            activityIdToOrderId: event.operation.input.idMapping.activityToOrderId,
            orderIdsToOrderDependencyId: event.operation.input.idMapping.dependencies,
          },
        },
        Array.from(entityStores.projectSubcontractorStore().projectSubcontractors.values()),
        stateless,
      );
    changes = unionizeChanges(schedulingChanges as InteractiveEntityChanges, changes);
    changes = encodeIdsOfNewOrdersAndDependencies(engine, changes, stateless);
    idMappings = [generatedIdMapping];
  } else if (event.operation.name === OperationNames.UpdateTradeSequence) {
    const currentTradeSequence = entityStates.tradeSequences?.get(event.operation.input.id);
    if (!currentTradeSequence) {
      throw new Error('Could not find to be synced trade sequence in the store');
    }

    const { changes: schedulingChanges, idMappings: syncedIdMappings } =
      syncDerivedOrdersWithTradeSequence(
        engine,
        {
          ...event.operation.input,
          calendar: { id: event.operation.input.calendarId },
          activities: event.operation.input.activities.map((activity) => ({
            ...omitKeys(activity, ['tradeId']),
            taskTemplates: (activity.taskTemplates?.filter((template) => template.id) ??
              []) as OrderTaskTemplate[],
            tenantTradeVariation: { id: activity.tradeId },
          })),
          dependencies: event.operation.input.dependencies.map((dependency) => ({
            ...omitKeys(dependency, ['fromActivityId', 'toActivityId']),
            type: dependency.type as DependencyType,
            from: { id: dependency.fromActivityId },
            to: { id: dependency.toActivityId },
          })),
        },
        {
          ...currentTradeSequence,
          dependencies: currentTradeSequence.dependencies.map((dependency) => ({
            ...dependency,
            type: dependency.type as DependencyType,
          })),
        },
        {
          idMappings: event.operation.input.idMappings.map((idMapping) => ({
            tradeSequenceInstanceId: idMapping.tradeSequenceInstanceId,
            activityIdToOrderId: idMapping.activityToOrderId,
            orderIdsToOrderDependencyId: idMapping.dependencies,
          })),
          dependencies: context?.dependencies as PartialEntity<OrderDependencyEntity>[],
        },
        Array.from(entityStores.projectSubcontractorStore().projectSubcontractors.values()),
        undefined,
        stateless,
      );
    changes = unionizeChanges(schedulingChanges as InteractiveEntityChanges, changes);
    changes = encodeIdsOfNewOrdersAndDependencies(engine, changes, stateless);
    idMappings = syncedIdMappings;
  } else if (changesNeedRescheduling(changes)) {
    const sanitizedChanges = sanitizeSchedulingChanges(changes, context);
    const { changes: schedulingChanges } = engine.schedule(
      mapInteractiveChangesToSENG(sanitizedChanges),
      {
        stateless,
      },
    );

    rescheduledDependencies = findRelevantImplicitlyRescheduledDependencies(
      entityStores,
      sanitizedChanges,
      schedulingChanges as InteractiveEntityChanges,
    );

    changes = mergeChanges(changes, schedulingChanges as InteractiveEntityChanges);
  }

  return { changes, idMappings, rescheduledDependencies };
}

/**
 * We want to show a lag updated notification for all dependencies where the lag has been changed
 * implicitly by the scheduling engine. However, it should only be shown for dependencies where
 * also the successor was changed manually by the user.
 */
function findRelevantImplicitlyRescheduledDependencies(
  entityStores: InteractiveEntityStores,
  initialChanges: InteractiveEntityChanges,
  changesAfterScheduling: InteractiveEntityChanges,
): RescheduledDependency[] {
  const initialDependencyChangesMap = new Map<string, PartialEntity<OrderDependencyEntity>>(
    (initialChanges.update?.dependencies ?? []).map((dependency) => [dependency.id, dependency]),
  );
  const initialOrderChangesMap = new Map<string, PartialEntity<OrderEntity>>(
    (initialChanges.update?.orders ?? []).map((order) => [order.id, order]),
  );
  const initialMilestoneChangesMap = new Map<string, PartialEntity<MilestoneEntity>>(
    (initialChanges.update?.milestones ?? []).map((milestone) => [milestone.id, milestone]),
  );

  const implicitlyUpdatedDependencies =
    changesAfterScheduling.update?.dependencies?.filter(
      (dependency) =>
        dependency.lagInMinutes !== undefined &&
        initialDependencyChangesMap.get(dependency.id)?.lagInMinutes === undefined,
    ) ?? [];

  const relevantImplicitlyUpdatedDependencies = implicitlyUpdatedDependencies
    .map((dependency) => {
      const { from, fromType, to, toType } = getDependentEntities(entityStores, {
        dependencyOrId: dependency.id,
      });

      return {
        id: dependency.id,
        lag: dependency.lagInMinutes!,
        from,
        fromType: fromType ?? '',
        to,
        toType: toType ?? '',
      };
    })
    .filter((dependency) => {
      if (!dependency.to) return false;
      const maybeOrder = initialOrderChangesMap.get(dependency.to.id);
      if (maybeOrder) {
        return maybeOrder.startAt !== undefined || maybeOrder.finishAt !== undefined;
      }
      const maybeMilestone = initialMilestoneChangesMap.get(dependency.to.id);
      if (maybeMilestone) {
        return maybeMilestone.date !== undefined;
      }
      return false;
    });

  return relevantImplicitlyUpdatedDependencies.map((dependency) => ({
    lagInMinutes: dependency.lag,
    fromName: dependency.from?.name ?? '',
    fromType: dependency.fromType,
    toName: dependency.to?.name ?? '',
    toType: dependency.toType,
  }));
}

export function sanitizeSchedulingChanges(
  changes: InteractiveEntityChanges,
  context?: ProjectChangeEventContext,
): InteractiveEntityChanges {
  const schedulingRelatedKeys: (keyof InteractiveEntities)[] = [
    'pauses',
    'orders',
    'milestones',
    'calendars',
    'dependencies',
  ];
  return {
    ...(changes.add
      ? {
          add: pickKeys(changes.add, schedulingRelatedKeys),
        }
      : {}),
    update: pickKeys(mergeEventContext(changes.update, context)!, schedulingRelatedKeys),
    ...(changes.delete
      ? {
          delete: pickKeys(changes.delete, schedulingRelatedKeys),
        }
      : {}),
  };
}

/**
 * When generating new orders and dependencies using the generateOrdersFromTradeSequence and syncDerivedOrdersWithTradeSequence functions,
 * the ids returned for these orders and dependencies are raw UUIDs. However, for subsequent operations, we need to encode them as graphql ids.
 */
function encodeIdsOfNewOrdersAndDependencies(
  engine: OrderSchedulingEngine,
  changes: InteractiveEntityChanges,
  stateless: boolean,
): InteractiveEntityChanges {
  const encodedChanges: InteractiveEntityChanges = { ...changes };
  const newOrderIds = new Set<string>(encodedChanges.add?.orders?.map((o) => o.id) ?? []);
  const newDependencyIds = new Set<string>(
    encodedChanges.add?.dependencies?.map((d) => d.id) ?? [],
  );

  const enhanceOrderWithMissingAttributes = (order: Order): OrderEntity => {
    return {
      ...order,
      finishedAt: null,
      progress: 0,
      status: StatusReport.NOT_SET,
    };
  };

  if (encodedChanges.add?.orders) {
    const encodedIds = Array.from(newOrderIds).map((id) => toGlobalId(NodeName.ORDER, id));
    encodedChanges.add.orders = encodedChanges.add.orders.map((o, idx) => ({
      ...enhanceOrderWithMissingAttributes(o as unknown as Order),
      id: encodedIds[idx],
    }));
    if (!stateless) {
      engine.amend(
        {
          orders: Array.from(newOrderIds),
        },
        {
          orders: encodedIds.map((id) => ({ id })),
        },
      );
    }
  }

  if (encodedChanges.add?.dependencies) {
    const encodedIds = encodedChanges.add.dependencies.map((d) => ({
      id: toGlobalId(NodeName.ORDER_DEPENDENCY, d.id),
      from: newOrderIds.has(d.from.id) ? toGlobalId(NodeName.ORDER, d.from.id) : d.from.id,
      to: newOrderIds.has(d.to.id) ? toGlobalId(NodeName.ORDER, d.to.id) : d.to.id,
    }));
    encodedChanges.add.dependencies = encodedChanges.add.dependencies.map((o, idx) => ({
      ...o,
      id: encodedIds[idx].id,
      from: {
        id: encodedIds[idx].from,
      },
      to: {
        id: encodedIds[idx].to,
      },
    }));
    if (!stateless) {
      engine.amend(
        {
          dependencies: Array.from(newDependencyIds),
        },
        {
          dependencies: encodedIds.map((encoded) => ({
            id: encoded.id,
            from: {
              id: encoded.from,
            },
            to: {
              id: encoded.to,
            },
          })),
        },
      );
    }
  }
  return encodedChanges;
}

function mergeChanges(
  changesA: InteractiveEntityChanges,
  changesB: InteractiveEntityChanges,
): InteractiveEntityChanges {
  return {
    add: {
      ...changesA.add,
      ...changesB.add,
    },
    update: {
      ...changesA.update,
      ...changesB.update,
    },
    delete: {
      ...changesA.delete,
      ...changesB.delete,
    },
  };
}

function mergeEventContext(
  changes: InteractiveEntityChanges['update'],
  context?: ProjectChangeEventContext,
): InteractiveEntityChanges['update'] {
  const contextOrders = (context?.orders ?? []).map((order) => ({
    ...order,
    startAt: order.startAt ? new SchedulingDate(order.startAt) : undefined,
    finishAt: order.finishAt ? new SchedulingDate(order.finishAt) : undefined,
  }));
  const contextMilestones = (context?.milestones ?? []).map((milestone) => ({
    ...milestone,
    date: milestone.date ? new SchedulingDate(milestone.date) : undefined,
  }));
  return {
    ...changes,
    orders: mergeArrays(changes?.orders ?? [], contextOrders as PartialEntity<OrderEntity>[]),
    milestones: mergeArrays(
      changes?.milestones ?? [],
      contextMilestones as PartialEntity<MilestoneEntity>[],
    ),
    dependencies: mergeArrays(
      changes?.dependencies ?? [],
      (context?.dependencies ?? []) as PartialEntity<OrderDependencyEntity>[],
    ),
  };
}

function isRemoteProjectChangeEvent(
  event: LocalProjectChangeEvent | RemoteProjectChangeEvent | LocalProjectChangeEventTemplate,
): event is RemoteProjectChangeEvent {
  return event && 'user' in event && typeof event.user !== 'undefined';
}

function getCreatedByInformation(
  event: LocalProjectChangeEvent | RemoteProjectChangeEvent | LocalProjectChangeEventTemplate,
) {
  const createdBy = { id: '', email: '', firstName: '', lastName: '' };
  const createdByTenant = { id: '', name: '' };
  const userStore = useUserStore();

  const { users } = useLiveUsers();

  if (isRemoteProjectChangeEvent(event)) {
    if (event.user) {
      createdBy.firstName = event.user.firstName;
      createdBy.lastName = event.user.lastName;
      createdBy.id = event.user.id;
    }

    const tenant = users.value.find((u) => u.id === event.user?.id)?.tenant;

    if (tenant) {
      createdByTenant.id = tenant.id;
      createdByTenant.name = tenant.name;
    }
  } else {
    const ownUser = users.value.find((user) => user.id === userStore.ownUser?.id);

    if (ownUser) {
      createdBy.id = ownUser.id;
      createdBy.firstName = ownUser.firstName;
      createdBy.lastName = ownUser.lastName;
      createdByTenant.id = ownUser.tenant.id;
      createdByTenant.name = ownUser.tenant.name;
    }
  }

  return { createdBy, createdByTenant };
}

function getDirectChangesFromEvent(
  engine: OrderSchedulingEngine,
  event: LocalProjectChangeEvent | RemoteProjectChangeEvent | LocalProjectChangeEventTemplate,
  entityStores: InteractiveEntityStores,
  entityStates: InteractiveEntityMaps,
): InteractiveEntityChanges {
  if (event.operation.name === OperationNames.RemoveScheduleNodes) {
    const sectionDeletionChanges = convertSectionDeleteOperationInputToChanges(
      event.operation.input,
      entityStates,
    );
    const orderDeletionChanges = event.operation.input.orders?.map((id) => ({ id }));
    const milestoneDeletionChanges = event.operation.input.milestones?.map((id) => ({ id }));
    return {
      delete: {
        pauses: event.operation.input.pauses?.map((id) => ({ id })),
        orders: orderDeletionChanges
          ? [...orderDeletionChanges, ...(sectionDeletionChanges?.orders ?? [])]
          : sectionDeletionChanges?.orders,
        milestones: milestoneDeletionChanges
          ? [...milestoneDeletionChanges, ...(sectionDeletionChanges?.milestones ?? [])]
          : sectionDeletionChanges?.milestones,
        calendars: event.operation.input.calendars?.map((id) => ({ id })),
        dependencies: event.operation.input.dependencies?.map((id) => ({ id })),
        wbsSections: sectionDeletionChanges?.wbsSections,
        tradeSequences: event.operation.input.tradeSequences?.map((id) => ({ id })),
      },
    };
  }

  if (event.operation.name === OperationNames.CreatePauses) {
    return {
      add: {
        pauses: convertPauseCreateOperationInputToPauseEntity(event.operation.input),
      },
    };
  }
  if (event.operation.name === OperationNames.UpdatePauses) {
    return {
      update: {
        pauses: convertPauseUpdateOperationInputToPartialPauseEntity(event.operation.input),
      },
    };
  }
  if (event.operation.name === OperationNames.CreateCalendars) {
    return convertCalendarCreateOperationInputToChanges(
      event.operation.input,
      entityStates.calendars!,
    );
  }
  if (event.operation.name === OperationNames.UpdateCalendars) {
    return convertCalendarUpdateOperationInputToChanges(
      event.operation.input,
      entityStates.calendars!,
    );
  }
  if (event.operation.name === OperationNames.CreateWBSSections) {
    const restoredEntities = handleRestoreEvent(event.restoredEntities, entityStores);

    const changes = convertSectionCreateOperationInputToChanges(
      event.operation.input,
      entityStates,
    );
    return {
      add: { ...(restoredEntities.add ?? {}), ...(changes.add ?? {}) },
      update: changes.update,
    };
  }
  if (event.operation.name === OperationNames.UpdateWBSSection) {
    return {
      update: convertSectionUpdateOperationInputToChanges(event.operation.input, entityStates),
    };
  }
  if (event.operation.name === OperationNames.IndentWBSSection) {
    return convertSectionIndentOperationInputToChanges(event.operation.input, entityStates);
  }
  if (event.operation.name === OperationNames.OutdentWBSSection) {
    return convertSectionOutdentOperationInputToChanges(event.operation.input, entityStates);
  }
  if (event.operation.name === OperationNames.CreateOrders) {
    return {
      add: {
        orders: event.operation.input.map((o) => {
          const startAt = new SchedulingDate(o.startAt);
          const finishAt = new SchedulingDate(o.finishAt);
          return {
            __typename: NodeName.ORDER,
            id: o.id,
            name: o.name,
            startAt,
            finishAt,
            finishedAt: null,
            status: StatusReport.NOT_SET,
            progress: 0,
            wbsSection: { id: o.wbsSectionId },
            tenantTradeVariation: { id: o.tradeId },
            subcontractor: o.subcontractorId ? { id: o.subcontractorId } : null,
            calendar: { id: o.calendarId },
            isFixed: o.isFixed ?? false,
            /**
             * Duration is not set for requests coming from the HTTP API.
             * This can lead to state divergence if the calendar changed.
             * We accept this for now because calendar changes and API requests are rare.
             */
            duration:
              o.workingTimeDuration ??
              engine.computeWorkingTimeBetween(finishAt, startAt, o.calendarId),
            dryingBreak: o.dryingBreak,
          };
        }),
      },
    };
  }
  if (event.operation.name === OperationNames.UpdateOrders) {
    return {
      update: {
        orders: convertOrderUpdateOperationInputToPartialOrderEntity(event.operation.input),
      },
    };
  }
  if (event.operation.name === OperationNames.CopyOrders) {
    return {
      add: convertOrderCopyOperationInputToEntities(event.operation.input),
    };
  }
  if (event.operation.name === OperationNames.CreateOrderStatusReports) {
    const { createdBy, createdByTenant } = getCreatedByInformation(event);

    return {
      add: {
        orderStatus: event.operation.input.map((orderCreateStatusInput) => ({
          id: orderCreateStatusInput.id,
          orderId: orderCreateStatusInput.orderId,
          status: convertOrderStatusToStatusReport(orderCreateStatusInput.status),
          progress: orderCreateStatusInput.progress,
          reason: orderCreateStatusInput.reason,
          comment: orderCreateStatusInput.comment,
          reportedAt: new Date(orderCreateStatusInput.reportedAt),
          createdBy,
          createdByTenant,
        })),
      },
      update: {
        orders: event.operation.input.map((orderCreateStatusInput) => ({
          id: orderCreateStatusInput.orderId,
          status: convertOrderStatusToStatusReport(orderCreateStatusInput.status),
          progress: orderCreateStatusInput.progress,
        })),
      },
    };
  }
  if (event.operation.name === OperationNames.CreateTradeSequence) {
    const orderAssignments = event.operation.input.orderAssignments;
    return {
      add: {
        tradeSequences: [
          {
            ...event.operation.input,
            calendar: { id: event.operation.input.calendarId },
            activities: event.operation.input.activities.map((activity) => ({
              ...activity,
              tenantTradeVariation: { id: activity.tradeId },
              taskTemplates: (activity.taskTemplates?.filter((template) => template.id) ??
                []) as OrderTaskTemplate[],
            })),
            dependencies: event.operation.input.dependencies.map((dependency) => ({
              ...dependency,
              type: dependency.type as DependencyType,
              from: { id: dependency.fromActivityId },
              to: { id: dependency.toActivityId },
            })),
          },
        ],
      },
      // NOTE: NO_LINK_CREATED_BETWEEN_PREFILLED_SEQUENCE_AND_ORDERS - we added it but it may not be integrated correctly yet
      update: {
        orders:
          orderAssignments?.assignments.map((assignment) => {
            return {
              id: assignment.orderId,
              tradeSequenceActivity: { id: assignment.activityId },
              tradeSequenceInstanceId: orderAssignments.tradeSequenceInstanceId,
            };
          }) ?? [],
      },
    };
  }
  if (event.operation.name === OperationNames.UpdateTradeSequence) {
    return {
      update: {
        tradeSequences: [
          {
            ...omitKeys(event.operation.input, ['idMappings']),
            calendar: { id: event.operation.input.calendarId },
            activities: event.operation.input.activities.map((activity) => ({
              ...omitKeys(activity, ['tradeId']),
              tenantTradeVariation: { id: activity.tradeId },
              taskTemplates: (activity.taskTemplates?.filter((template) => template.id) ??
                []) as OrderTaskTemplate[],
            })),
            dependencies: event.operation.input.dependencies.map((dependency) => ({
              ...omitKeys(dependency, ['fromActivityId', 'toActivityId']),
              type: dependency.type as DependencyType,
              from: { id: dependency.fromActivityId },
              to: { id: dependency.toActivityId },
            })),
          },
        ],
      },
    };
  }
  if (event.operation.name === OperationNames.CreateDependencies) {
    const convertedDependencies = convertOrderDependencyCreateEventInputIntoOrderDependencyEntity(
      event.operation.input,
    );

    convertedDependencies.forEach((dependency) => {
      const { from, to } = getDependentEntities(entityStores, { dependencyOrId: dependency });
      if (!from) {
        entityStores.orderStore().setSoftDeletedEntity(dependency.from.id, dependency.id);
        entityStores.milestoneStore().setSoftDeletedEntity(dependency.from.id, dependency.id);
      }
      if (!to) {
        entityStores.orderStore().setSoftDeletedEntity(dependency.to.id, dependency.id);
        entityStores.milestoneStore().setSoftDeletedEntity(dependency.to.id, dependency.id);
      }
    });

    return {
      add: {
        dependencies: convertedDependencies,
      },
    };
  }
  if (event.operation.name === OperationNames.UpdateDependencies) {
    return {
      update: {
        dependencies: convertOrderDependencyUpdateEventInputIntoOrderDependencyEntity(
          event.operation.input,
        ),
      },
    };
  }
  if (event.operation.name === OperationNames.CreateMilestones) {
    return {
      add: {
        milestones: event.operation.input.map((m) => ({
          ...m,
          date: new SchedulingDate(m.date),
          wbsSection: m.wbsSectionId ? { id: m.wbsSectionId } : null,
          isFixed: m.type === MilestoneType.FIXED,
          type: m.type as MilestoneType,
          acceptanceCriteria: m.criteria ?? [],
          completedAt: null,
        })),
      },
    };
  }
  if (event.operation.name === OperationNames.UpdateMilestones) {
    return {
      update: {
        milestones: convertMilestoneUpdateOperationInputToPartialMilestoneEntity(
          event.operation.input,
        ),
      },
    };
  }
  if (event.operation.name === OperationNames.UpdateMilestoneStatus) {
    return {
      update: {
        milestones: convertMilestoneUpdateOperationInputToPartialMilestoneEntity([
          {
            id: event.operation.input.milestoneId,
            completedAt: event.operation.input.completedAt,
          },
        ]),
      },
    };
  }
  if (event.operation.name === OperationNames.RescheduleScheduleNodes) {
    return {
      update: {
        ...(event.operation.input.pauses
          ? {
              pauses: convertPauseUpdateOperationInputToPartialPauseEntity(
                event.operation.input.pauses,
              ),
            }
          : {}),
        ...(event.operation.input.orders
          ? {
              orders: convertOrderUpdateOperationInputToPartialOrderEntity(
                event.operation.input.orders,
              ),
            }
          : {}),
        ...(event.operation.input.milestones
          ? {
              milestones: convertMilestoneUpdateOperationInputToPartialMilestoneEntity(
                event.operation.input.milestones,
              ),
            }
          : {}),
        ...(event.operation.input.calendars
          ? convertCalendarUpdateOperationInputToChanges(
              event.operation.input.calendars,
              entityStates.calendars!,
            ).update
          : {}),
      },
    };
  }
  if (event.operation.name === OperationNames.UpdateProjectStatus) {
    const currentProjectId = entityStores.projectStore().currentProject?.id;
    if (!currentProjectId) return {};
    return {
      update: {
        projects: [
          {
            id: currentProjectId,
            completed: event.operation.input.status === 'COMPLETED',
          },
        ],
      },
    };
  }
  if (event.operation.name === OperationNames.CompleteProjectSetup) {
    const currentProjectId = entityStores.projectStore().currentProject?.id;
    if (!currentProjectId) return {};
    return {
      add: {
        calendars: [
          toCalendarEntity({
            ...event.operation.input.calendar,
            minutesPerDay: event.operation.input.hourlyPlanningEnabled
              ? defaultHoursPerDay * MINUTES_PER_HOUR
              : MINUTES_PER_DAY,
            isDefault: true,
          }),
        ],
        wbsSections: event.operation.input.sections,
      },
      update: {
        projects: [
          {
            id: currentProjectId,
            hourlyPlanningEnabled: event.operation.input.hourlyPlanningEnabled,
            status: 'ACTIVE',
          },
        ],
      },
    };
  }
  if (event.operation.name === OperationNames.DeleteProjectAlternative) {
    return {
      delete: {
        projectAlternatives: [
          {
            id: event.operation.input.alternativeId,
          },
        ],
      },
    };
  }

  return {};
}

/**
 * This function takes two sets of changes and returns a new set of changes that is the union of the two, where the changes from the second set take precedence over the changes from the first set.
 * @param changesA
 * @param changesB
 */
export function unionizeChanges(
  changesA: InteractiveEntityChanges,
  changesB: InteractiveEntityChanges,
): InteractiveEntityChanges {
  const unionizedChanges: InteractiveEntityChanges = { ...changesA };

  Object.keys(changesB).forEach((type) => {
    if (!unionizedChanges[type]) {
      unionizedChanges[type] = changesB[type];
    } else {
      unionizedChanges[type] = Object.keys(changesB[type]).reduce<PartialInteractiveEntities>(
        (acc, entity) => {
          if (!acc[entity]) {
            acc[entity] = changesB[type][entity];
          } else {
            acc[entity] = mergeEntityChanges(acc[entity], changesB[type][entity]);
          }
          return acc;
        },
        unionizedChanges[type] || {},
      );
    }
  });

  return unionizedChanges;
}

function mergeEntityChanges(entitiesA: Entity[], entitiesB: Entity[]): Entity[] {
  const merged: Entity[] = entitiesA.slice();
  const entitiesAMap = new Map(entitiesA.map((entity) => [entity.id, entity]));
  const entitiesAIndicesMap = new Map(entitiesA.map((entity, index) => [entity.id, index]));

  entitiesB.forEach((entityB) => {
    const entityA = entitiesAMap.get(entityB.id);
    const entityAIndex = entitiesAIndicesMap.get(entityB.id);
    if (entityA && entityAIndex !== undefined) {
      merged[entityAIndex] = {
        ...entityA,
        ...entityB,
      };
      return;
    }
    merged.push(entityB);
  });

  return merged;
}

export function distributeChangesToStores(
  entityStores: InteractiveEntityStores,
  changes: InteractiveEntityChanges,
): void {
  entityStores.pauseStore().applyChanges({
    add: changes.add?.pauses,
    update: changes.update?.pauses,
    delete: changes.delete?.pauses,
  });
  entityStores.wbsSectionStore().applyChanges(
    {
      add: changes.add?.wbsSections,
      update: changes.update?.wbsSections,
      delete: changes.delete?.wbsSections,
    },
    entityStores.orderStore().copyState(),
    entityStores.milestoneStore().copyState(),
  );
  entityStores.orderStore().applyChanges({
    add: changes.add?.orders,
    update: changes.update?.orders,
    delete: changes.delete?.orders,
    addStatus: changes.add?.orderStatus,
  });
  entityStores.calendarStore().applyChanges({
    add: changes.add?.calendars,
    update: changes.update?.calendars,
    delete: changes.delete?.calendars,
  });
  entityStores.projectTradeSequenceStore().applyChanges({
    add: changes.add?.tradeSequences,
    update: changes.update?.tradeSequences,
    delete: changes.delete?.tradeSequences,
  });
  entityStores.milestoneStore().applyChanges({
    add: changes.add?.milestones,
    update: changes.update?.milestones,
    delete: changes.delete?.milestones,
  });
  entityStores.orderDependencyStore().applyChanges({
    add: changes.add?.dependencies,
    update: changes.update?.dependencies,
    delete: changes.delete?.dependencies,
  });
  entityStores.projectStore().applyChanges({
    add: changes.add?.projects,
    update: changes.update?.projects,
    delete: changes.delete?.projects,
  });
}

export function changesNeedRescheduling(changes: InteractiveEntityChanges): boolean {
  return (
    !!changes.add?.orders?.length ||
    !!changes.update?.orders?.length ||
    !!changes.delete?.orders?.length ||
    !!changes.add?.milestones?.length ||
    !!changes.update?.milestones?.length ||
    !!changes.delete?.milestones?.length ||
    !!changes.add?.calendars?.length ||
    !!changes.update?.calendars?.length ||
    !!changes.delete?.calendars?.length ||
    !!changes.add?.pauses?.length ||
    !!changes.update?.pauses?.length ||
    !!changes.delete?.pauses?.length ||
    !!changes.add?.dependencies?.length ||
    !!changes.update?.dependencies?.length ||
    !!changes.delete?.dependencies?.length
  );
}

function handleRestoreEvent(
  restoredEntities: RemoteProjectChangeEvent['restoredEntities'],
  entityStores: InteractiveEntityStores,
): InteractiveEntityChanges {
  const restoredPauses =
    restoredEntities?.pauses?.map((id) => entityStores.pauseStore().getSoftDeletedEntity(id)) ?? [];

  const unresolvedPauseIdx = restoredPauses.findIndex((pause) => !pause);
  if (unresolvedPauseIdx !== -1) {
    throw new RestoredEntityNotFoundError('pause', restoredEntities!.pauses![unresolvedPauseIdx]);
  }

  const restoredOrders =
    restoredEntities?.orders?.map(
      (id) => entityStores.orderStore().getSoftDeletedEntity(id)?.order,
    ) ?? [];

  const unresolvedOrderIdx = restoredOrders.findIndex((order) => !order);
  if (unresolvedOrderIdx !== -1) {
    throw new RestoredEntityNotFoundError('order', restoredEntities!.orders![unresolvedOrderIdx]);
  }

  const restoredMilestones =
    restoredEntities?.milestones?.map(
      (id) => entityStores.milestoneStore().getSoftDeletedEntity(id)?.milestone,
    ) ?? [];

  const unresolvedMilestoneIdx = restoredMilestones.findIndex((milestone) => !milestone);
  if (unresolvedMilestoneIdx !== -1) {
    throw new RestoredEntityNotFoundError(
      'milestone',
      restoredEntities!.milestones![unresolvedMilestoneIdx],
    );
  }

  const restoredCalendars =
    restoredEntities?.calendars?.map((id) =>
      entityStores.calendarStore().getSoftDeletedEntity(id),
    ) ?? [];
  const unresolvedCalendarIdx = restoredCalendars.findIndex((calendar) => !calendar);
  if (unresolvedCalendarIdx !== -1) {
    throw new RestoredEntityNotFoundError(
      'calendar',
      restoredEntities!.calendars![unresolvedCalendarIdx],
    );
  }

  const restoredDependencies: (OrderDependencyEntity | undefined)[] = [];
  const restoredDependencyIds = new Set();

  restoredEntities?.dependencyDetails?.forEach((dependencyDetail) => {
    if (restoredDependencyIds.has(dependencyDetail.id)) {
      return;
    }
    restoredDependencyIds.add(dependencyDetail.id);
    restoredDependencies.push({
      ...dependencyDetail,
      from: {
        id: dependencyDetail.from.orderId ?? dependencyDetail.from.milestoneId ?? '',
      },
      to: {
        id: dependencyDetail.to.orderId ?? dependencyDetail.to.milestoneId ?? '',
      },
      type: dependencyDetail.type as DependencyType,
    });
  });

  restoredEntities?.dependencies?.forEach((id) => {
    if (restoredDependencyIds.has(id)) {
      return;
    }
    restoredDependencies.push(entityStores.orderDependencyStore().getSoftDeletedEntity(id));
  });

  const unresolvedDependencyIdx = restoredDependencies.findIndex((dependency) => !dependency);
  if (unresolvedDependencyIdx !== -1) {
    throw new RestoredEntityNotFoundError(
      'dependency',
      restoredEntities!.dependencies![unresolvedDependencyIdx],
    );
  }

  const restoredTradeSequences =
    restoredEntities?.tradeSequences?.map((id) =>
      entityStores.projectTradeSequenceStore().getSoftDeletedEntity(id),
    ) ?? [];
  const unresolvedTradeSequenceIdx = restoredTradeSequences.findIndex(
    (tradeSequence) => !tradeSequence,
  );
  if (unresolvedTradeSequenceIdx !== -1) {
    throw new RestoredEntityNotFoundError(
      'tradeSequence',
      restoredEntities!.tradeSequences![unresolvedTradeSequenceIdx],
    );
  }

  const restoredWbsSections =
    restoredEntities?.wbsSections?.flatMap((id) =>
      entityStores.wbsSectionStore().getSoftDeletedEntities(id),
    ) ?? [];
  const unresolvedWbsSectionIdx = restoredWbsSections.findIndex((wbsSection) => !wbsSection);
  if (unresolvedWbsSectionIdx !== -1) {
    throw new RestoredEntityNotFoundError(
      'wbsSection',
      restoredEntities!.wbsSections![unresolvedWbsSectionIdx],
    );
  }

  return {
    add: {
      pauses: restoredPauses as PauseEntity[],
      orders: restoredOrders as OrderEntity[],
      milestones: restoredMilestones as MilestoneEntity[],
      calendars: restoredCalendars as CalendarEntity[],
      dependencies: restoredDependencies as OrderDependencyEntity[],
      tradeSequences: restoredTradeSequences as TradeSequenceEntity[],
      wbsSections: restoredWbsSections as WbsSectionEntity[],
    },
  };
}
