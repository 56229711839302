import { RouteLocationNormalized } from 'vue-router';

import { MembershipWithTenant } from '@/interfaces/repositories/memberships';
import { ProjectSubcontractorWorker } from '@/interfaces/repositories/projectSubcontractorWorker';
import { GuardMeta, useWithMembership } from '@/router/helpers/memberships';
import { getNoMembershipsRoute } from '@/router/helpers/redirection';

import { Routes } from '../helpers/routeNames';

export function useTenantsGuard({
  loggingService,
  membershipRepository,
  projectSubcontractorWorkerRepository,
}: GuardMeta): (to: RouteLocationNormalized, from: RouteLocationNormalized) => Promise<void> {
  return async (to): Promise<any> =>
    useWithMembership(
      { to },
      { loggingService, membershipRepository, projectSubcontractorWorkerRepository },
      (
        activeMembership: MembershipWithTenant | undefined | null,
        memberships: MembershipWithTenant[],
        activeSubcontractorWorker: ProjectSubcontractorWorker | undefined | null,
        subcontractorWorkers: readonly ProjectSubcontractorWorker[],
      ): any => {
        const activeTenantId =
          // Active membership has precedence
          activeMembership?.tenant?.id ?? activeSubcontractorWorker?.subcontractor?.id;
        if (activeTenantId) {
          return {
            name: Routes.Lean.All,
            params: { tenantId: activeTenantId },
          };
        }
        /** visit tenant picker, if multiple memberships or subcontractors exist */
        if (memberships.length || subcontractorWorkers.length) {
          return true;
        }
        /** redirect to no memberships page, if no memberships exist */
        return getNoMembershipsRoute();
      },
    );
}
