<template>
  <span
    class="ds-tag"
    :class="[
      `--${size}`,
      { '--border': isBasicTag },
      { 'tw-border tw-border-black tw-border-solid': outlined },
    ]"
    :style="{ backgroundColor: color, color: computedTextColor }"
  >
    <span class="ds-tag-slot-content"><slot /></span>

    <button v-if="action === 'close'" type="button" class="ds-tag-close" @click="$emit('close')">
      <DsIcon :style="{ color: computedTextColor }" :size="iconSize">close</DsIcon>
    </button>
  </span>
</template>

<script setup lang="ts">
import { getColorForContrast } from '@/helpers/utils/colors';

const props = defineProps({
  action: { type: String as PropType<'close' | 'count' | null>, default: null },
  size: { type: String as PropType<'sm' | 'md' | 'lg'>, default: 'md' },
  color: {
    type: String,
    default: '#FFFFFF',
  },
  textColor: {
    type: String,
    default: null,
  },
  outlined: {
    type: Boolean,
    default: false,
  },
});
defineEmits<{
  (e: 'close'): void;
}>();

const iconSize = computed(() => {
  return { sm: 10, md: 12, lg: 14 }[props.size];
});

const isBasicTag = computed(
  () => !props.color || props.color === '#FFFFFF' || props.color === 'transparent',
);

const computedTextColor = computed(() => {
  if (props.textColor) return props.textColor;
  if (isBasicTag.value) return 'var(--color-grey-500)';

  return getColorForContrast(props.color, 'var(--color-white)', 'var(--color-black)');
});
</script>

<style scoped>
.ds-tag {
  @apply tw-shrink tw-inline-flex tw-items-center tw-justify-center tw-gap-[0.1875rem];
  @apply tw-rounded-2xs tw-max-w-full;
}
.ds-tag.--sm {
  @apply tw-ds-text-xs--medium tw-h-6 tw-px-2;
}
.ds-tag-slot-content {
  @apply tw-truncate;
}
.ds-tag.--md {
  @apply tw-ds-text-sm--medium tw-h-6 tw-px-[0.5625rem];
}
.ds-tag.--lg {
  @apply tw-ds-text-sm--medium tw-h-[1.875rem] tw-px-2.5;
}

.ds-tag.--border {
  @apply tw-border tw-border-solid tw-border-grey-200;
}

.ds-tag-close {
  @apply tw-flex-none tw-flex tw-items-center tw-justify-center;
  @apply tw-p-0.5 tw-rounded-[0.1875rem] tw-outline-none tw-cursor-pointer;
  @apply hover:tw-bg-[rgba(0,0,0,0.1)];
  @apply focus-visible:tw-ring focus-visible:tw-ring-[rgba(0,0,0,0.1)];
  @apply active:tw-bg-transparent;
}
.ds-tag.--lg .ds-tag-close {
  @apply tw-p-[0.1875rem];
}
</style>
