// GENERATED FILE, DO NOT EDIT
export const IconToBundleMapping = {
  'activity-heart': 'icons-0',
  activity: 'icons-0',
  airplay: 'icons-0',
  airpods: 'icons-0',
  'alarm-clock-check': 'icons-0',
  'alarm-clock-minus': 'icons-0',
  'alarm-clock-off': 'icons-0',
  'alarm-clock-plus': 'icons-0',
  'alarm-clock': 'icons-0',
  'alert-circle': 'icons-0',
  'alert-hexagon': 'icons-0',
  'alert-octagon': 'icons-0',
  'alert-square': 'icons-0',
  'alert-triangle': 'icons-0',
  'align-bottom-01': 'icons-0',
  'align-bottom-02': 'icons-0',
  'align-center': 'icons-0',
  'align-horizontal-centre-01': 'icons-0',
  'align-horizontal-centre-02': 'icons-0',
  'align-justify': 'icons-0',
  'align-left-01': 'icons-0',
  'align-left-02': 'icons-0',
  'align-left': 'icons-0',
  'align-right-01': 'icons-0',
  'align-right-02': 'icons-0',
  'align-right': 'icons-0',
  'align-top-arrow-01': 'icons-0',
  'align-top-arrow-02': 'icons-0',
  'align-vertical-center-01': 'icons-0',
  'align-vertical-center-02': 'icons-0',
  anchor: 'icons-0',
  'annotation-alert': 'icons-0',
  'annotation-check': 'icons-0',
  'annotation-dots': 'icons-0',
  'annotation-heart': 'icons-0',
  'annotation-info': 'icons-0',
  'annotation-plus': 'icons-0',
  'annotation-question': 'icons-0',
  'annotation-x': 'icons-0',
  annotation: 'icons-0',
  'announcement-01': 'icons-0',
  'announcement-02': 'icons-0',
  'announcement-03': 'icons-0',
  archive: 'icons-0',
  'arrow-block-down': 'icons-0',
  'arrow-block-left': 'icons-0',
  'arrow-block-right': 'icons-0',
  'arrow-block-up': 'icons-0',
  'arrow-circle-broken-down-left': 'icons-0',
  'arrow-circle-broken-down-right': 'icons-0',
  'arrow-circle-broken-down': 'icons-0',
  'arrow-circle-broken-left': 'icons-0',
  'arrow-circle-broken-right': 'icons-0',
  'arrow-circle-broken-up-left': 'icons-0',
  'arrow-circle-broken-up-right': 'icons-0',
  'arrow-circle-broken-up': 'icons-0',
  'arrow-circle-down-left': 'icons-0',
  'arrow-circle-down-right': 'icons-0',
  'arrow-circle-down': 'icons-0',
  'arrow-circle-left': 'icons-0',
  'arrow-circle-right': 'icons-0',
  'arrow-circle-up-left': 'icons-0',
  'arrow-circle-up-right': 'icons-0',
  'arrow-circle-up': 'icons-0',
  'arrow-down-left': 'icons-0',
  'arrow-down-right': 'icons-0',
  'arrow-down': 'icons-0',
  'arrow-left': 'icons-0',
  'arrow-narrow-down-left': 'icons-0',
  'arrow-narrow-down-right': 'icons-0',
  'arrow-narrow-down': 'icons-0',
  'arrow-narrow-left': 'icons-0',
  'arrow-narrow-right': 'icons-0',
  'arrow-narrow-up-left': 'icons-0',
  'arrow-narrow-up-right': 'icons-0',
  'arrow-narrow-up': 'icons-0',
  'arrow-right': 'icons-0',
  'arrow-square-down-left': 'icons-0',
  'arrow-square-down-right': 'icons-0',
  'arrow-square-down': 'icons-0',
  'arrow-square-left': 'icons-0',
  'arrow-square-right': 'icons-0',
  'arrow-square-up-left': 'icons-0',
  'arrow-square-up-right': 'icons-0',
  'arrow-square-up': 'icons-0',
  'arrow-up-left': 'icons-0',
  'arrow-up-right': 'icons-0',
  'arrow-up': 'icons-0',
  'arrows-down': 'icons-0',
  'arrows-left': 'icons-0',
  'arrows-right': 'icons-0',
  'arrows-triangle': 'icons-0',
  'arrows-up': 'icons-0',
  'asterisk-01': 'icons-0',
  'asterisk-02': 'icons-0',
  'at-sign': 'icons-0',
  'atom-01': 'icons-0',
  'atom-02': 'icons-0',
  'attachment-01': 'icons-0',
  'attachment-02': 'icons-0',
  'award-01': 'icons-1',
  'award-02': 'icons-1',
  'award-03': 'icons-1',
  'award-04': 'icons-1',
  'award-05': 'icons-1',
  backpack: 'icons-1',
  'bank-note-01': 'icons-1',
  'bank-note-02': 'icons-1',
  'bank-note-03': 'icons-1',
  bank: 'icons-1',
  'bar-chart-01': 'icons-1',
  'bar-chart-02': 'icons-1',
  'bar-chart-03': 'icons-1',
  'bar-chart-04': 'icons-1',
  'bar-chart-05': 'icons-1',
  'bar-chart-06': 'icons-1',
  'bar-chart-07': 'icons-1',
  'bar-chart-08': 'icons-1',
  'bar-chart-09': 'icons-1',
  'bar-chart-10': 'icons-1',
  'bar-chart-11': 'icons-1',
  'bar-chart-12': 'icons-1',
  'bar-chart-circle-01': 'icons-1',
  'bar-chart-circle-02': 'icons-1',
  'bar-chart-circle-03': 'icons-1',
  'bar-chart-square-01': 'icons-1',
  'bar-chart-square-02': 'icons-1',
  'bar-chart-square-03': 'icons-1',
  'bar-chart-square-down': 'icons-1',
  'bar-chart-square-minus': 'icons-1',
  'bar-chart-square-plus': 'icons-1',
  'bar-chart-square-up': 'icons-1',
  'bar-line-chart': 'icons-1',
  'battery-charging-01': 'icons-1',
  'battery-charging-02': 'icons-1',
  'battery-empty': 'icons-1',
  'battery-full': 'icons-1',
  'battery-low': 'icons-1',
  'battery-mid': 'icons-1',
  'beaker-01': 'icons-1',
  'beaker-02': 'icons-1',
  'bell-01': 'icons-1',
  'bell-02': 'icons-1',
  'bell-03': 'icons-1',
  'bell-04': 'icons-1',
  'bell-minus': 'icons-1',
  'bell-off-01': 'icons-1',
  'bell-off-02': 'icons-1',
  'bell-off-03': 'icons-1',
  'bell-plus': 'icons-1',
  'bell-ringing-01': 'icons-1',
  'bell-ringing-02': 'icons-1',
  'bell-ringing-03': 'icons-1',
  'bell-ringing-04': 'icons-1',
  'bezier-curve-01': 'icons-1',
  'bezier-curve-02': 'icons-1',
  'bezier-curve-03': 'icons-1',
  'bluetooth-connect': 'icons-1',
  'bluetooth-off': 'icons-1',
  'bluetooth-on': 'icons-1',
  'bluetooth-signal': 'icons-1',
  'bold-01': 'icons-1',
  'bold-02': 'icons-1',
  'bold-square': 'icons-1',
  'book-closed': 'icons-1',
  'book-open-01': 'icons-1',
  'book-open-02': 'icons-1',
  'bookmark-add': 'icons-1',
  'bookmark-check': 'icons-1',
  'bookmark-minus': 'icons-1',
  'bookmark-x': 'icons-1',
  bookmark: 'icons-1',
  box: 'icons-1',
  'brackets-check': 'icons-1',
  'brackets-ellipses': 'icons-1',
  'brackets-minus': 'icons-1',
  'brackets-plus': 'icons-1',
  'brackets-slash': 'icons-1',
  'brackets-x': 'icons-1',
  brackets: 'icons-1',
  'briefcase-01': 'icons-1',
  'briefcase-02': 'icons-1',
  browser: 'icons-1',
  'brush-01': 'icons-1',
  'brush-02': 'icons-1',
  'brush-03': 'icons-1',
  'building-01': 'icons-1',
  'building-02': 'icons-1',
  'building-03': 'icons-1',
  'building-04': 'icons-1',
  'building-05': 'icons-1',
  'building-06': 'icons-1',
  'building-07': 'icons-1',
  'building-08': 'icons-1',
  bus: 'icons-1',
  calculator: 'icons-1',
  'calendar-check-01': 'icons-1',
  'calendar-check-02': 'icons-1',
  'calendar-date': 'icons-1',
  'calendar-heart-01': 'icons-1',
  'calendar-heart-02': 'icons-2',
  'calendar-minus-01': 'icons-2',
  'calendar-minus-02': 'icons-2',
  'calendar-plus-01': 'icons-2',
  'calendar-plus-02': 'icons-2',
  calendar: 'icons-2',
  'camera-01': 'icons-2',
  'camera-02': 'icons-2',
  'camera-03': 'icons-2',
  'camera-lens': 'icons-2',
  'camera-off': 'icons-2',
  'camera-plus': 'icons-2',
  'car-01': 'icons-2',
  'car-02': 'icons-2',
  'certificate-01': 'icons-2',
  'certificate-02': 'icons-2',
  'chart-breakout-circle': 'icons-2',
  'chart-breakout-square': 'icons-2',
  'check-circle-broken': 'icons-2',
  'check-circle': 'icons-2',
  'check-done-01': 'icons-2',
  'check-done-02': 'icons-2',
  'check-heart': 'icons-2',
  'check-square-broken': 'icons-2',
  'check-square': 'icons-2',
  'check-verified-01': 'icons-2',
  'check-verified-02': 'icons-2',
  'check-verified-03': 'icons-2',
  check: 'icons-2',
  'chevron-down-double': 'icons-2',
  'chevron-down': 'icons-2',
  'chevron-left-double': 'icons-2',
  'chevron-left': 'icons-2',
  'chevron-right-double': 'icons-2',
  'chevron-right': 'icons-2',
  'chevron-selector-horizontal': 'icons-2',
  'chevron-selector-vertical': 'icons-2',
  'chevron-up-double': 'icons-2',
  'chevron-up': 'icons-2',
  'chrome-cast': 'icons-2',
  'circle-cut': 'icons-2',
  circle: 'icons-2',
  clapperboard: 'icons-2',
  'clipboard-attachment': 'icons-2',
  'clipboard-check': 'icons-2',
  'clipboard-download': 'icons-2',
  'clipboard-minus': 'icons-2',
  'clipboard-plus': 'icons-2',
  'clipboard-x': 'icons-2',
  clipboard: 'icons-2',
  'clock-check': 'icons-2',
  'clock-fast-forward': 'icons-2',
  'clock-plus': 'icons-2',
  'clock-refresh': 'icons-2',
  'clock-rewind': 'icons-2',
  'clock-snooze': 'icons-2',
  'clock-stopwatch': 'icons-2',
  clock: 'icons-2',
  'cloud-01': 'icons-2',
  'cloud-02': 'icons-2',
  'cloud-03': 'icons-2',
  'cloud-blank-01': 'icons-2',
  'cloud-blank-02': 'icons-2',
  'cloud-lightning': 'icons-2',
  'cloud-moon': 'icons-2',
  'cloud-off': 'icons-2',
  'cloud-raining-01': 'icons-2',
  'cloud-raining-02': 'icons-2',
  'cloud-raining-03': 'icons-2',
  'cloud-raining-04': 'icons-2',
  'cloud-raining-05': 'icons-2',
  'cloud-raining-06': 'icons-2',
  'cloud-snowing-01': 'icons-2',
  'cloud-snowing-02': 'icons-2',
  'cloud-sun-01': 'icons-2',
  'cloud-sun-02': 'icons-2',
  'cloud-sun-03': 'icons-2',
  'code-01': 'icons-2',
  'code-02': 'icons-2',
  'code-browser': 'icons-2',
  'code-circle-01': 'icons-2',
  'code-circle-02': 'icons-2',
  'code-circle-03': 'icons-2',
  'code-snippet-01': 'icons-2',
  'code-snippet-02': 'icons-2',
  'code-square-01': 'icons-2',
  'code-square-02': 'icons-2',
  codepen: 'icons-2',
  'coins-01': 'icons-2',
  'coins-02': 'icons-2',
  'coins-03': 'icons-2',
  'coins-04': 'icons-2',
  'coins-hand': 'icons-2',
  'coins-stacked-01': 'icons-2',
  'coins-stacked-02': 'icons-2',
  'coins-stacked-03': 'icons-2',
  'coins-stacked-04': 'icons-2',
  'coins-swap-01': 'icons-2',
  'coins-swap-02': 'icons-2',
  'colors-1': 'icons-2',
  colors: 'icons-3',
  'columns-01': 'icons-3',
  'columns-02': 'icons-3',
  'columns-03': 'icons-3',
  command: 'icons-3',
  'compass-01': 'icons-3',
  'compass-02': 'icons-3',
  'compass-03': 'icons-3',
  compass: 'icons-3',
  container: 'icons-3',
  'contrast-01': 'icons-3',
  'contrast-02': 'icons-3',
  'contrast-03': 'icons-3',
  'copy-01': 'icons-3',
  'copy-02': 'icons-3',
  'copy-03': 'icons-3',
  'copy-04': 'icons-3',
  'copy-05': 'icons-3',
  'copy-06': 'icons-3',
  'copy-07': 'icons-3',
  'corner-down-left': 'icons-3',
  'corner-down-right': 'icons-3',
  'corner-left-down': 'icons-3',
  'corner-left-up': 'icons-3',
  'corner-right-down': 'icons-3',
  'corner-right-up': 'icons-3',
  'corner-up-left': 'icons-3',
  'corner-up-right': 'icons-3',
  'cpu-chip-01': 'icons-3',
  'cpu-chip-02': 'icons-3',
  'credit-card-01': 'icons-3',
  'credit-card-02': 'icons-3',
  'credit-card-check': 'icons-3',
  'credit-card-down': 'icons-3',
  'credit-card-download': 'icons-3',
  'credit-card-edit': 'icons-3',
  'credit-card-lock': 'icons-3',
  'credit-card-minus': 'icons-3',
  'credit-card-plus': 'icons-3',
  'credit-card-refresh': 'icons-3',
  'credit-card-search': 'icons-3',
  'credit-card-shield': 'icons-3',
  'credit-card-up': 'icons-3',
  'credit-card-upload': 'icons-3',
  'credit-card-x': 'icons-3',
  'crop-01': 'icons-3',
  'crop-02': 'icons-3',
  'cryptocurrency-01': 'icons-3',
  'cryptocurrency-02': 'icons-3',
  'cryptocurrency-03': 'icons-3',
  'cryptocurrency-04': 'icons-3',
  'cube-01': 'icons-3',
  'cube-02': 'icons-3',
  'cube-03': 'icons-3',
  'cube-04': 'icons-3',
  'cube-outline': 'icons-3',
  'currency-bitcoin-circle': 'icons-3',
  'currency-bitcoin': 'icons-3',
  'currency-dollar-circle': 'icons-3',
  'currency-dollar': 'icons-3',
  'currency-ethereum-circle': 'icons-3',
  'currency-ethereum': 'icons-3',
  'currency-euro-circle': 'icons-3',
  'currency-euro': 'icons-3',
  'currency-pound-circle': 'icons-3',
  'currency-pound': 'icons-3',
  'currency-ruble-circle': 'icons-3',
  'currency-ruble': 'icons-3',
  'currency-rupee-circle': 'icons-3',
  'currency-rupee': 'icons-3',
  'currency-yen-circle': 'icons-3',
  'currency-yen': 'icons-3',
  'cursor-01': 'icons-3',
  'cursor-02': 'icons-3',
  'cursor-03': 'icons-3',
  'cursor-04': 'icons-3',
  'cursor-box': 'icons-3',
  'cursor-click-01': 'icons-3',
  'cursor-click-02': 'icons-3',
  data: 'icons-3',
  'database-01': 'icons-3',
  'database-02': 'icons-3',
  'database-03': 'icons-3',
  'dataflow-01': 'icons-3',
  'dataflow-02': 'icons-3',
  'dataflow-03': 'icons-3',
  'dataflow-04': 'icons-3',
  delete: 'icons-3',
  'diamond-01': 'icons-3',
  'diamond-02': 'icons-3',
  'dice-1': 'icons-3',
  'dice-2': 'icons-3',
  'dice-3': 'icons-3',
  'dice-4': 'icons-3',
  'dice-5': 'icons-3',
  'dice-6': 'icons-3',
  'disc-01': 'icons-3',
  'disc-02': 'icons-3',
  'distribute-spacing-horizontal': 'icons-3',
  'distribute-spacing-vertical': 'icons-3',
  'divide-01': 'icons-4',
  'divide-02': 'icons-4',
  'divide-03': 'icons-4',
  divider: 'icons-4',
  'dotpoints-01': 'icons-4',
  'dotpoints-02': 'icons-4',
  'dots-grid': 'icons-4',
  'dots-horizontal': 'icons-4',
  'dots-vertical': 'icons-4',
  'download-01': 'icons-4',
  'download-02': 'icons-4',
  'download-03': 'icons-4',
  'download-04': 'icons-4',
  'download-cloud-01': 'icons-4',
  'download-cloud-02': 'icons-4',
  drop: 'icons-4',
  'droplets-01': 'icons-4',
  'droplets-02': 'icons-4',
  'droplets-03': 'icons-4',
  dropper: 'icons-4',
  'edit-01': 'icons-4',
  'edit-02': 'icons-4',
  'edit-03': 'icons-4',
  'edit-04': 'icons-4',
  'edit-05': 'icons-4',
  'equal-not': 'icons-4',
  equal: 'icons-4',
  eraser: 'icons-4',
  'expand-01': 'icons-4',
  'expand-02': 'icons-4',
  'expand-03': 'icons-4',
  'expand-04': 'icons-4',
  'expand-05': 'icons-4',
  'expand-06': 'icons-4',
  'eye-off': 'icons-4',
  eye: 'icons-4',
  'face-content': 'icons-4',
  'face-frown': 'icons-4',
  'face-happy': 'icons-4',
  'face-id-square': 'icons-4',
  'face-id': 'icons-4',
  'face-neutral': 'icons-4',
  'face-sad': 'icons-4',
  'face-smile': 'icons-4',
  'face-wink': 'icons-4',
  'fast-backward': 'icons-4',
  'fast-forward': 'icons-4',
  feather: 'icons-4',
  figma: 'icons-4',
  'file-01': 'icons-4',
  'file-02': 'icons-4',
  'file-03': 'icons-4',
  'file-04': 'icons-4',
  'file-05': 'icons-4',
  'file-06': 'icons-4',
  'file-07': 'icons-4',
  'file-attachment-01': 'icons-4',
  'file-attachment-02': 'icons-4',
  'file-attachment-03': 'icons-4',
  'file-attachment-04': 'icons-4',
  'file-attachment-05': 'icons-4',
  'file-check-01': 'icons-4',
  'file-check-02': 'icons-4',
  'file-check-03': 'icons-4',
  'file-code-01': 'icons-4',
  'file-code-02': 'icons-4',
  'file-download-01': 'icons-4',
  'file-download-02': 'icons-4',
  'file-download-03': 'icons-4',
  'file-heart-01': 'icons-4',
  'file-heart-02': 'icons-4',
  'file-heart-03': 'icons-4',
  'file-lock-01': 'icons-4',
  'file-lock-02': 'icons-4',
  'file-lock-03': 'icons-4',
  'file-minus-01': 'icons-4',
  'file-minus-02': 'icons-4',
  'file-minus-03': 'icons-4',
  'file-plus-01': 'icons-4',
  'file-plus-02': 'icons-4',
  'file-plus-03': 'icons-4',
  'file-question-01': 'icons-4',
  'file-question-02': 'icons-4',
  'file-question-03': 'icons-4',
  'file-search-01': 'icons-4',
  'file-search-02': 'icons-4',
  'file-search-03': 'icons-4',
  'file-shield-01': 'icons-4',
  'file-shield-02': 'icons-4',
  'file-shield-03': 'icons-4',
  'file-x-01': 'icons-4',
  'file-x-02': 'icons-4',
  'file-x-03': 'icons-4',
  'film-01': 'icons-4',
  'film-02': 'icons-4',
  'film-03': 'icons-4',
  'filter-funnel-01': 'icons-4',
  'filter-funnel-02': 'icons-4',
  'filter-lines': 'icons-4',
  'fingerprint-01': 'icons-4',
  'fingerprint-02': 'icons-5',
  'fingerprint-03': 'icons-5',
  'fingerprint-04': 'icons-5',
  'flag-01': 'icons-5',
  'flag-02': 'icons-5',
  'flag-03': 'icons-5',
  'flag-04': 'icons-5',
  'flag-05': 'icons-5',
  'flag-06': 'icons-5',
  'flash-off': 'icons-5',
  flash: 'icons-5',
  'flex-align-bottom': 'icons-5',
  'flex-align-left': 'icons-5',
  'flex-align-right': 'icons-5',
  'flex-align-top': 'icons-5',
  'flip-backward': 'icons-5',
  'flip-forward': 'icons-5',
  'folder-check': 'icons-5',
  'folder-closed': 'icons-5',
  'folder-code': 'icons-5',
  'folder-download': 'icons-5',
  'folder-lock': 'icons-5',
  'folder-minus': 'icons-5',
  'folder-plus': 'icons-5',
  'folder-question': 'icons-5',
  'folder-search': 'icons-5',
  'folder-shield': 'icons-5',
  'folder-x': 'icons-5',
  folder: 'icons-5',
  framer: 'icons-5',
  'gaming-pad-01': 'icons-5',
  'gaming-pad-02': 'icons-5',
  'gift-01': 'icons-5',
  'gift-02': 'icons-5',
  'git-branch-01': 'icons-5',
  'git-branch-02': 'icons-5',
  'git-commit': 'icons-5',
  'git-merge': 'icons-5',
  'git-pull-request': 'icons-5',
  'glasses-01': 'icons-5',
  'glasses-02': 'icons-5',
  'globe-01-1': 'icons-5',
  'globe-01': 'icons-5',
  'globe-02-1': 'icons-5',
  'globe-02': 'icons-5',
  'globe-03': 'icons-5',
  'globe-04': 'icons-5',
  'globe-05': 'icons-5',
  'globe-06': 'icons-5',
  'google-chrome': 'icons-5',
  'graduation-hat-01': 'icons-5',
  'graduation-hat-02': 'icons-5',
  'grid-01': 'icons-5',
  'grid-02': 'icons-5',
  'grid-03': 'icons-5',
  'grid-dots-blank': 'icons-5',
  'grid-dots-bottom': 'icons-5',
  'grid-dots-horizontal-center': 'icons-5',
  'grid-dots-left': 'icons-5',
  'grid-dots-outer': 'icons-5',
  'grid-dots-right': 'icons-5',
  'grid-dots-top': 'icons-5',
  'grid-dots-vertical-center': 'icons-5',
  hand: 'icons-5',
  'hard-drive': 'icons-5',
  'hash-01': 'icons-5',
  'hash-02': 'icons-5',
  'heading-01': 'icons-5',
  'heading-02': 'icons-5',
  'heading-square': 'icons-5',
  'headphones-01': 'icons-5',
  'headphones-02': 'icons-5',
  'heart-circle': 'icons-5',
  'heart-hand': 'icons-5',
  'heart-hexagon': 'icons-5',
  'heart-octagon': 'icons-5',
  'heart-rounded': 'icons-5',
  'heart-square': 'icons-5',
  heart: 'icons-5',
  hearts: 'icons-5',
  'help-circle': 'icons-5',
  'help-octagon-1': 'icons-5',
  'help-octagon': 'icons-5',
  'help-square': 'icons-5',
  'hexagon-01': 'icons-5',
  'hexagon-02': 'icons-5',
  'home-01': 'icons-5',
  'home-02': 'icons-5',
  'home-03': 'icons-5',
  'home-04': 'icons-5',
  'home-05': 'icons-5',
  'home-line': 'icons-5',
  'home-smile': 'icons-5',
  'horizontal-bar-chart-01': 'icons-5',
  'horizontal-bar-chart-02': 'icons-5',
  'horizontal-bar-chart-03': 'icons-5',
  'hourglass-01': 'icons-5',
  'hourglass-02': 'icons-5',
  'hourglass-03': 'icons-5',
  'hurricane-01': 'icons-5',
  'hurricane-02': 'icons-6',
  'hurricane-03': 'icons-6',
  'image-01': 'icons-6',
  'image-02': 'icons-6',
  'image-03': 'icons-6',
  'image-04': 'icons-6',
  'image-05': 'icons-6',
  'image-check': 'icons-6',
  'image-down': 'icons-6',
  'image-indent-left': 'icons-6',
  'image-indent-right': 'icons-6',
  'image-left': 'icons-6',
  'image-plus': 'icons-6',
  'image-right': 'icons-6',
  'image-up': 'icons-6',
  'image-user-check': 'icons-6',
  'image-user-down': 'icons-6',
  'image-user-left': 'icons-6',
  'image-user-plus': 'icons-6',
  'image-user-right': 'icons-6',
  'image-user-up': 'icons-6',
  'image-user-x': 'icons-6',
  'image-user': 'icons-6',
  'image-x': 'icons-6',
  'inbox-01': 'icons-6',
  'inbox-02': 'icons-6',
  infinity: 'icons-6',
  'info-circle': 'icons-6',
  'info-hexagon': 'icons-6',
  'info-octagon': 'icons-6',
  'info-square': 'icons-6',
  'intersect-circle': 'icons-6',
  'intersect-square': 'icons-6',
  'italic-01': 'icons-6',
  'italic-02': 'icons-6',
  'italic-square': 'icons-6',
  'key-01': 'icons-6',
  'key-02': 'icons-6',
  'keyboard-01': 'icons-6',
  'keyboard-02': 'icons-6',
  'laptop-01': 'icons-6',
  'laptop-02': 'icons-6',
  'layer-single': 'icons-6',
  'layers-three-01': 'icons-6',
  'layers-three-02': 'icons-6',
  'layers-two-01': 'icons-6',
  'layers-two-02': 'icons-6',
  'layout-alt-01': 'icons-6',
  'layout-alt-02': 'icons-6',
  'layout-alt-03': 'icons-6',
  'layout-alt-04': 'icons-6',
  'layout-bottom': 'icons-6',
  'layout-grid-01': 'icons-6',
  'layout-grid-02': 'icons-6',
  'layout-left': 'icons-6',
  'layout-right': 'icons-6',
  'layout-top': 'icons-6',
  'left-indent-01': 'icons-6',
  'left-indent-02': 'icons-6',
  'letter-spacing-01': 'icons-6',
  'letter-spacing-02': 'icons-6',
  'life-buoy-01': 'icons-6',
  'life-buoy-02': 'icons-6',
  'lightbulb-01': 'icons-6',
  'lightbulb-02': 'icons-6',
  'lightbulb-03': 'icons-6',
  'lightbulb-04': 'icons-6',
  'lightbulb-05': 'icons-6',
  'lightning-01': 'icons-6',
  'lightning-02': 'icons-6',
  'line-chart-down-01': 'icons-6',
  'line-chart-down-02': 'icons-6',
  'line-chart-down-03': 'icons-6',
  'line-chart-down-04': 'icons-6',
  'line-chart-down-05': 'icons-6',
  'line-chart-up-01': 'icons-6',
  'line-chart-up-02': 'icons-6',
  'line-chart-up-03': 'icons-6',
  'line-chart-up-04': 'icons-6',
  'line-chart-up-05': 'icons-6',
  'line-height': 'icons-6',
  'link-01': 'icons-6',
  'link-02': 'icons-6',
  'link-03': 'icons-6',
  'link-04': 'icons-6',
  'link-05': 'icons-6',
  'link-broken-01': 'icons-6',
  'link-broken-02': 'icons-6',
  'link-external-01': 'icons-6',
  'link-external-02': 'icons-6',
  'link-plus-01': 'icons-6',
  'link-plus-02': 'icons-6',
  list: 'icons-6',
  'loading-01': 'icons-6',
  'loading-02': 'icons-6',
  'loading-03': 'icons-6',
  'lock-01': 'icons-6',
  'lock-02': 'icons-6',
  'lock-03': 'icons-6',
  'lock-04': 'icons-6',
  'lock-keyhole-circle': 'icons-7',
  'lock-keyhole-square': 'icons-7',
  'lock-unlocked-01': 'icons-7',
  'lock-unlocked-02': 'icons-7',
  'lock-unlocked-03': 'icons-7',
  'lock-unlocked-04': 'icons-7',
  'log-in-01': 'icons-7',
  'log-in-02': 'icons-7',
  'log-in-03': 'icons-7',
  'log-in-04': 'icons-7',
  'log-out-01': 'icons-7',
  'log-out-02': 'icons-7',
  'log-out-03': 'icons-7',
  'log-out-04': 'icons-7',
  'luggage-01': 'icons-7',
  'luggage-02': 'icons-7',
  'luggage-03': 'icons-7',
  'magic-wand-01': 'icons-7',
  'magic-wand-02': 'icons-7',
  'mail-01': 'icons-7',
  'mail-02': 'icons-7',
  'mail-03': 'icons-7',
  'mail-04': 'icons-7',
  'mail-05': 'icons-7',
  'map-01': 'icons-7',
  'map-02': 'icons-7',
  mark: 'icons-7',
  'marker-pin-01': 'icons-7',
  'marker-pin-02': 'icons-7',
  'marker-pin-03': 'icons-7',
  'marker-pin-04': 'icons-7',
  'marker-pin-05': 'icons-7',
  'marker-pin-06': 'icons-7',
  'maximize-01': 'icons-7',
  'maximize-02': 'icons-7',
  'medical-circle': 'icons-7',
  'medical-cross': 'icons-7',
  'medical-square': 'icons-7',
  'menu-01': 'icons-7',
  'menu-02': 'icons-7',
  'menu-03': 'icons-7',
  'menu-04': 'icons-7',
  'menu-05': 'icons-7',
  'message-alert-circle': 'icons-7',
  'message-alert-square': 'icons-7',
  'message-chat-circle': 'icons-7',
  'message-chat-square': 'icons-7',
  'message-check-circle': 'icons-7',
  'message-check-square': 'icons-7',
  'message-circle-01': 'icons-7',
  'message-circle-02': 'icons-7',
  'message-dots-circle': 'icons-7',
  'message-dots-square': 'icons-7',
  'message-heart-circle': 'icons-7',
  'message-heart-square': 'icons-7',
  'message-notification-circle': 'icons-7',
  'message-notification-square': 'icons-7',
  'message-plus-circle': 'icons-7',
  'message-plus-square': 'icons-7',
  'message-question-circle': 'icons-7',
  'message-question-square': 'icons-7',
  'message-smile-circle': 'icons-7',
  'message-smile-square': 'icons-7',
  'message-square-01': 'icons-7',
  'message-square-02': 'icons-7',
  'message-text-circle-01': 'icons-7',
  'message-text-circle-02': 'icons-7',
  'message-text-square-01': 'icons-7',
  'message-text-square-02': 'icons-7',
  'message-x-circle': 'icons-7',
  'message-x-square': 'icons-7',
  'microphone-01': 'icons-7',
  'microphone-02': 'icons-7',
  'microphone-off-01': 'icons-7',
  'microphone-off-02': 'icons-7',
  microscope: 'icons-7',
  'minimize-01': 'icons-7',
  'minimize-02': 'icons-7',
  'minus-circle': 'icons-7',
  'minus-square': 'icons-7',
  minus: 'icons-7',
  'modem-01': 'icons-7',
  'modem-02': 'icons-7',
  'monitor-01': 'icons-7',
  'monitor-02': 'icons-7',
  'monitor-03': 'icons-7',
  'monitor-04': 'icons-7',
  'monitor-05': 'icons-7',
  'moon-01': 'icons-7',
  'moon-02': 'icons-7',
  'moon-eclipse': 'icons-7',
  'moon-star': 'icons-7',
  mouse: 'icons-7',
  move: 'icons-7',
  'music-note-01': 'icons-7',
  'music-note-02': 'icons-7',
  'music-note-plus': 'icons-7',
  'navigation-pointer-01': 'icons-7',
  'navigation-pointer-02': 'icons-7',
  'navigation-pointer-off-01': 'icons-7',
  'navigation-pointer-off-02': 'icons-8',
  'notification-box': 'icons-8',
  'notification-message': 'icons-8',
  'notification-text': 'icons-8',
  octagon: 'icons-8',
  'package-check': 'icons-8',
  'package-minus': 'icons-8',
  'package-plus': 'icons-8',
  'package-search': 'icons-8',
  'package-x': 'icons-8',
  package: 'icons-8',
  'paint-pour': 'icons-8',
  paint: 'icons-8',
  palette: 'icons-8',
  paperclip: 'icons-8',
  'paragraph-spacing': 'icons-8',
  'paragraph-wrap': 'icons-8',
  'passcode-lock': 'icons-8',
  passcode: 'icons-8',
  passport: 'icons-8',
  'pause-circle': 'icons-8',
  'pause-square': 'icons-8',
  'pen-tool-01': 'icons-8',
  'pen-tool-02': 'icons-8',
  'pen-tool-minus': 'icons-8',
  'pen-tool-plus': 'icons-8',
  'pencil-01': 'icons-8',
  'pencil-02': 'icons-8',
  'pencil-line': 'icons-8',
  pentagon: 'icons-8',
  'percent-01': 'icons-8',
  'percent-02': 'icons-8',
  'percent-03': 'icons-8',
  'perspective-01': 'icons-8',
  'perspective-02': 'icons-8',
  'phone-01': 'icons-8',
  'phone-02': 'icons-8',
  'phone-call-01': 'icons-8',
  'phone-call-02': 'icons-8',
  'phone-hang-up': 'icons-8',
  'phone-incoming-01': 'icons-8',
  'phone-incoming-02': 'icons-8',
  'phone-outgoing-01': 'icons-8',
  'phone-outgoing-02': 'icons-8',
  'phone-pause': 'icons-8',
  'phone-plus': 'icons-8',
  'phone-x': 'icons-8',
  phone: 'icons-8',
  'pie-chart-01': 'icons-8',
  'pie-chart-02': 'icons-8',
  'pie-chart-03': 'icons-8',
  'pie-chart-04': 'icons-8',
  'piggy-bank-01': 'icons-8',
  'piggy-bank-02': 'icons-8',
  'pilcrow-01': 'icons-8',
  'pilcrow-02': 'icons-8',
  'pilcrow-square': 'icons-8',
  'pin-01': 'icons-8',
  'pin-02': 'icons-8',
  placeholder: 'icons-8',
  plane: 'icons-8',
  'play-circle': 'icons-8',
  'play-square': 'icons-8',
  play: 'icons-8',
  'plus-circle': 'icons-8',
  'plus-square': 'icons-8',
  plus: 'icons-8',
  podcast: 'icons-8',
  'power-01': 'icons-8',
  'power-02': 'icons-8',
  'power-03': 'icons-8',
  'presentation-chart-01': 'icons-8',
  'presentation-chart-02': 'icons-8',
  'presentation-chart-03': 'icons-8',
  printer: 'icons-8',
  'puzzle-piece-01': 'icons-8',
  'puzzle-piece-02': 'icons-8',
  'qr-code-01': 'icons-8',
  'qr-code-02': 'icons-8',
  'receipt-check': 'icons-8',
  receipt: 'icons-8',
  'recording-01': 'icons-8',
  'recording-02': 'icons-8',
  'recording-03': 'icons-8',
  'reflect-01': 'icons-8',
  'reflect-02': 'icons-8',
  'refresh-ccw-01': 'icons-8',
  'refresh-ccw-02': 'icons-8',
  'refresh-ccw-03': 'icons-8',
  'refresh-ccw-04': 'icons-8',
  'refresh-ccw-05': 'icons-8',
  'refresh-cw-01': 'icons-8',
  'refresh-cw-02': 'icons-8',
  'refresh-cw-03': 'icons-8',
  'refresh-cw-04': 'icons-8',
  'refresh-cw-05': 'icons-8',
  'repeat-01': 'icons-8',
  'repeat-02': 'icons-8',
  'repeat-03': 'icons-8',
  'repeat-04': 'icons-8',
  'reverse-left': 'icons-9',
  'reverse-right': 'icons-9',
  'right-indent-01': 'icons-9',
  'right-indent-02': 'icons-9',
  'rocket-01': 'icons-9',
  'rocket-02': 'icons-9',
  'roller-brush': 'icons-9',
  route: 'icons-9',
  'rows-01': 'icons-9',
  'rows-02': 'icons-9',
  'rows-03': 'icons-9',
  'rss-01': 'icons-9',
  'rss-02': 'icons-9',
  ruler: 'icons-9',
  safe: 'icons-9',
  'sale-01': 'icons-9',
  'sale-02': 'icons-9',
  'sale-03': 'icons-9',
  'sale-04': 'icons-9',
  'save-01': 'icons-9',
  'save-02': 'icons-9',
  'save-03': 'icons-9',
  'scale-01': 'icons-9',
  'scale-02': 'icons-9',
  'scale-03': 'icons-9',
  'scales-01': 'icons-9',
  'scales-02': 'icons-9',
  scan: 'icons-9',
  'scissors-01': 'icons-9',
  'scissors-02': 'icons-9',
  'scissors-cut-01': 'icons-9',
  'scissors-cut-02': 'icons-9',
  'search-lg': 'icons-9',
  'search-md': 'icons-9',
  'search-refraction': 'icons-9',
  'search-sm': 'icons-9',
  'send-01': 'icons-9',
  'send-02': 'icons-9',
  'send-03': 'icons-9',
  'server-01': 'icons-9',
  'server-02': 'icons-9',
  'server-03': 'icons-9',
  'server-04': 'icons-9',
  'server-05': 'icons-9',
  'server-06': 'icons-9',
  'settings-01': 'icons-9',
  'settings-02': 'icons-9',
  'settings-03': 'icons-9',
  'settings-04': 'icons-9',
  'share-01': 'icons-9',
  'share-02': 'icons-9',
  'share-03': 'icons-9',
  'share-04': 'icons-9',
  'share-05': 'icons-9',
  'share-06': 'icons-9',
  'share-07': 'icons-9',
  'shield-01': 'icons-9',
  'shield-02': 'icons-9',
  'shield-03': 'icons-9',
  'shield-dollar': 'icons-9',
  'shield-off': 'icons-9',
  'shield-plus': 'icons-9',
  'shield-tick': 'icons-9',
  'shield-zap': 'icons-9',
  'shopping-bag-01': 'icons-9',
  'shopping-bag-02': 'icons-9',
  'shopping-bag-03': 'icons-9',
  'shopping-cart-01': 'icons-9',
  'shopping-cart-02': 'icons-9',
  'shopping-cart-03': 'icons-9',
  'shuffle-01': 'icons-9',
  'shuffle-02': 'icons-9',
  'signal-01': 'icons-9',
  'signal-02': 'icons-9',
  'signal-03': 'icons-9',
  simcard: 'icons-9',
  skew: 'icons-9',
  'skip-back': 'icons-9',
  'skip-forward': 'icons-9',
  'slash-circle-01': 'icons-9',
  'slash-circle-02': 'icons-9',
  'slash-divider': 'icons-9',
  'slash-octagon': 'icons-9',
  'sliders-01': 'icons-9',
  'sliders-02': 'icons-9',
  'sliders-03': 'icons-9',
  'sliders-04': 'icons-9',
  'snowflake-01': 'icons-9',
  'snowflake-02': 'icons-9',
  'spacing-height-01': 'icons-9',
  'spacing-height-02': 'icons-9',
  'spacing-width-01': 'icons-9',
  'spacing-width-02': 'icons-9',
  'speaker-01': 'icons-9',
  'speaker-02': 'icons-9',
  'speaker-03': 'icons-9',
  'speedometer-01': 'icons-9',
  'speedometer-02': 'icons-9',
  'speedometer-03': 'icons-9',
  'speedometer-04': 'icons-9',
  square: 'icons-10',
  stand: 'icons-10',
  'star-01': 'icons-10',
  'star-02': 'icons-10',
  'star-03': 'icons-10',
  'star-04': 'icons-10',
  'star-05': 'icons-10',
  'star-06': 'icons-10',
  'star-07': 'icons-10',
  'stars-01': 'icons-10',
  'stars-02': 'icons-10',
  'stars-03': 'icons-10',
  'sticker-circle': 'icons-10',
  'sticker-square': 'icons-10',
  'stop-circle': 'icons-10',
  'stop-square': 'icons-10',
  stop: 'icons-10',
  'strikethrough-01': 'icons-10',
  'strikethrough-02': 'icons-10',
  'strikethrough-square': 'icons-10',
  subscript: 'icons-10',
  'sun-setting-01': 'icons-10',
  'sun-setting-02': 'icons-10',
  'sun-setting-03': 'icons-10',
  sun: 'icons-10',
  sunrise: 'icons-10',
  sunset: 'icons-10',
  'switch-horizontal-01': 'icons-10',
  'switch-horizontal-02': 'icons-10',
  'switch-vertical-01': 'icons-10',
  'switch-vertical-02': 'icons-10',
  table: 'icons-10',
  'tablet-01': 'icons-10',
  'tablet-02': 'icons-10',
  'tag-01': 'icons-10',
  'tag-02': 'icons-10',
  'tag-03': 'icons-10',
  'target-01': 'icons-10',
  'target-02': 'icons-10',
  'target-03': 'icons-10',
  'target-04': 'icons-10',
  'target-05': 'icons-10',
  telescope: 'icons-10',
  'terminal-browser': 'icons-10',
  'terminal-circle': 'icons-10',
  'terminal-square': 'icons-10',
  terminal: 'icons-10',
  'text-input': 'icons-10',
  'thermometer-01': 'icons-10',
  'thermometer-02': 'icons-10',
  'thermometer-03': 'icons-10',
  'thermometer-cold': 'icons-10',
  'thermometer-warm': 'icons-10',
  'thumbs-down': 'icons-10',
  'thumbs-up': 'icons-10',
  'ticket-01': 'icons-10',
  'ticket-02': 'icons-10',
  'toggle-01-left': 'icons-10',
  'toggle-01-right': 'icons-10',
  'toggle-02-left': 'icons-10',
  'toggle-02-right': 'icons-10',
  'toggle-03-left': 'icons-10',
  'toggle-03-right': 'icons-10',
  'tool-01': 'icons-10',
  'tool-02': 'icons-10',
  train: 'icons-10',
  tram: 'icons-10',
  transform: 'icons-10',
  'translate-01': 'icons-10',
  'translate-02': 'icons-10',
  'trash-01': 'icons-10',
  'trash-02': 'icons-10',
  'trash-03': 'icons-10',
  'trash-04': 'icons-10',
  'trend-down-01': 'icons-10',
  'trend-down-02': 'icons-10',
  'trend-up-01': 'icons-10',
  'trend-up-02': 'icons-10',
  triangle: 'icons-10',
  'trophy-01': 'icons-10',
  'trophy-02': 'icons-10',
  'truck-01': 'icons-10',
  'truck-02': 'icons-10',
  'tv-01': 'icons-10',
  'tv-02': 'icons-10',
  'tv-03': 'icons-10',
  'type-01': 'icons-10',
  'type-02': 'icons-10',
  'type-square': 'icons-10',
  'type-strikethrough-01': 'icons-10',
  'type-strikethrough-02': 'icons-10',
  'umbrella-01': 'icons-10',
  'umbrella-02': 'icons-10',
  'umbrella-03': 'icons-10',
  'underline-01': 'icons-10',
  'underline-02': 'icons-10',
  'underline-square': 'icons-10',
  'upload-01': 'icons-10',
  'upload-02': 'icons-10',
  'upload-03': 'icons-10',
  'upload-04': 'icons-11',
  'upload-cloud-01': 'icons-11',
  'upload-cloud-02': 'icons-11',
  'usb-flash-drive': 'icons-11',
  'user-01': 'icons-11',
  'user-02': 'icons-11',
  'user-03': 'icons-11',
  'user-check-01': 'icons-11',
  'user-check-02': 'icons-11',
  'user-circle': 'icons-11',
  'user-down-01': 'icons-11',
  'user-down-02': 'icons-11',
  'user-edit': 'icons-11',
  'user-left-01': 'icons-11',
  'user-left-02': 'icons-11',
  'user-minus-01': 'icons-11',
  'user-minus-02': 'icons-11',
  'user-plus-01': 'icons-11',
  'user-plus-02': 'icons-11',
  'user-right-01': 'icons-11',
  'user-right-02': 'icons-11',
  'user-square': 'icons-11',
  'user-up-01': 'icons-11',
  'user-up-02': 'icons-11',
  'user-x-01': 'icons-11',
  'user-x-02': 'icons-11',
  'users-01': 'icons-11',
  'users-02': 'icons-11',
  'users-03': 'icons-11',
  'users-check': 'icons-11',
  'users-down': 'icons-11',
  'users-edit': 'icons-11',
  'users-left': 'icons-11',
  'users-minus': 'icons-11',
  'users-plus': 'icons-11',
  'users-right': 'icons-11',
  'users-up': 'icons-11',
  'users-x': 'icons-11',
  variable: 'icons-11',
  'video-recorder-off': 'icons-11',
  'video-recorder': 'icons-11',
  virus: 'icons-11',
  voicemail: 'icons-11',
  'volume-max': 'icons-11',
  'volume-min': 'icons-11',
  'volume-minus': 'icons-11',
  'volume-plus': 'icons-11',
  'volume-x': 'icons-11',
  'wallet-01': 'icons-11',
  'wallet-02': 'icons-11',
  'wallet-03': 'icons-11',
  'wallet-04': 'icons-11',
  'wallet-05': 'icons-11',
  'watch-circle': 'icons-11',
  'watch-square': 'icons-11',
  waves: 'icons-11',
  'webcam-01': 'icons-11',
  'webcam-02': 'icons-11',
  'wifi-off': 'icons-11',
  wifi: 'icons-11',
  'wind-01': 'icons-11',
  'wind-02': 'icons-11',
  'wind-03': 'icons-11',
  'x-circle': 'icons-11',
  'x-close': 'icons-11',
  'x-square': 'icons-11',
  x: 'icons-11',
  youtube: 'icons-11',
  'zap-circle': 'icons-11',
  'zap-fast': 'icons-11',
  'zap-off': 'icons-11',
  'zap-square': 'icons-11',
  zap: 'icons-11',
  'zoom-in': 'icons-11',
  'zoom-out': 'icons-11',
};
