import { OperationNames } from 'events.schema';

import { RemoteProjectChangeEvent } from '../types';

export const checkIfOrderTasksChanged = (event: RemoteProjectChangeEvent, orderId: string) => {
  return (
    event.operation.name === OperationNames.UpdateOrders &&
    event.operation.input.some((input) => input.id === orderId && input.tasks !== undefined)
  );
};

export const checkIfAlternativeHasBeenDeleted = (
  event: RemoteProjectChangeEvent,
  alternativeId: string,
) =>
  event.operation.name === OperationNames.DeleteProjectAlternative &&
  event.status === 'SUCCESS' &&
  event.operation.input.alternativeId === alternativeId;

export const reloadIfAlternativeHasBeenPublished = (
  event: RemoteProjectChangeEvent,
  alternativeId: string,
) => {
  if (
    event.operation.name === OperationNames.PublishProjectAlternativeResult &&
    event.operation.input.source.alternativeId === alternativeId
  ) {
    window.location.reload();
  }
};

export const runActionOnVersionRestore = (event: RemoteProjectChangeEvent, cb = () => {}) => {
  if (event.operation.name === OperationNames.RestoreProjectVersionResult) {
    cb();
  }
};

export const reloadIfVersionHasBeenRestored = (event: RemoteProjectChangeEvent) => {
  if (
    event.operation.name === OperationNames.RestoreProjectVersionResult &&
    event.operation.input.status === 'SUCCESS'
  ) {
    window.location.reload();
  }
};
