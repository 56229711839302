import { RTCClient } from './client';

let client: RTCClient;

export function useRTCClient(): RTCClient {
  return client;
}

export function installRTCClient(newClient: RTCClient) {
  client = newClient;
}
