import {
  Entity,
  INTERACTIVE_ENTITY_KEYS,
  INTERACTIVE_ENTITY_STORES_BY_ENTITY,
  InteractiveEntityChanges,
} from '@/common/types';
import { pickKeys } from '@/helpers/utils/objects';

import { RTCConflictDetection } from '../realTimeCollaboration';
import { FullInteractiveEntityStores } from './actions';

export function changesAreConflictingToCurrentState(
  entityStores: FullInteractiveEntityStores,
  changes: InteractiveEntityChanges,
): boolean {
  return INTERACTIVE_ENTITY_KEYS.some((entityKey) => {
    // section sanitization for undo redo requires custom conflict detection, which we won't to for now
    // the backend has sanity checks in place, that should suffice
    if (entityKey === 'wbsSections') return false;
    const storeKey = INTERACTIVE_ENTITY_STORES_BY_ENTITY[entityKey];
    if (!storeKey) return false;

    const store = entityStores[storeKey]();
    if (!store) return false;

    const storeEntities = store[entityKey] as Map<string, Entity>;

    const changesContainInsertsOrUpdates = Boolean(
      changes.add?.[entityKey]?.length || changes.update?.[entityKey]?.length,
    );

    if (changesContainInsertsOrUpdates) {
      const entityChanges = [
        ...(changes.update?.[entityKey] ?? []),
        ...(changes.add?.[entityKey] ?? []),
      ];
      const currentEntities = entityChanges.map((entity) => storeEntities.get(entity.id));

      const atLeastOneEntityHasBeenRemovedFromTheStore = currentEntities.some(
        (entity) => entity === undefined,
      );

      if (atLeastOneEntityHasBeenRemovedFromTheStore) return true;

      const changesHaveConflicts = RTCConflictDetection.changesHaveConflicts(
        {
          update: {
            [entityKey]: entityChanges,
          },
        },
        {
          update: {
            [entityKey]: entityChanges.map((entity) =>
              pickKeys(storeEntities.get(entity.id)!, Object.keys(entity) as (keyof Entity)[]),
            ),
          },
        },
      );

      if (changesHaveConflicts) {
        return true;
      }
    }

    const deleteChanges = changes.delete?.[entityKey];

    if (deleteChanges?.length) {
      const currentEntities = deleteChanges.map((entity) => storeEntities.get(entity.id));

      const atLeastOneEntityHasBeenRestoredToTheStore = currentEntities.some(
        (entity) => entity !== undefined,
      );

      if (atLeastOneEntityHasBeenRestoredToTheStore) return true;
    }

    return false;
  });
}
