import { AnalyticsEvent, AnalyticsEventCategories } from '@/utils/analyticsEvents/categories';

/** Schedule handling */

export class DependencyCreatedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.DEPENDENCY_CREATED;

  public constructor(public properties: { causeRescheduling: boolean }) {
    super();
  }
}

export class DependencyOpenedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.DEPENDENCY_OPENED;
}

type SharedMethod = 'details' | 'order_details' | 'milestone_details';

export class DependenciesDeletedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.DEPENDENCIES_DELETED;

  public constructor(
    public properties: {
      method: 'keyboard' | SharedMethod | 'delete_all' | 'toolbar';
      count: number;
    },
  ) {
    super();
  }
}

export class DependenciesEditedEvent extends AnalyticsEvent {
  public identification = AnalyticsEventCategories.DEPENDENCIES_EDITED;

  public constructor(public properties: { method: SharedMethod; count: number }) {
    super();
  }
}

export class DependenciesHighlighted extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.DEPENDENCIES_HIGHLIGHTED;

  public constructor(public properties: { method: 'details' | 'double_click'; active: boolean }) {
    super();
  }
}
