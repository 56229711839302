<template>
  <span class="ds-icon tw-rounded-full" :class="[`--${theme}`, `--${color}`]" :style="outerStyle">
    <span
      class="tw-leading-none tw-blue"
      :class="[
        outlined ? 'material-icons-outlined' : 'material-icons',
        { 'inner-circle': useInnerBorder },
      ]"
      :style="innerStyle"
      v-bind="$attrs"
    >
      <slot v-if="!svg">{{ icon }}</slot>
      <template v-else>
        <!-- Render the SVG icon using an inline SVG -->
        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 24 24">
          <path :fill="outlined ? 'none' : 'currentColor'" :d="icon" v-bind="extraSvgProps" />
        </svg>
      </template>
    </span>
  </span>
</template>

<script setup lang="ts">
import { StyleValue } from 'vue';

const props = defineProps({
  icon: {
    type: String,
    default: null,
  },
  outlined: {
    type: Boolean,
    default: false,
  },
  size: {
    type: [Number, String],
    default: 20,
  },
  svg: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String as PropType<'default' | 'circle' | 'halo-light' | 'halo-dark' | 'double-outline'>,
    default: 'default',
  },
  color: {
    type: String as PropType<
      'inherit' | 'blue' | 'grey' | 'info' | 'attention' | 'warning' | 'success' | 'drawerIcons'
    >,
    default: 'inherit',
  },
  extraSvgProps: {
    type: Object as PropType<Record<string, string | number>>,
    default: () => ({}),
  },
});

defineOptions({ inheritAttrs: false });

const useOuterCircle = computed(
  () => props.color !== 'inherit' && ['circle', 'halo-light', 'halo-dark'].includes(props.theme),
);
const useOuterBorder = computed(
  () => props.color !== 'inherit' && ['halo-light', 'halo-dark'].includes(props.theme),
);
const useInnerBorder = computed(
  () => props.color !== 'inherit' && props.theme === 'double-outline',
);

const outerStyle = computed(() => {
  const iconSize = Number(props.size);
  const style: Record<string, string> = useInnerBorder.value
    ? { 'border-width': `${iconSize / 10}px` }
    : {
        'border-width': useOuterBorder.value ? `${Math.round(iconSize / 4)}px` : '',
      };
  if (useInnerBorder.value) {
    style.padding = `${Math.round(iconSize / 6)}px`;
  }
  return style;
});

const innerStyle = computed(() => {
  const iconSize = Number(props.size);
  const style: StyleValue = {
    fontSize: `${iconSize}px`,
    height: `${iconSize}px`,
    width: `${iconSize}px`,
  };
  if (useInnerBorder.value) {
    const borderWidth = Math.round(iconSize / 10);
    const borderColor = `var(--color-${props.color}-100)`;
    const padding = Math.round(iconSize / 8);
    style.border = `solid ${borderWidth}px ${borderColor}`;
    style.padding = `${padding}px`;
    style.height = `${iconSize + borderWidth * 2 + padding * 2}px`;
    style.width = `${iconSize + borderWidth * 2 + padding * 2}px`;
  } else {
    const marginFactor = useOuterBorder.value ? 4 : 2;
    style.margin = useOuterCircle.value ? `${Math.round(iconSize / marginFactor)}px` : '';
  }
  return style;
});
</script>

<style scoped>
.ds-icon {
  @apply tw-inline-flex tw-items-center tw-justify-center tw-flex-none;
}

.ds-icon .inner-circle {
  @apply tw-rounded-full;
}
.ds-icon.--blue {
  @apply tw-text-blue-500 tw-bg-blue-50;
}
.ds-icon.--grey {
  @apply tw-text-grey-500 tw-bg-grey-50;
}
.ds-icon.--info {
  @apply tw-text-info-500 tw-bg-info-50;
}
.ds-icon.--attention {
  @apply tw-text-attention-500 tw-bg-attention-50;
}
.ds-icon.--warning {
  @apply tw-text-warning-500 tw-bg-warning-50;
}
.ds-icon.--success {
  @apply tw-text-success-600 tw-bg-success-50;
}

.ds-icon.--halo-dark,
.ds-icon.--halo-light,
.ds-icon.--double-outline {
  @apply tw-border-solid;
}

/* Light halo */
.ds-icon.--blue.--halo-light {
  @apply tw-text-blue-500 tw-border-blue-50 tw-bg-blue-100;
}
.ds-icon.--grey.--halo-light {
  @apply tw-text-grey-500 tw-border-grey-50 tw-bg-grey-100;
}
.ds-icon.--info.--halo-light {
  @apply tw-text-info-500 tw-border-info-50 tw-bg-info-100;
}
.ds-icon.--attention.--halo-light {
  @apply tw-text-attention-500 tw-border-attention-50 tw-bg-attention-100;
}
.ds-icon.--warning.--halo-light {
  @apply tw-text-warning-500 tw-border-warning-50 tw-bg-warning-100;
}
.ds-icon.--success.--halo-light {
  @apply tw-text-success-600 tw-border-success-50 tw-bg-success-100;
}

/* Dark halo */
.ds-icon.--blue.--halo-dark {
  @apply tw-text-white tw-border-blue-700 tw-bg-blue-600;
}
.ds-icon.--grey.--halo-dark {
  @apply tw-text-white tw-border-grey-700 tw-bg-grey-600;
}
.ds-icon.--info.--halo-dark {
  @apply tw-text-white tw-border-info-700 tw-bg-info-600;
}
.ds-icon.--attention.--halo-dark {
  @apply tw-text-white tw-border-attention-600 tw-bg-attention-500;
}
.ds-icon.--warning.--halo-dark {
  @apply tw-text-white tw-border-warning-600 tw-bg-warning-500;
}
.ds-icon.--success.--halo-dark {
  @apply tw-text-white tw-border-success-600 tw-bg-success-500;
}

/* Double outline */
.ds-icon.--double-outline {
  @apply tw-bg-transparent;
}
.ds-icon.--blue.--double-outline {
  @apply tw-border-blue-50;
}
.ds-icon.--blue.--double-outline span {
  @apply tw-border-blue-100;
}
.ds-icon.--grey.--double-outline {
  @apply tw-border-grey-50;
}
.ds-icon.--grey.--double-outline span {
  @apply tw-border-grey-100;
}
.ds-icon.--info.--double-outline {
  @apply tw-border-info-50;
}
.ds-icon.--info.--double-outline span {
  @apply tw-border-info-100;
}
.ds-icon.--attention.--double-outline {
  @apply tw-border-attention-50;
}
.ds-icon.--attention.--double-outline span {
  @apply tw-border-attention-100;
}
.ds-icon.--warning.--double-outline {
  @apply tw-border-warning-50;
}
.ds-icon.--warning.--double-outline span {
  @apply tw-border-warning-100;
}
.ds-icon.--success.--double-outline {
  @apply tw-border-success-50;
}
.ds-icon.--success.--double-outline span {
  @apply tw-border-success-100;
}

.ds-icon.--default {
  @apply tw-bg-transparent tw-contents tw-border-none;
}
</style>
