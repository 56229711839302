import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

import { MembershipWithTenant } from '@/interfaces/repositories/memberships';
import { ProjectSubcontractorWorker } from '@/interfaces/repositories/projectSubcontractorWorker';
import { GuardMeta, useWithMembership } from '@/router/helpers/memberships';
import {
  getProjectsAllOrPickTenantRoute,
  getRouteForMembershipStatus,
} from '@/router/helpers/redirection';

type GuardReturn = (to: RouteLocationNormalized, from: RouteLocationNormalized) => Promise<void>;

export function useCompanyGuard(meta: GuardMeta): GuardReturn {
  return async (to): Promise<any> => {
    const guard = (
      membership: MembershipWithTenant | undefined | null,
      memberships: MembershipWithTenant[],
      subcontraction: ProjectSubcontractorWorker | undefined | null,
      subcontractions: readonly ProjectSubcontractorWorker[],
    ): boolean | RouteLocationRaw => {
      // handle no memberships
      const membershipRedirectResult = getRouteForMembershipStatus(
        to,
        membership,
        memberships,
        subcontraction,
        subcontractions,
      );

      if (membershipRedirectResult) return membershipRedirectResult;
      const activeMembership = membership;

      // We expect that either activeMembership or subcontraction is set at this point
      // If both are set, they have the same subcontractor id anyway
      const tempTenantId = activeMembership?.tenant.id || subcontraction?.subcontractor.id;

      if (!activeMembership && subcontraction) {
        return getProjectsAllOrPickTenantRoute(tempTenantId);
      }

      return true;
    };
    return useWithMembership({ to }, meta, guard);
  };
}
