import { App } from 'vue';
import { Router } from 'vue-router';

import { AppApolloClient } from '@/interfaces/graphql';
import { ContainerProvider } from '@/ioc';
import { IoCContainer } from '@/ioc/framework/container';
import { iocProviderKey } from '@/ioc/injectKey';
import { setupModules } from '@/ioc/modules';
import { GoogleMaps } from '@/plugins/googleMaps';

export function installIoCContainer(
  app: App,
  client: AppApolloClient,
  existingContainer: IoCContainer,
  router: Router,
  googleMaps: GoogleMaps,
): void {
  const container = existingContainer ?? new IoCContainer();
  setupModules(container, client, router, googleMaps);

  const provider = new ContainerProvider(container);

  app.provide(iocProviderKey, provider);
}
