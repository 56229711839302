import gql from 'graphql-tag';
import { defineStore } from 'pinia';

import { useGlobalStore } from '@/common/globalStore';
import { gql as gqlFn } from '@/graphql/__generated__';
import {
  CreateTenantTemplateMutationVariables,
  ProjectStructureDetailsTemplateFragment,
  ProjectStructurePreviewTemplateFragment,
  ProjectStructureTemplatesPreviewsQueryVariables,
  ProjectStructurTemplateDetailsQueryVariables,
  UpdateTenantTemplateMutationVariables,
} from '@/graphql/__generated__/graphql';
import { useApolloClient } from '@/plugins/apollo';

import { WbsSection } from '../projectStructure/types';
import { copySections } from '../projectStructure/utils/copy';
import { queryProjectSections } from '../projectStructure/utils/queryProjectSections';
import { ProjectStructureTemplateBase } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const STRUCTURE_FRAGMENT = gql`
  fragment ProjectStructurePreviewTemplate on ProjectStructureTemplateNode {
    id
    name
    description
    isDefaultTemplate
    createdAt
    createdBy {
      firstName
      lastName
      profilePicture {
        imageThumbnail {
          url
        }
      }
    }
    updatedAt
    updatedBy {
      firstName
      lastName
      profilePicture {
        imageThumbnail {
          url
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const STRUCTURE_FRAGMENT_DETAILS_FRAGMENT = gql`
  fragment ProjectStructureDetailsTemplate on ProjectStructureTemplateNode {
    ...ProjectStructurePreviewTemplate
    wbsSections {
      id
      name
      position
      parent {
        id
      }
    }
  }
`;

function parseFragment<
  TFragment extends { isDefaultTemplate: boolean; description?: string | null },
>(fragment: TFragment): TFragment {
  return fragment;
}

export const useProjectStructureTemplatesStore = defineStore(
  'project-structure-templates-store',
  () => {
    const globalStore = useGlobalStore();
    const isInitialized = ref(false);
    const templates = ref<Map<string, ProjectStructurePreviewTemplateFragment>>(new Map());

    const templateDetails = ref<Map<string, ProjectStructureDetailsTemplateFragment>>(new Map());
    const projectSections = ref<Map<string, WbsSection[]>>(new Map());

    const fetchTemplateDetails = async (vars: ProjectStructurTemplateDetailsQueryVariables) => {
      const client = useApolloClient();

      if (templateDetails.value.has(vars.id)) return templateDetails.value.get(vars.id) ?? null;

      const query = gqlFn(/* GraphQL */ `
        query ProjectStructurTemplateDetails($id: ID!) {
          projectStructureTemplate(id: $id) {
            ...ProjectStructureDetailsTemplate
          }
        }
      `);

      return client
        .query({
          query,
          variables: vars,
          fetchPolicy: 'no-cache',
        })
        .then((result) => {
          const template = result.data.projectStructureTemplate;
          if (!template) return null;
          templateDetails.value.set(template.id, parseFragment(template));
          return template;
        });
    };

    const fetchTemplates = async (vars: ProjectStructureTemplatesPreviewsQueryVariables) => {
      const client = useApolloClient();

      const query = gqlFn(/* GraphQL */ `
        query ProjectStructureTemplatesPreviews($tenant: ID!) {
          projectStructureTemplates(tenant: $tenant, includeDefaultTemplates: true) {
            ...ProjectStructurePreviewTemplate
          }
        }
      `);

      return client
        .query({
          query,
          variables: {
            ...vars,
          },
          fetchPolicy: 'no-cache',
        })
        .then((result) => {
          isInitialized.value = true;
          const flattened = result.data.projectStructureTemplates ?? [];
          templates.value = new Map(
            flattened.map((template) => [template.id, parseFragment(template)]),
          );
          return flattened;
        });
    };

    const initialize = (tenantId: string) => {
      return fetchTemplates({ tenant: tenantId }).finally(() => {
        isInitialized.value = true;
      });
    };

    const fetchTemplateStructure = ({
      templateId,
    }: {
      templateId: string;
    }): Promise<WbsSection[]> => {
      return fetchTemplateDetails({ id: templateId }).then((details) => {
        if (!details) return [];
        return details.wbsSections.map((v) => ({
          id: v.id,
          name: v.name,
          position: v.position,
          parentId: v.parent?.id ?? null,
        }));
      });
    };

    const deleteTenantTemplate = async (id: string) => {
      const client = useApolloClient();

      const mutation = gqlFn(/* GraphQL */ `
        mutation DeleteProjectStructureTemplate($input: DeleteProjectStructureTemplateInput!) {
          deleteProjectStructureTemplate(input: $input) {
            success
          }
        }
      `);

      return client
        .mutate({
          mutation,
          variables: { input: { id } },
        })
        .then((response) => {
          const result = response.data?.deleteProjectStructureTemplate?.success ?? null;
          if (!result) return null;

          templates.value.delete(id);
          templateDetails.value.delete(id);
          return result;
        });
    };

    const createTenantTemplate = async (
      variables: Omit<CreateTenantTemplateMutationVariables['input'], 'tenant'>,
    ) => {
      const client = useApolloClient();

      const mutation = gqlFn(/* GraphQL */ `
        mutation CreateTenantTemplate($input: CreateProjectStructureTemplateInput!) {
          createProjectStructureTemplate(input: $input) {
            projectStructureTemplate {
              ...ProjectStructureDetailsTemplate
            }
          }
        }
      `);

      const input: CreateTenantTemplateMutationVariables['input'] = {
        name: variables.name,
        description: variables.description,
        tenant: globalStore.currentTenantId!,
        /**
         * Section ids are currently never used for anything
         * so we can safely regnerate them to prevent us from
         * potentially reusing the same ids from an existing template/project.
         */
        sections: copySections(
          variables.sections.map((section) => ({
            id: section.id,
            parentId: section.parentId ?? null,
            name: section.name,
            position: section.position,
          })),
        ),
      };

      return client.mutate({ mutation, variables: { input } }).then((response) => {
        const result =
          response.data?.createProjectStructureTemplate?.projectStructureTemplate ?? null;
        if (!result) return null;
        templateDetails.value.set(result.id, result);
        templates.value.set(result.id, result);
        return result;
      });
    };

    const updateTenantTemplate = async (
      variables: UpdateTenantTemplateMutationVariables['input'],
    ) => {
      const client = useApolloClient();

      const mutation = gqlFn(/* GraphQL */ `
        mutation UpdateTenantTemplate($input: UpdateProjectStructureTemplateInput!) {
          updateProjectStructureTemplate(input: $input) {
            projectStructureTemplate {
              ...ProjectStructureDetailsTemplate
            }
          }
        }
      `);

      const input: UpdateTenantTemplateMutationVariables['input'] = {
        name: variables.name,
        description: variables.description,
        id: variables.id,
        sections:
          variables.sections?.map((section) => ({
            id: section.id,
            parentId: section.parentId,
            name: section.name,
            position: section.position,
          })) ?? undefined,
      };

      return client.mutate({ mutation, variables: { input } }).then((response) => {
        const result =
          response.data?.updateProjectStructureTemplate?.projectStructureTemplate ?? null;
        if (!result) return null;
        templateDetails.value.set(result.id, result);
        templates.value.set(result.id, result);
        return result;
      });
    };

    const fetchProjectStructure = async ({
      projectId,
    }: {
      projectId: string;
    }): Promise<WbsSection[]> => {
      if (projectSections.value.has(projectId)) {
        return projectSections.value.get(projectId)!;
      }

      const sections = await queryProjectSections(projectId);
      projectSections.value.set(projectId, sections);
      return sections;
    };

    const fetchBaseStructure = (structure: ProjectStructureTemplateBase) => {
      if ('templateId' in structure) {
        const { templateId } = structure;
        return fetchTemplateStructure({ templateId });
      }
      return fetchProjectStructure({ projectId: structure.otherTemplateId });
    };

    const tenantTemplates = computed(() => {
      return [...templates.value.values()].filter((t) => !t.isDefaultTemplate);
    });

    const kopplaTemplates = computed(() => {
      return [...templates.value.values()].filter((t) => t.isDefaultTemplate);
    });

    return {
      isInitialized,
      templates,
      tenantTemplates,
      kopplaTemplates,
      createTenantTemplate,
      updateTenantTemplate,
      deleteTenantTemplate,
      initialize,
      fetchTemplateDetails,
      fetchTemplates,
      fetchBaseStructure,
    };
  },
);
