// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { h } from '@/jsx/jsxFactory';

export function removeOrphans() {
  removeOrphanTooltips();
  removeOrphanToolbars();
}

function removeOrphanTooltips() {
  const orphanTooltips = document.body.getElementsByClassName('jsx-tooltip');
  Array.from(orphanTooltips).forEach((tooltip) => {
    document.body.removeChild(tooltip);
  });
}

export function removeOrphanToolbars() {
  Array.from(document.getElementsByClassName('section-teleporter-toolbar')).forEach((toolbar) => {
    toolbar.parentNode?.removeChild(toolbar);
  });
}

export function formatText(text: string) {
  const textParts = text.split(/(<\/?b>)/).filter(Boolean);
  return (
    <span>
      {...textParts.map((part, index) => {
        if (part === '<b>') {
          return <b>{textParts[index + 1]}</b>;
        } else if (part === '</b>') {
          return '';
        } else if (textParts[index - 1] === '<b>') {
          return '';
        } else {
          return part;
        }
      })}
    </span>
  );
}
