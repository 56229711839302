import { defineStore } from 'pinia';

import {
  CalendarEntity,
  Entity,
  InteractiveEntityChanges,
  PartialEntity,
  WrapRef,
} from '@/common/types';
import { UndoRedoCommit } from '@/features/undoRedo';
import { useApolloClient } from '@/plugins/apollo';
import { flattenNodeConnection, getOrderByArgument } from '@/repositories/utils/fetchAll';

import { useMilestoneStoreV2 } from '../milestones';
import { useOrderDependencyStoreV2 } from '../orderDependencies';
import { useOrderStoreV2 } from '../orders';
import { ProjectChangeEventContext } from '../realTimeCollaboration/types';
import { pushLocalChangeEventAndCommit } from '../realTimeCollaboration/utils';
import {
  createUndoRedoCreateCommit,
  createUndoRedoDeleteCommit,
  createUndoRedoRestoreCommit,
  createUndoRedoUpdateCommit,
} from './calendarCommits';
import {
  createProjectChangeCreateEvent,
  createProjectChangeDeleteEvent,
  createProjectChangeRestoreEvent,
  createProjectChangeUpdateEvent,
} from './calendarEvents';
import { calendarsQuery } from './calendarGql';
import { CalendarStore } from './calendarTypes';
import {
  convertCalendarFragmentsToCalendarEntities,
  findDefaultCalendar,
  sortCalendars,
} from './calendarUtils';

export const useCalendarStore = defineStore(
  'calendar-store',
  (): WrapRef<
    CalendarStore,
    'calendars' | 'calendarList' | 'fetchAllPromise' | 'hideDefaultCalendarInfo'
  > => {
    const calendars = ref(new Map<string, CalendarEntity>());
    const deletedCalendars = ref(new Map<string, CalendarEntity>());
    const hideDefaultCalendarInfo = ref(false);

    const calendarList = computed(() => sortCalendars(Array.from(calendars.value.values())));

    const create = async (
      vars: CalendarEntity[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeCreateEvent(vars, context);
      const commit = createUndoRedoCreateCommit(vars);
      return pushLocalChangeEventAndCommit(event, commit, createCommit, {
        dependencies: useOrderDependencyStoreV2().copyState(),
      });
    };

    const update = async (
      vars: PartialEntity<CalendarEntity>[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeUpdateEvent(vars, context);
      const commit = createUndoRedoUpdateCommit(vars, calendars.value);
      return pushLocalChangeEventAndCommit(event, commit, createCommit, {
        dependencies: useOrderDependencyStoreV2().copyState(),
        orders: useOrderStoreV2().copyState(),
        milestones: useMilestoneStoreV2().copyState(),
        sendElementContext: true,
        injectWorkingTimeDuration: (o) => useOrderStoreV2().injectWorkingTimeDuration(o),
      });
    };

    const restore = async (
      vars: CalendarEntity[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeRestoreEvent(vars, context);
      const commit = createUndoRedoRestoreCommit(vars);
      return pushLocalChangeEventAndCommit(event, commit, createCommit, {
        dependencies: useOrderDependencyStoreV2().copyState(),
      });
    };

    const remove = async (
      vars: CalendarEntity[],
      createCommit = true,
      context?: ProjectChangeEventContext,
    ): Promise<{ changes: InteractiveEntityChanges; commit: UndoRedoCommit }> => {
      const event = createProjectChangeDeleteEvent(vars, context);
      const commit = createUndoRedoDeleteCommit(vars);
      return pushLocalChangeEventAndCommit(event, commit, createCommit, {
        dependencies: useOrderDependencyStoreV2().copyState(),
      });
    };

    const fetchAllPromise: Ref<Promise<CalendarEntity[]> | null> = ref(null);

    const fetchAll = async (projectId: string): Promise<CalendarEntity[]> => {
      const client = useApolloClient();

      fetchAllPromise.value = client
        .query({
          query: calendarsQuery,
          variables: {
            projectId,
            orderBy: getOrderByArgument({ name: 'ASC' }),
          },
          fetchPolicy: 'no-cache',
        })
        .then((result) => {
          const newCalendars = convertCalendarFragmentsToCalendarEntities(
            flattenNodeConnection(result.data.calendars),
          );
          calendars.value = new Map(newCalendars.map((calendar) => [calendar.id, calendar]));
          return newCalendars;
        });
      return fetchAllPromise.value;
    };

    const reset = () => {
      calendars.value = new Map();
      fetchAllPromise.value = null;
    };

    const setState = (state?: Map<string, CalendarEntity>) => {
      if (!state) {
        return;
      }
      state.forEach((calendar) => {
        if (deletedCalendars.value.has(calendar.id)) {
          deletedCalendars.value.set(calendar.id, calendar);
        }
      });
      calendars.value.forEach((calendar) => {
        if (!state.has(calendar.id)) {
          deletedCalendars.value.set(calendar.id, calendar);
        }
      });
      calendars.value = new Map(state);
    };

    const applyChanges = (changes: {
      add?: CalendarEntity[];
      update?: PartialEntity<CalendarEntity>[];
      delete?: Entity[];
    }): void => {
      changes.add?.forEach((calendar) => {
        calendars.value.set(calendar.id, calendar);
      });
      changes.update?.forEach((calendar) => {
        calendars.value.set(calendar.id, {
          ...calendars.value.get(calendar.id)!,
          ...calendar,
        });
        if (deletedCalendars.value.has(calendar.id)) {
          deletedCalendars.value.set(calendar.id, {
            ...calendars.value.get(calendar.id)!,
            ...calendar,
          });
        }
      });
      changes.delete?.forEach((calendar) => {
        const existingCalendar = calendars.value.get(calendar.id);
        if (existingCalendar) {
          deletedCalendars.value.set(calendar.id, existingCalendar);
          calendars.value.delete(calendar.id);
        }
      });
    };

    const copyState = () => {
      return new Map(calendars.value);
    };

    const getDefaultCalendar = () => computed(() => findDefaultCalendar(calendars.value));

    const getSoftDeletedEntity = (id: string): CalendarEntity | undefined => {
      return deletedCalendars.value.get(id);
    };

    return {
      calendars,
      calendarList,
      hideDefaultCalendarInfo,
      create,
      update,
      restore,
      delete: remove,
      setState,
      applyChanges,
      copyState,
      fetchAll,
      fetchAllPromise,
      reset,
      getDefaultCalendar,
      getSoftDeletedEntity,
    };
  },
  { persist: { paths: ['hideDefaultCalendarInfo'] } },
);
