import {
  CalendarEntity,
  Entity,
  InsertTradeSequenceVariables,
  MilestoneEntity,
  OrderDependencyEntity,
  OrderEntity,
  PartialEntity,
  PauseEntity,
  TradeSequenceEntity,
  WbsSectionEntity,
} from '@/common/types';
import { getRandomId } from '@/helpers/utils/strings';

import {
  RemoveEntitiesVariables,
  RescheduleEntitiesVariables,
  RestoreEntitiesVariables,
} from '../multiSelectActions/types';
import { RemoveCopiedOrdersVariables, RestoreCopiedOrdersVariables } from '../orders/types';
import {
  CreateTradeSequenceVariables,
  DeleteTradeSequenceVariables,
} from '../projectTradeSequences/types';

export enum UndoRedoActionNames {
  // milestones
  CreateMilestones = 'CreateMilestones',
  RestoreMilestones = 'RestoreMilestones',
  UpdateMilestones = 'UpdateMilestones',
  DeleteMilestones = 'DeleteMilestones',
  // pauses
  CreatePauses = 'CreatePauses',
  RestorePauses = 'RestorePauses',
  UpdatePauses = 'UpdatePauses',
  DeletePauses = 'DeletePauses',
  // orders
  CreateOrders = 'CreateOrders',
  RestoreOrders = 'RestoreOrders',
  UpdateOrders = 'UpdateOrders',
  DeleteOrders = 'DeleteOrders',
  CopyOrders = 'CopyOrders',
  RevertCopyOrders = 'RevertCopyOrders',
  // orderDependencies
  CreateOrderDependencies = 'CreateOrderDependencies',
  RestoreOrderDependencies = 'RestoreOrderDependencies',
  UpdateOrderDependencies = 'UpdateOrderDependencies',
  DeleteOrderDependencies = 'DeleteOrderDependencies',
  // sections
  CreateWBSSections = 'CreateWBSSections',
  RestoreWBSSections = 'RestoreWBSSections',
  UpdateWBSSection = 'UpdateWBSSection',
  DeleteWBSSections = 'DeleteWBSSections',
  IndentWBSSection = 'IndentWBSSection',
  OutdentWBSSection = 'OutdentWBSSection',
  UndoOutdentWBSSection = 'UndoOutdentWBSSection',
  // trade sequences
  CreateTradeSequences = 'CreateTradeSequences',
  RestoreTradeSequences = 'RestoreTradeSequences',
  UpdateTradeSequences = 'UpdateTradeSequences',
  DeleteTradeSequences = 'DeleteTradeSequences',
  InsertTradeSequence = 'InsertTradeSequence',
  RevertInsertTradeSequence = 'RevertInsertTradeSequence',
  RestoreInsertTradeSequence = 'RestoreInsertTradeSequence',
  // calendars
  CreateCalendars = 'CreateCalendars',
  RestoreCalendars = 'RestoreCalendars',
  UpdateCalendars = 'UpdateCalendars',
  DeleteCalendars = 'DeleteCalendars',
  // Multi actions
  RescheduleScheduleNodes = 'RescheduleScheduleNodes',
  RemoveScheduleNodes = 'RemoveScheduleNodes',
  RestoreScheduleNodes = 'RestoreScheduleNodes',
}

type CreateAction<A extends UndoRedoActionNames, E extends Entity> = {
  name: A;
  data: E[];
};

type RestoreAction<A extends UndoRedoActionNames, E extends Entity> = {
  name: A;
  data: E[];
};

type DeleteAction<A extends UndoRedoActionNames, E extends Entity> = {
  name: A;
  data: E[];
};

type UpdateAction<
  A extends UndoRedoActionNames,
  E extends Entity,
  IsPartial extends boolean = true,
> = {
  name: A;
  data: IsPartial extends true ? PartialEntity<E>[] : E[];
};

type CreateTradeSequencesAction = {
  name: UndoRedoActionNames.CreateTradeSequences;
  data: CreateTradeSequenceVariables[];
};

type DeleteTradeSequencesAction = {
  name: UndoRedoActionNames.DeleteTradeSequences;
  data: DeleteTradeSequenceVariables[];
};

type InsertTradeSequenceAction = {
  name: UndoRedoActionNames.InsertTradeSequence;
  data: InsertTradeSequenceVariables;
};

type RevertInsertTradeSequenceAction = {
  name: UndoRedoActionNames.RevertInsertTradeSequence;
  data: {
    orders: Entity[];
  };
};

type RestoreInsertTradeSequenceAction = {
  name: UndoRedoActionNames.RestoreInsertTradeSequence;
  data: {
    orders: Entity[];
  };
};

type RescheduleScheduleNodesAction = {
  name: UndoRedoActionNames.RescheduleScheduleNodes;
  data: RescheduleEntitiesVariables;
};

type RemoveScheduleNodesAction = {
  name: UndoRedoActionNames.RemoveScheduleNodes;
  data: RemoveEntitiesVariables;
};

type RestoreScheduleNodesAction = {
  name: UndoRedoActionNames.RestoreScheduleNodes;
  data: RestoreEntitiesVariables;
};

type CopyOrdersAction = {
  name: UndoRedoActionNames.CopyOrders;
  data: RestoreCopiedOrdersVariables;
};

type RevertCopyOrdersAction = {
  name: UndoRedoActionNames.RevertCopyOrders;
  data: RemoveCopiedOrdersVariables;
};

type IndentWBSSectionAction = {
  name: UndoRedoActionNames.IndentWBSSection;
  data: WbsSectionEntity;
};

type CreateWBSSectionAction = {
  name: UndoRedoActionNames.CreateWBSSections;
  data: { sections: WbsSectionEntity[]; root: WbsSectionEntity | null };
};

type OutdentWBSSectionAction = {
  name: UndoRedoActionNames.OutdentWBSSection;
  data: { id: string };
};

type UndoOutdentWBSSectionAction = {
  name: UndoRedoActionNames.UndoOutdentWBSSection;
  data: { originallyOutdentedSection: WbsSectionEntity; sectionsToRecreate: WbsSectionEntity[] };
};

export type UndoRedoAction =
  | CreateAction<UndoRedoActionNames.CreateMilestones, MilestoneEntity>
  | RestoreAction<UndoRedoActionNames.RestoreMilestones, MilestoneEntity>
  | UpdateAction<UndoRedoActionNames.UpdateMilestones, MilestoneEntity>
  | DeleteAction<UndoRedoActionNames.DeleteMilestones, MilestoneEntity>
  | CreateAction<UndoRedoActionNames.CreatePauses, PauseEntity>
  | RestoreAction<UndoRedoActionNames.RestorePauses, PauseEntity>
  | UpdateAction<UndoRedoActionNames.UpdatePauses, PauseEntity>
  | DeleteAction<UndoRedoActionNames.DeletePauses, PauseEntity>
  | CreateAction<UndoRedoActionNames.CreateOrders, OrderEntity>
  | RestoreAction<UndoRedoActionNames.RestoreOrders, OrderEntity>
  | UpdateAction<UndoRedoActionNames.UpdateOrders, OrderEntity>
  | DeleteAction<UndoRedoActionNames.DeleteOrders, OrderEntity>
  | CopyOrdersAction
  | RevertCopyOrdersAction
  | CreateTradeSequencesAction
  | RestoreAction<UndoRedoActionNames.RestoreTradeSequences, TradeSequenceEntity>
  | UpdateAction<UndoRedoActionNames.UpdateTradeSequences, TradeSequenceEntity, false>
  | DeleteTradeSequencesAction
  | InsertTradeSequenceAction
  | RevertInsertTradeSequenceAction
  | RestoreInsertTradeSequenceAction
  | CreateAction<UndoRedoActionNames.CreateOrderDependencies, OrderDependencyEntity>
  | RestoreAction<UndoRedoActionNames.RestoreOrderDependencies, OrderDependencyEntity>
  | UpdateAction<UndoRedoActionNames.UpdateOrderDependencies, OrderDependencyEntity>
  | DeleteAction<UndoRedoActionNames.DeleteOrderDependencies, OrderDependencyEntity>
  | CreateAction<UndoRedoActionNames.CreateCalendars, CalendarEntity>
  | RestoreAction<UndoRedoActionNames.RestoreCalendars, CalendarEntity>
  | UpdateAction<UndoRedoActionNames.UpdateCalendars, CalendarEntity>
  | DeleteAction<UndoRedoActionNames.DeleteCalendars, CalendarEntity>
  | RestoreAction<UndoRedoActionNames.RestoreWBSSections, WbsSectionEntity>
  | UpdateAction<UndoRedoActionNames.UpdateWBSSection, WbsSectionEntity>
  | DeleteAction<UndoRedoActionNames.DeleteWBSSections, WbsSectionEntity>
  | CreateWBSSectionAction
  | IndentWBSSectionAction
  | OutdentWBSSectionAction
  | UndoOutdentWBSSectionAction
  | RemoveScheduleNodesAction
  | RestoreScheduleNodesAction
  | RescheduleScheduleNodesAction;

export interface UndoRedoCommit {
  id: string;
  action: UndoRedoAction;
  inverseAction: UndoRedoAction;
}

export function generateUndoRedoCommitId(): string {
  return getRandomId();
}
